import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {

  /**
   * 
   */
  init() {
    // IMPL: Remove setInterceptors
    // this.setInterceptors();
    this.handleAuthentication();
  }

  /**
   * 
   */
  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  /**
   * 
   * @returns
   */
  handleAuthentication = () => {

    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  /**
   * 
   * @param {*} email 
   * @param {*} password 
   * @returns 
   */
  signInWithEmailAndPassword = (email, password, messageBox) => {

    const baseUrl = process.env.REACT_APP_API_URL;
    const url = baseUrl + 'Auth/login';
    return new Promise((resolve, reject) => {

      fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        redirect: "follow",
        referrer: "no-referrer",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password
        }),
      }).then(res => {

        if (res.ok) return res.json().then(result => {

          if (!result.user) messageBox.showError('Invalid email or password.')          

            this.setSession(result.token);
          resolve(result.user);
          this.emit('onLogin', result.user);

        });
      }).catch(err => reject(err));

    });

    // return new Promise((resolve, reject) => {
    //   axios
    //     .get(jwtServiceConfig.signIn, {
    //       data: {
    //         email,
    //         password,
    //       },
    //     })
    //     .then((response) => {
    //       if (response.data.user) {
    //         this.setSession(response.data.access_token);
    //         resolve(response.data.user);
    //         this.emit('onLogin', response.data.user);
    //       } else {
    //         reject(response.data.error);
    //       }
    //     });
    // });
  };

  /**
   * 
   * @returns 
   */
  signInWithToken = () => {

    const baseUrl = process.env.REACT_APP_API_URL;
    const url = baseUrl + 'Auth/ValidateAuth';

    return new Promise((resolve, reject) => {

      fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        redirect: "follow",
        referrer: "no-referrer",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + this.getAccessToken()
        },
        body: null
      }).then(res => {
        if (res.ok) {
          return res.json().then(result => {
            this.setSession(result.token);
            resolve(result.user);
          });
        } else {
          this.logout();
          reject(new Error('Failed to login with token.'));          
        }
      }).catch(err => {
        this.logout();
        reject(err);
      });

      // axios
      //   .get(jwtServiceConfig.accessToken, {
      //     data: {
      //       access_token: this.getAccessToken(),
      //     },
      //   })
      //   .then((response) => {
      //     if (response.data.user) {
      //       this.setSession(response.data.access_token);
      //       resolve(response.data.user);
      //     } else {
      //       this.logout();
      //       reject(new Error('Failed to login with token.'));
      //     }
      //   })
      //   .catch((error) => {
      //     this.logout();
      //     reject(new Error('Failed to login with token.'));
      //   });

    });
  };

  /**
   * 
   * @param {*} user 
   * @returns 
   */
  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  /**
   * 
   * @param {*} access_token 
   */
  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  /**
   * 
   */
  logout = () => {
    this.setSession(null);
    this.emit('onLogout', 'Logged out');
  };

  /**
   * 
   * @param {*} access_token 
   * @returns 
   */
  isAuthTokenValid = (access_token) => {

    if (!access_token) return false;

    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      return false;
    }

    return true;
  };

  /**
   * 
   * @returns 
   */
  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new JwtService();

export default instance;
