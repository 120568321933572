import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NotesTable } from './notes.table';
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';

/**
 * 
 */
export class NotesDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.notes = [];
    this.order = null;
  }

}

/**
 * 
 */
export class NotesDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  notes = () => this.model.notes;

  /**
   * 
   */
  refreshGv = async () => {

    try {

      this.spinner().show();
      const result = await this.api().productionNoteApi.getUserNotesByProductionID(this.model.order.productionID);
      if (result) this.updateModel(x => x.notes = result);
  
    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

  /**
   * 
   * @param {*} order 
   * @returns 
   */
  show = async order => {

    try {

      this.spinner().show();
      const result = await this.api().productionNoteApi.getUserNotesByProductionID(order.productionID);
      return super.show(x => {
        x.notes = result;
        x.order = order;
      });

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  };

};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const NotesDialog = props => {

  const { controller } = props;

  /**
   * 
   */
  return <Dialog fullScreen style={{ margin: 'auto', maxWidth: 1000, maxHeight: 600 }}
    open={controller.visible()}
    onClose={controller.hide}
    scroll='paper'
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description">
    <DialogTitle id="scroll-dialog-title">
      <DialogHeaderContent title="Notes" onClose={controller.hide} />
    </DialogTitle>
    <DialogContent dividers={true}>
      <div style={{ margin: "10px 0" }}>
        <NotesTable controller={controller} />
        <br />
      </div>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" onClick={controller.hide}>Close</Button>
    </DialogActions>
  </Dialog>
}
