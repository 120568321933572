import { useState } from 'react';
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import TextField from '@mui/material/TextField';
import { TextInput } from 'src/app/@core/forms/controls/textInput';
import { SelectInput } from 'src/app/@core/forms/controls/selectInput';
import { Motion20 } from 'src/app/@core/forms/controls/motion20';
import { isUoN, toMMDDYYYY } from 'src/app/@core/common';
import { getFullName } from 'src/app/@core/common';
import { showMessage } from 'app/store/fuse/messageSlice';
import { OrderStatusController } from 'src/app/@domain/modules/order/@orderStatus.controller';
import { OrderAdvanceDialog, OrderAdvanceDialogController, OrderAdvanceDialogModel } from 'src/app/@domain/modules/prodStatus/orderAdvance.dialog';
import { OrderReverseDialog, OrderReverseDialogController, OrderReverseDialogModel } from 'src/app/@domain/modules/prodStatus/orderReverse.dialog';
import { OrderReversePendingDialog, OrderReversePendingDialogController, OrderReversePendingDialogModel } from 'src/app/@domain/modules/prodStatus/orderReversePending.dialog';
import { OrderPendingDialog, OrderPendingDialogController, OrderPendingDialogModel } from 'src/app/@domain/modules/prodStatus/orderPending.dialog';
import { OrderCancelDialog, OrderCancelDialogController, OrderCancelDialogModel } from 'src/app/@domain/modules/prodStatus/orderCancel.dialog';

export class OrderEditSectionController extends OrderStatusController {

  /**
   * 
   * @param {*} controllerBase 
   */
  constructor(controllerBase) {
    super(controllerBase);
  }

  /**
   *
   * @param {*} val
   */
  updateProductionStatus = async (val) => {

    const result = val === 'Yes' ? await this.base.convertToTrialDialogController.show(this.base.shirts())
      : await this.base.convertToNoTrialDialogController.show(this.base.shirts());
    if (!result) return;

    this.base.updateActiveProduction(val);

  };

  /**
   *
   * @param {*} obj
   */
  updateShirtUser = async (obj) => {

    try {

      this.base.spinner().show();

      const result = await this.base.api().orderApi.updateShirtUser({
        orderID: this.base.order().orderID,
        contactID: obj.contactID,
      });

      if (result) {
        this.base.updateModel((x) => (x.shirtUser = obj));
        this.base.dispatch(showMessage({ message: 'The Shirt User has been updated.' }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.base.spinner().hide();
    }
  };

  /**
   *
   * @param {*} obj
   */
  updateRetailer = async (obj) => {
    try {
      this.base.spinner().show();

      const result = await this.base.api().orderApi.updateRetailer({
        orderID: this.base.order().orderID,
        retailerID: obj.id,
      });

      if (result) {
        this.base.updateModel((x) => (x.retailer = obj));
        this.base.dispatch(showMessage({ message: 'The Retailer has been updated.' }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.base.spinner().hide();
    }
  };

  /**
   *
   * @param {*} obj
   */
  updateSalesperson = async (obj) => {
    try {
      this.base.spinner().show();

      const result = await this.base.api().orderApi.updateSalesperson({
        orderID: this.base.order().orderID,
        salespersonID: obj.contactID,
      });

      if (result) {
        this.base.updateModel((x) => (x.salesperson = obj));
        this.base.dispatch(showMessage({ message: 'The Salesperson has been updated.' }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.base.spinner().hide();
    }
  };

  /**
   * 
   * @param {*} val 
   */
  updateReceivedDate = async val => {

    try {

      this.base.spinner().show();

      const receivedDate = new Date(val);
      console.log(receivedDate, this.base.order());

      const result = await this.base.api().orderApi.updateReceivedDate({
        orderID: this.base.order().orderID,
        receiveDate: receivedDate,
      });

      if (result) {
        this.base.updateModel(x => {
          x.order.receiveDate = receivedDate
          x.order.orderReceiveDate = receivedDate
        });
        this.base.dispatch(showMessage({ message: 'The Received Date has been updated.' }));
      }

    } catch (err) { console.log(err); }
    finally { this.base.spinner().hide(); }

  }

}

/**
 *
 * @param {*} prop
 * @returns
 */
export const OrderEditSection = (props) => {

  const { controllerBase } = props;
  const controller = new OrderEditSectionController(controllerBase);

  const { contacts, retailers, order, shirtUser, retailer, salesperson } = controllerBase;
  const { cancelOrder,
    pendingOrder,
    advanceOrder,
    reverseOrder,
    updateShirtUser,
    updateRetailer,
    updateSalesperson,
    updateProductionStatus
  } = controller;

  const [orderAdvanceDialogModel, setOrderAdvanceDialogModel] = useState(new OrderAdvanceDialogModel());
  const orderAdvanceDialogController = new OrderAdvanceDialogController(orderAdvanceDialogModel, setOrderAdvanceDialogModel);
  controller.orderAdvanceDialogController = orderAdvanceDialogController;

  const [orderReverseDialogModel, setOrderReverseDialogModel] = useState(new OrderReverseDialogModel());
  const orderReverseDialogController = new OrderReverseDialogController(orderReverseDialogModel, setOrderReverseDialogModel);
  controller.orderReverseDialogController = orderReverseDialogController;

  const [orderReversePendingDialogModel, setOrderReversePendingDialogModel] = useState(new OrderReversePendingDialogModel());
  const orderReversePendingDialogController = new OrderReversePendingDialogController(orderReversePendingDialogModel, setOrderReversePendingDialogModel);
  controller.orderReversePendingDialogController = orderReversePendingDialogController;

  const [orderPendingDialogModel, setOrderPendingDialogModel] = useState(new OrderPendingDialogModel());
  const orderPendingDialogController = new OrderPendingDialogController(orderPendingDialogModel, setOrderPendingDialogModel);
  controller.orderPendingDialogController = orderPendingDialogController;

  const [orderCancelDialogModel, setOrderCancelDialogModel] = useState(new OrderCancelDialogModel());
  const orderCancelDialogController = new OrderCancelDialogController(orderCancelDialogModel, setOrderCancelDialogModel);
  controller.orderCancelDialogController = orderCancelDialogController;


  return <div>

    <OrderAdvanceDialog controller={orderAdvanceDialogController} />
    <OrderReverseDialog controller={orderReverseDialogController} />
    <OrderReversePendingDialog controller={orderReversePendingDialogController} />
    <OrderPendingDialog controller={orderPendingDialogController} />
    <OrderCancelDialog controller={orderCancelDialogController} />

    <div className='w-full flex flex-row justify-end'>
      <div style={{ transform: 'rotate(90deg)', marginRight: 10 }}>
        <FuseSvgIcon className='text-48 cursor-pointer' size={30} color='success' onClick={reverseOrder}>
          material-twotone:arrow_circle_down
        </FuseSvgIcon>
      </div>
      <div style={{ marginRight: 10, marginTop: 5 }}>
        <img src='img/stopsign.png' className='cursor-pointer' width='30' onClick={pendingOrder} />
      </div>
      <div style={{ transform: 'rotate(-90deg)', marginRight: 50 }}>
        <FuseSvgIcon className='text-48 cursor-pointer' size={30} color='success' onClick={advanceOrder}>
          material-twotone:arrow_circle_down
        </FuseSvgIcon>
      </div>
      <Motion20>
        <Button variant='contained' color='secondary' onClick={cancelOrder}>Cancel Order</Button>
      </Motion20>
    </div>
    <hr style={{ margin: '10px 0' }} />
    <div className='w-full flex flex-row'>
      <TextInput label='Order ID' value={order().orderID} disabled={true} />
      <TextInput label='Order Status' value={order().orderStatus} disabled={true} />

      {/* <TextInput label='Received Date' value={toMMDDYYYY(order().orderReceiveDate)} disabled={true} /> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          size="small"
          value={order().orderReceiveDate === '' ? null : dayjs(order().orderReceiveDate)}
          label="Received Date"
          className="mt-8 mb-2 mx-8"
          sx={{ height: 20, width: "100%" }}
          fullWidth
          variant="outlined"
          onChange={val => controller.updateReceivedDate(val)}
        />
      </LocalizationProvider>

      <SelectInput label='Active Production Is Trial?'
        items={[{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }]}
        defaultValue={order().isCutTrial}
        onChange={(e) => updateProductionStatus(e.target.value)}
      />
    </div>
    <hr style={{ margin: '10px 0 10px 0' }} />
    <div className='w-full flex flex-row'>
      <Autocomplete
        className='mt-9 mb-2 mx-8'
        disablePortal
        fullWidth
        options={contacts()}
        value={shirtUser()}
        filterOptions={createFilterOptions({ matchFrom: 'any', limit: 2000 })}
        onChange={(e, data) => updateShirtUser(data)}
        getOptionLabel={(x) => getFullName(x)}
        renderOption={(props, option) => <li {...props} key={option.contactID}>{getFullName(option)}</li>}
        renderInput={(params) => <TextField {...params} label='Shirt User' size='small' />}
      />
      <Autocomplete
        className='mt-9 mb-2 mx-8'
        disablePortal
        fullWidth
        options={retailers()}
        value={retailer()}
        onChange={(e, data) => updateRetailer(data)}
        isOptionEqualToValue={(option, value) => value.id === '' ? true : option.id === value.id}
        getOptionLabel={(x) => x.name}
        renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
        renderInput={(params) => <TextField {...params} label='Retailer' size='small' />}
      />
      <Autocomplete
        className='mt-9 mb-2 mx-8'
        disablePortal
        fullWidth
        options={contacts()}
        value={salesperson()}
        filterOptions={createFilterOptions({ matchFrom: 'any', limit: 2000 })}
        onChange={(e, data) => updateSalesperson(data)}
        getOptionLabel={(x) => getFullName(x)}
        renderOption={(props, option) => <li {...props} key={option.contactID}>{getFullName(option)}</li>}
        renderInput={(params) => <TextField {...params} label='Salesperson' size='small' />}
      />
    </div>
  </div>

};

