import { ReportInventoryByFabricIDPage } from "./inventoryByFabricIDReport.page";
import { ReportSalesByFabricIDPage } from "./salesByFabricIDReport.page";
import { ReportDailyReceivedCutCompletePage } from "./dailyReceivedCutCompleteReport/@dailyReceivedCutCompleteReport.page";
import { ReportSalesByRetailerByDatePage } from "./salesByRetailerByDate.report.page";
import { ReportShirtsOnOrderSummaryPage } from "./shirtsOnOrderSummaryReport/@shirtsOnOrderSummaryReport.page";
import { ReportProductionToReviewPage } from "./productionToReviewReport.page";
import { ReportSaleByRetailerBySalespersonPage } from "./saleByRetailerBySalespersonReport.page";
         
/**
 * 
 */
const ReportConfig = {
  routes: [
    {
      path: "/report",
      element: <ReportDailyReceivedCutCompletePage />,
    },
    {
      path: "/report/dailyReceivedCutComplete",
      element: <ReportDailyReceivedCutCompletePage />,
    },
    {
      path: "/report/inventoryByFabricID",
      element: <ReportInventoryByFabricIDPage />,
    },
    {
      path: "/report/productionToReview",
      element: <ReportProductionToReviewPage />,
    },
    {
      path: "/report/salesByDateByRetailer",
      element: <ReportSalesByRetailerByDatePage />,
    },
    {
      path: "/report/salesByFabricID",
      element: <ReportSalesByFabricIDPage />,
    },
    {
      path: "/report/salesByRetailerBySalesperson",
      element: <ReportSaleByRetailerBySalespersonPage />,
    },
    {
      path: "/report/shirtsOnOrderSummary",
      element: <ReportShirtsOnOrderSummaryPage />,
    },
  ],
};

export default ReportConfig;
