import { APIBase } from './@base.api';

export class ProductionNoteApi extends APIBase {

  /**
   * 
   */
  constructor(context) {
    super(context);
    this.endPoints = {
      getAll: "ProductionNote/GetAll",
      insertNote: "ProductionNote/InsertNote",
      insert: "ProductionNote/Insert",
      update: "ProductionNote/Update",
      delete: "ProductionNote/Delete",
      get: "ProductionNote/get",
      getUserNotesByProductionID: 'ProductionNote/GetUserNotesByProductionID',
      getUserNotesByOrderID: 'ProductionNote/GetUserNotesByOrderID',
    };
  
  }

  /**
   *
   * @param {*} data
   * @returns
   */
  insertNote = data => this.httpPost(this.endPoints.insertNote, data)

  /**
   * 
   * @returns 
   */
  getUserNotesByOrderID = data => this.httpPost(this.endPoints.getUserNotesByOrderID, data);

  /**
   * 
   * @returns 
   */
  getUserNotesByProductionID = data => this.httpPost(this.endPoints.getUserNotesByProductionID, data);
  

}
