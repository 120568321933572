import { useMemo, useState } from 'react';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { SpinnerControl } from 'src/app/@core/forms/controls/spinner.control';
import { initiaDomainlData } from 'src/app/@core/@contexts/domain.context';
import { doaminDataContext } from 'src/app/@core/@contexts/domain.context';
import { ErrorBoundary } from 'src/app/@core/forms/controls/errorBoundary';
import { ErrorControl } from 'src/app/@core/forms/controls/error.control';
import { MessageBoxControl } from 'src/app/@core/forms/controls/messageBox.control';
import { ShirtUserPickerDialog } from 'src/app/@domain/@shared/dialogs/shirtUserPicker.dialog';
import { ShirtUserAddDialog } from 'src/app/@domain/@shared/dialogs/shirtUserAdd.dialog';
import { SalespersonAddDialog } from 'src/app/@domain/@shared/dialogs/salespersonAdd.dialog';
import { OrderAddDialog } from 'src/app/@domain/modules/order/add/@order.add.dialog';
import { OrderEditDialog } from 'src/app/@domain/modules/order/edit/@order.edit.dialog';

export const AppShell = (props) => {
  const [store, setStore] = useState(initiaDomainlData);
  const contextObj = useMemo(() => {
    doaminDataContext.bootstrap(store, setStore);
    return doaminDataContext;
  }, [store]);

  return (
    <DomainContext.Provider value={contextObj}>
      <SpinnerControl />
      <MessageBoxControl />
      <ErrorControl />
      <ShirtUserPickerDialog />
      <ShirtUserAddDialog />
      <SalespersonAddDialog />
      <OrderAddDialog />
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </DomainContext.Provider>
  );
};
