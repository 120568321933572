import { useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import FusePageCarded from "src/app/@core/@fuse/FusePageCarded.FullWidth";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { ModuleContext, initialModuleData, moduleDataContext } from "src/app/@core/@contexts/module.context";
import TableRow from "@mui/material/TableRow";
import { IntegerInput } from "src/app/@core/forms/controls/integerInput";
import { saveXLSX, toMMDDYYYY } from "src/app/@core/common";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { TBodyCell, TBodyCellEdit } from "src/app/@core/forms/dataTable/dataTable.control";
import { ReportBody, ReportHeader } from "src/app/@core/forms/report/report.control";
import { OrderEditDialog, OrderEditDialogController, OrderEditDialogModel, } from 'src/app/@domain/modules/order/edit/@order.edit.dialog';

export const ReportProductionToReviewPage = () => {

  const frmModel = useForm({ mode: "onChange", defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(null);

  const { api, spinner } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const frmCriteria = useForm({ mode: "onChange", defaultValues: { startDate: new Date(), endDate: new Date(), availability: '' } });
  const { control, getValues } = frmCriteria;

  const [orderEditDialogModel, setOrderEditDialogModel] = useState(new OrderEditDialogModel());
  const orderEditDialogController = new OrderEditDialogController(orderEditDialogModel, setOrderEditDialogModel);

  /**
   * 
   * @param {*} data 
   */
  const loadData = async data => {

    try {

      spinner.show();
      const result = await api.reportApi.getProductionsToReview(data);
      setGvItems(result);
      moduleDataContext.moduleGvData(result);
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  };

  /**
   *
   */
  const contextObj = useMemo(() => {
    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;
    return moduleDataContext;
  }, [store]);

  /**
   *
   */
  const onSearch = async () => {

    const obj = getValues();
    const criteria = { numberOfDays: obj.numberOfDays, };
    setSearchCriteria(criteria);
    loadData(criteria);

  };

  /**
   *
   */
  const onExport = async () => {
    try {
      spinner.show();
      const result = await api.reportApi.exportProductionsToReview(searchCriteria);
      saveXLSX(result, 'ProductionsToReview');
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }
  };

  /**
   * 
   * @param {*} item 
   */
  const onEdit = async item => {

    try {

      await orderEditDialogController.show(item.orderID);

    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  }

  const columns = [
    { id: "actionID", align: "center", disablePadding: false, label: "", sort: true, width: 70 },
    { id: "lastName", align: "center", disablePadding: false, label: "Last Name", sort: true, width: 70 },
    { id: "firstName", align: "center", disablePadding: false, label: "First Name", sort: true, width: 70 },
    { id: "retailerName", align: "center", disablePadding: false, label: "Retailer Name", sort: true, width: 70 },
    { id: "salesperson", align: "center", disablePadding: false, label: "Sales person", sort: true, width: 70 },
    { id: "newShirts", align: "center", disablePadding: false, label: "New", sort: true, width: 70 },
    { id: "repairShirts", align: "center", disablePadding: false, label: "Repair", sort: true, width: 70 },
    { id: "receiveDate", align: "center", disablePadding: false, label: "Receive Date", sort: true, width: 70 },
    { id: "prodDescText", align: "center", disablePadding: false, label: "Prod Type", sort: true, width: 70 },
    { id: "prodStatusText", align: "center", disablePadding: false, label: "Prod Status", sort: true, width: 70 },
    { id: "lastStatusUpdateDate", align: "center", disablePadding: false, label: "Updated Date", sort: true, width: 70 },
    { id: "cleanersDate", align: "center", disablePadding: false, label: "Clean Date", sort: true, width: 70 },
    { id: "Ship Date", align: "center", disablePadding: false, label: "Ship Date", sort: true, width: 70 },
    { id: "arriveDate", align: "center", disablePadding: false, label: "Arrive Date", sort: true, width: 70 },
  ];

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportCriteria = props => {

    const { frmCriteria } = props;

    return <FormProvider {...frmCriteria}>
      <IntegerInput id="numberOfDays" label="Review orders with no updates over (x) days" control={control} />
    </FormProvider>

  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportRow = props => {

    const { item } = props;

    return <TableRow hover tabIndex={-1} key={item.itemID}>
      <TBodyCellEdit onEdit={() => onEdit(item)} />
      <TBodyCell align="left" value={item.lastName} />
      <TBodyCell align="left" value={item.firstName} />
      <TBodyCell align="left" value={item.retailerName} />
      <TBodyCell align="left" value={item.salesperson} />
      <TBodyCell align="center" value={item.newShirts} />
      <TBodyCell align="center" value={item.repairShirts} />
      <TBodyCell align="center" value={toMMDDYYYY(item.receiveDate)} />
      <TBodyCell align="center" value={item.prodDescText} />
      <TBodyCell align="center" value={item.prodStatusText} />
      <TBodyCell align="center" value={toMMDDYYYY(item.lastStatusUpdateDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.cleanersDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.shipDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.arriveDate)} />
    </TableRow>
  }

  return <ModuleContext.Provider value={contextObj}>

    <OrderEditDialog controller={orderEditDialogController} />

    <FusePageCarded
      header={<ReportHeader title="Production To Review" reportCriteria={<ReportCriteria frmCriteria={frmCriteria} />} onSearch={onSearch} />}
      content={<ReportBody columns={columns} gvItems={gvItems} onExport={onExport} ReportRow={ReportRow} />}
      scroll={isMobile ? "normal" : "content"}
    />
  </ModuleContext.Provider>

};
