import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import SignInConfig from "../main/sign-in/SignInConfig";
import SignUpConfig from "../main/sign-up/SignUpConfig";
import SignOutConfig from "../main/sign-out/SignOutConfig";
import Error404Page from "../main/404/Error404Page";
import HomeConfig from "src/app/@domain/modules/home/@home.config";
import OrderCreateConfig from "src/app/@domain/modules/orderCreate/@orderCreate.config";
import OrderEditConfig from "src/app/@domain/modules/orderEdit/@orderEdit.config";
import ReportConfig from "src/app/@domain/modules/report/@report.config";
import LookupConfig from "src/app/@domain/modules/lookup/@lookup.config";
import AdminConfig from "src/app/@domain/modules/admin/@admin.config";
import AuthConfig from "src/app/@domain/modules/auth/@auth.config";

const routeConfigs = [
  AuthConfig,
  HomeConfig,
  OrderCreateConfig,
  OrderCreateConfig,
  ReportConfig,
  LookupConfig,
  AdminConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/login",
    element: <Navigate to="/login" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/",
    element: <Navigate to="/productionLog" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/createOrder",
    element: <Navigate to="/createOrder" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/editOrder",
    element: <Navigate to="/editOrder" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/report",
    element: <Navigate to="/report" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/lookup",
    element: <Navigate to="/lookup" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/admin",
    element: <Navigate to="/admin" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
