import { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';

/**
 * 
 */
export class OrderCancelDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.orderID = 0;
  }

}

/**
 * 
 */
export class OrderCancelDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   */
  submit = async () => {

    try {

      this.spinner().show();
      const result = await this.api().orderApi.cancelOrder(this.model.orderID);
      this.hide(result);

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderCancelDialog = props => {

  const { controller } = props;

  return <Dialog fullScreen style={{ margin: 'auto', maxWidth: 300, maxHeight: 200 }}
    open={controller.visible()}
    onClose={() => controller.hide(false)}
    scroll='paper'
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description">
    <DialogTitle id="scroll-dialog-title">      
      <DialogHeaderContent title="Cancel Order" onClose={() => controller.hide(false)} />
    </DialogTitle>
    <DialogContent dividers={true}>
      <div className="flex flex-col" style={{ marginRight: 20 }}>
        Do you want to cancel this order?
      </div>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" onClick={() => controller.hide(false)}>No</Button>
      <Button variant="contained" color="error" onClick={controller.submit}>Yes</Button>
    </DialogActions>
  </Dialog>
}
