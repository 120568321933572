import { APIBase } from "./@base.api";

export class FabricApi extends APIBase {

  /**
   * 
   * @param {*} context 
   */
  constructor(context) {
    super(context);
    this.endPoints = {
      insert: "Fabric/Insert",
      update: "Fabric/Update",
      delete: "Fabric/Delete",
      getAll: "Fabric/GetAll",
      get: "Fabric/Get",
      getCreateOrderFabrics: "Fabric/GetCreateOrderFabrics",

    };
  }

  /**
   * 
   * @returns 
   */
  getCreateOrderFabrics = data => this.httpPost(this.endPoints.getCreateOrderFabrics, data);

}
