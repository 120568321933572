import { createContext } from 'react';
import { isUoN } from 'src/app/@core/common';

/**
 * 
 */
class InitialModuleData {

  /**
   * 
   */
  constructor() {
    this.frmModel = null;
    this.searchText = '';
    this.page = 0;
    this.rowsPerPage = 25;
    this.comments = [];
    this.moduleGvData = [];
    this.miscData = {};
  }

  /**
   * 
   * @param {*} frmModel 
   */
  loadFormModel = frmModel => {
    this.frmModel = frmModel;
  }

}

/**
 * 
 */
class ModuleDataContext {

  frmModel = () => this.store.frmModel;

  /**
   * 
   * @param {*} val 
   * @returns 
   */
  moduleGvData = val => {
    if (isUoN(val)) return this.store.moduleGvData;
    this.setStore({ ...this.store, ...{ moduleGvData: [...val]} });
  }

  /**
   * 
   * @param {*} val 
   * @returns 
   */
  miscData = val => {
    if (isUoN(val)) return this.store.miscData;
    this.setStore({ ...this.store, ...{ miscData: val} });
  }

  /**
   * 
   * @param {*} val 
   * @returns 
   */
  comments = val => {
    if (isUoN(val)) return this.store.comments;
    this.setStore({ ...this.store, ...{ comments: [...val]} });
  }

  /**
   * 
   * @param {*} val 
   * @returns 
   */
  rowsPerPage = val => {
    if (isUoN(val)) return this.store.rowsPerPage;
    this.setStore({ ...this.store, ...{ page: 0, rowsPerPage: val } });
  }

  /**
   * 
   * @param {*} val 
   * @returns 
   */
  page = val => {
    if (isUoN(val)) return this.store.page;
    this.setStore({ ...this.store, ...{ page: val} });
  }

  /**
   * 
   * @param {*} val 
   * @returns 
   */
  searchText = val => {
    if (isUoN(val)) return this.store.searchText;
    this.setStore({ ...this.store, ...{ searchText: val} });
  }

  /**
   * 
   */
  constructor() {
    this.loadModelDataFunc = null;
    this.executeItemFilterFunc = null;
    this.setGvItems = null;
    this.searchCriteria = null;
    this.setSearchCriteria = null;
    this.spinner = null;
    this.refreshItem = null

  }

  /**
   * 
   * @param {*} store 
   * @param {*} setStore 
   */
  bootstrap(store, setStore) {
    this.store = store;
    this.setStore = setStore;
  }

  /**
   * 
   * @param {*} searchFunc 
   * @param {*} criteria 
   */
  executeSearchFunc = async (searchFunc, criteria, sortFunc)  => {

    this.spinner.show();
    const result = await searchFunc(criteria);
    const sorted = sortFunc ? sortFunc(result, 'lastStatusUpdateDate', 'desc') : result;
    this.setGvItems(sorted);
    this.moduleGvData(sorted);
    this.spinner.hide();
      
  }

  /**
   * 
   */
  executeDataFilterFunc = modelData => {    

    if (!modelData) modelData = this.moduleGvData();
    const text = this.searchText().toLowerCase();
    if (text.length > 0) {

      const result = modelData.filter(item => {        
        if (isUoN(item)) return false;
        return this.executeItemFilterFunc(item, text);
      });

      this.setGvItems(result);
      this.page(0);
      
    } else {
      this.setGvItems(modelData);
    }

  }



}

export const initialModuleData = new InitialModuleData();
export const moduleDataContext = new ModuleDataContext();

export const ModuleContext = createContext({});