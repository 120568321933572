import { useSearchParams } from "react-router-dom";
import { OrdersPage } from "src/app/@domain/modules/order/@orders.page"

export const OrderEditPage = () => {

  const [urlParams, setUrlParams] = useSearchParams();
  const orderID = urlParams.get("orderID");

  return <OrdersPage isEditOrder={true} orderID={orderID} />

}