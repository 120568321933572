import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import FusePageCarded from "src/app/@core/@fuse/FusePageCarded.FullWidth";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { ModuleContext, initialModuleData, moduleDataContext, } from "src/app/@core/@contexts/module.context";
import { TableRow } from "@mui/material";
import { TBodyCell, TBodyCellEdit, TBodyCellReport } from "src/app/@core/forms/dataTable/dataTable.control";
import { ReportBodyOrderSummary, ReportHeader } from "src/app/@core/forms/report/report.control";
import { saveXLSX } from "src/app/@core/common";
import {
  ProductionToCutReportDialog,
  ProductionToCutReportDialogController,
  ProductionToCutReportDialogModel
} from "./productionToCutRepor.dialog";
import {
  ReportOrderSummaryTotalDialogController,
  ReportOrderSummaryTotalDialogModel,
  ReportOrderSummaryTotalDialog
} from "./orderSummaryTotalReport.dialog";

/**
 * 
 * @returns 
 */
export const ReportShirtsOnOrderSummaryPage = () => {

  const frmModel = useForm({ mode: "onChange", defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const { api, spinner } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);
  const [summaryItem, setSummaryItem] = useState({
    shirtsPendingApproval: 0,
    shirtsToCut: 0,
    shirtsInProduction: 0,
    total: 0
  });

  const [productionToCutReportDialogModel, setProductionToCutReportDialogModel] = useState(new ProductionToCutReportDialogModel());
  const productionToCutReportDialogController = new ProductionToCutReportDialogController(productionToCutReportDialogModel, setProductionToCutReportDialogModel);

  const [summaryTotalModel, setSummaryTotalModel] = useState(new ReportOrderSummaryTotalDialogModel());
  const summaryTotalController = new ReportOrderSummaryTotalDialogController(summaryTotalModel, setSummaryTotalModel);

  /**
   *
   */
  const loadData = async () => {

    try {

      spinner.show();
      const result = await api.reportApi.getShirtsOnOrderSummary();
      if (!result) return;

      const summary = getSummary(result);
      setSummaryItem(summary);
      setGvItems(result);
      moduleDataContext.moduleGvData(result);

    } catch (err) { console.log(err); }
    finally { spinner.hide(); }
  };

  /**
   * 
   * @param {*} items 
   */
  const getSummary = items => {

    const summary = summaryItem;
    summary.shirtsPendingApproval = items.reduce((a, b) => a + b.shirtsPendingApproval, 0);
    summary.shirtsToCut = items.reduce((a, b) => a + b.shirtsToCut, 0);
    summary.shirtsInProduction = items.reduce((a, b) => a + b.shirtsInProduction, 0);
    summary.total = items.reduce((a, b) => a + b.total, 0);
    return summary;

  }

  /**
   *
   */
  const contextObj = useMemo(() => {
    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;

    return moduleDataContext;
  }, [store]);

  /**
   *
   */
  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  /**
   *
   */
  const onExport = async () => {
    try {
      spinner.show();
      const result = await api.reportApi.exportShirtsOnOrderSummary();
      saveXLSX(result, 'ShirtsOnOrderSummaryReport');
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }
  };

  /**
   * 
   * @param {*} props 
   */
  const onViewSummary = storedProc => {
    summaryTotalController.show(storedProc);
  }

  /**
   * 
   * @param {*} item 
   */
  const onViewProductionToCutReport = async item => await productionToCutReportDialogController.show(item.retailerShortName);

  const columns = [
    { id: "actionID", align: "center", disablePadding: false, label: "", sort: true, width: 70 },
    { id: "retailerShortName", align: "center", disablePadding: false, label: "Retailer Short Name", sort: true },
    { id: "shirtsPendingApproval", align: "center", disablePadding: false, label: "Shirts Pending", sort: true, width: 200 },
    { id: "shirtsToCut", align: "center", disablePadding: false, label: "Shirts to Cut not Pending", sort: true, width: 200 },
    { id: "shirtsInProduction", align: "center", disablePadding: false, label: "Shirts In Production", sort: true, width: 200 },
    { id: "total", align: "center", disablePadding: false, label: "Total", sort: true, width: 200 },
  ];


  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportRow = props => {

    const { item } = props;

    return <TableRow hover tabIndex={-1} key={item.itemID}>
      <TBodyCellReport onClick={() => onViewProductionToCutReport(item)} />
      <TBodyCell align="left" value={item.retailerShortName} />
      <TBodyCell align="center" value={item.shirtsPendingApproval} />
      <TBodyCell align="center" value={item.shirtsToCut} />
      <TBodyCell align="center" value={item.shirtsInProduction} />
      <TBodyCell align="center" value={item.total} />
    </TableRow>
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportCell = props => <div className="btn-view-child-report" onClick={() => onViewSummary(props.storedProc)}>{props.value}</div>

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportSummaryRow = props => {

    const { item } = props;

    return <TableRow hover tabIndex={-1} key='summaryRow' style={{ backgroundColor: '#e5e5e5' }}>
      <TBodyCell align="left" value='' />
      <TBodyCell align="right" value='Total' />
      <TBodyCell align="center" value={<ReportCell value={summaryItem.shirtsPendingApproval} storedProc="pGetAllShirtsPendingApproval" />} />
      <TBodyCell align="center" value={<ReportCell value={summaryItem.shirtsToCut} storedProc="pGetAllProductionsToCut" />} />
      <TBodyCell align="center" value={<ReportCell value={summaryItem.shirtsInProduction} storedProc="pGetAllShirtsInProduction" />} />
      <TBodyCell align="center" value={<ReportCell value={summaryItem.total} storedProc="pGetAllTotalProductions" />} />
    </TableRow>
  }


  return <ModuleContext.Provider value={contextObj}>

    <ProductionToCutReportDialog controller={productionToCutReportDialogController} />
    <ReportOrderSummaryTotalDialog controller={summaryTotalController} />

    <FusePageCarded
      header={<ReportHeader title="Shirts On Order Summary" />}
      content={<ReportBodyOrderSummary columns={columns} gvItems={gvItems} onExport={onExport} ReportRow={ReportRow} ReportSummaryRow={ReportSummaryRow} />}
      scroll={isMobile ? "normal" : "content"}
    />
  </ModuleContext.Provider>

};
