import { useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SelectInput } from "src/app/@core/forms/controls/selectInput";
import { DatePicker } from "src/app/@core/forms/controls/datePicker";
import FusePageCarded from "src/app/@core/@fuse/FusePageCarded.FullWidth";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { ModuleContext, initialModuleData, moduleDataContext } from "src/app/@core/@contexts/module.context";
import TableRow from "@mui/material/TableRow";
import { saveXLSX, toAPIDate, toMMDDYYYY } from "src/app/@core/common";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { TBodyCell, TBodyCellEdit } from "src/app/@core/forms/dataTable/dataTable.control";
import { ReportBody, ReportHeader } from "src/app/@core/forms/report/report.control";
import { InventoryEdit, InventoryEditDialogModel, InventoryEditDialogController } from 'src/app/@domain/modules/inventory/inventory.edit';
import { comboLists } from "src/app/@domain/consts/comboLists";

export const ReportInventoryByFabricIDPage = () => {

  const frmModel = useForm({ mode: "onChange", defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(null);

  const { api, spinner, dropdown } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const frmCriteria = useForm({
    mode: "onChange", defaultValues: {
      startDate: new Date().setMonth(new Date().getMonth() - 6),
      endDate: new Date(),
      availability: ''
    }
  });

  const { control, getValues } = frmCriteria;

  const [inventoryEditDialogModel, setInventoryEditDialogModel] = useState(new InventoryEditDialogModel());
  const inventoryEditDialogController = new InventoryEditDialogController(inventoryEditDialogModel, setInventoryEditDialogModel);

  /**
   * 
   * @param {*} data 
   */
  const loadData = async data => {

    try {
      spinner.show();
      const result = await api.reportApi.getInventoryByFabricID(data);
      setGvItems(result);
      moduleDataContext.moduleGvData(result);
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  };

  /**
   *
   */
  const contextObj = useMemo(() => {
    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;
    return moduleDataContext;
  }, [store]);

  /**
   *
   */
  const onSearch = async () => {

    const obj = getValues();
    const criteria = {
      availability: obj.availability,
      startDate: toAPIDate(obj.startDate),
      endDate: toAPIDate(obj.endDate)
    };
    setSearchCriteria(criteria);
    loadData(criteria);

  };

  /**
   *
   */
  const onExport = async () => {
    try {
      spinner.show();
      const result = await api.reportApi.exportInventoryByFabricID(searchCriteria);
      saveXLSX(result, 'InventoryByFabricIDReport');
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }
  };

  /**
   * 
   * @param {*} item 
   */
  const onEdit = async item => {

    try {

      console.log(item);
      const dto = {
        startDate: searchCriteria.startDate,
        endDate: searchCriteria.endDate,
        fabricID: item.fabricID
      };

      spinner.show();
      const result = await api.inventoryApi.getInventoryEdit(dto)
      spinner.hide();
      await inventoryEditDialogController.show(result);

    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  }

  const columns = [
    { id: "actionID", align: "center", disablePadding: false, label: "", sort: true, width: 70 },
    { id: "fabricID", align: "center", disablePadding: false, label: "Fabric ID", sort: true, width: 70 },
    { id: "fabricIDDescription", align: "center", disablePadding: false, label: "Fabric ID Description", sort: true, width: 70 },
    { id: "currentAvailabilityStatus", align: "center", disablePadding: false, label: "Current Availability Status", sort: true, width: 70 },
    { id: "usagePerShirt", align: "center", disablePadding: false, label: "Usage Per Shirt", sort: true, width: 70, },
    { id: "physicalInventoryDate", align: "center", disablePadding: false, label: "Physical Inventory Date", sort: true, width: 70 },
    { id: "physicalShirtsonHand", align: "center", disablePadding: false, label: "Physical Shirtson Hand", sort: true, width: 70 },
    { id: "metersOnHand", align: "center", disablePadding: false, label: "Meters On Hand", sort: true, width: 70 },
    { id: "lastOrdered", align: "center", disablePadding: false, label: "Last Ordered", sort: true, width: 70 },
    { id: "monthsInReport", align: "center", disablePadding: false, label: "Months In Report", sort: true, width: 70 },
    { id: "shirtsSoldSincePhysicalInventoryDate", align: "center", disablePadding: false, label: "Shirts Sold Since Physical Inventory Date", sort: true, width: 70 },
    { id: "newHolding", align: "center", disablePadding: false, label: "New Holding", sort: true, width: 70 },
    { id: "repairHolding", align: "center", disablePadding: false, label: "Repair Holding", sort: true, width: 70 },
    { id: "newPending", align: "center", disablePadding: false, label: "New Pending", sort: true, width: 70 },
    { id: "repairPending", align: "center", disablePadding: false, label: "Repair Pending", sort: true, width: 70 },
    { id: "newtoCut", align: "center", disablePadding: false, label: "New to Cut", sort: true, width: 70 },
  ];

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportCriteria = props => {

    const { frmCriteria } = props;

    return <FormProvider {...frmCriteria}>
      <div style={{ flex: 1, display: "flex" }}>
        <DatePicker id="startDate" label="Report Start Date" control={control} />
        &nbsp;
        <DatePicker id="endDate" label="Report End Date" control={control} />
        &nbsp;
        <SelectInput id="availability" label="Availability" control={control} items={comboLists.availablility} />
      </div>
    </FormProvider>

  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportRow = props => {

    const { item } = props;

    return <TableRow hover tabIndex={-1} key={item.itemID}>
      <TBodyCellEdit onEdit={() => onEdit(item)} />
      <TBodyCell align="center" value={item.fabricID} />
      <TBodyCell align="left" value={item.fabricIDDescription} />
      <TBodyCell align="center" value={item.currentAvailabilityStatus} />
      <TBodyCell align="center" value={item.usagePerShirt} />
      <TBodyCell align="center" value={toMMDDYYYY(item.physicalInventoryDate)} />
      <TBodyCell align="center" value={item.physicalShirtsonHand} />
      <TBodyCell align="center" value={item.metersOnHand} />
      <TBodyCell align="center" value={toMMDDYYYY(item.lastOrdered)} />
      <TBodyCell align="center" value={item.monthsInReport} />
      <TBodyCell align="center" value={item.shirtsSoldSincePhysicalInventoryDate} />
      <TBodyCell align="center" value={item.newHolding} />
      <TBodyCell align="center" value={item.repairHolding} />
      <TBodyCell align="center" value={item.newPending} />
      <TBodyCell align="center" value={item.repairPending} />
      <TBodyCell align="center" value={item.newtoCut} />
    </TableRow>
  }

  return <ModuleContext.Provider value={contextObj}>
    <InventoryEdit controller={inventoryEditDialogController} />
    <FusePageCarded
      header={<ReportHeader title="Inventory By Fabric ID" reportCriteria={<ReportCriteria frmCriteria={frmCriteria} />} onSearch={onSearch} />}
      content={<ReportBody columns={columns} gvItems={gvItems} onExport={onExport} ReportRow={ReportRow} />}
      scroll={isMobile ? "normal" : "content"}
    />
  </ModuleContext.Provider>

};
