import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useContext, useEffect } from 'react';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { ModuleContext } from 'src/app/@core/@contexts/module.context';

export const LoginPage = props => {

  const { api, dropdown, spinner } = useContext(DomainContext);
  const { comments, loadModelDataFunc } = useContext(ModuleContext);
  const frmModel = useForm({
    mode: 'onChange', defaultValues: {
      password: ''
    }
  });
  const { control, getValues, dirtyFields, errors } = frmModel;

  const handleSubmit = args => {

  }

  const onSubmit = args => {

  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="w-full h-full sm:h-auto md:flex md:items-center md:justify-center w-full md:h-full md:w-full">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Sign in
          </Typography>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              className="mb-24"
              label="Email"
              autoFocus
              type="email"
              variant="outlined"
              required
              fullWidth
            />

            <TextField
              className="mb-24"
              label="Password"
              type="password"
              variant="outlined"
              required
              fullWidth
            />

            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
              <FormControl>
                <FormControlLabel
                  label="Remember me"
                  control={<Checkbox size="small" />}
                />
              </FormControl>

            </div>

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="Sign in"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Sign in
            </Button>
          </form>
        </div>
      </Paper>

    </div>
  );

}