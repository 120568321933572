export class ContactModel {

  /**
   *
   * @returns
   */
  createNew = () => {
    return {
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      comments: "",
      retailer: null
    };
  };
}

export const contactModel = new ContactModel();
