import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DatePicker } from "src/app/@core/forms/controls/datePicker";
import FusePageCarded from "src/app/@core/@fuse/FusePageCarded.FullWidth";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { ModuleContext, initialModuleData, moduleDataContext } from "src/app/@core/@contexts/module.context";
import TableRow from "@mui/material/TableRow";
import { saveXLSX, toAPIDate } from "src/app/@core/common";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { TBodyCell } from "src/app/@core/forms/dataTable/dataTable.control";
import { ReportBody, ReportHeader } from "src/app/@core/forms/report/report.control";
import { InventoryEdit, InventoryEditDialogModel, InventoryEditDialogController } from 'src/app/@domain/modules/inventory/inventory.edit';
import { TextSelectMultiInput } from 'src/app/@core/forms/controls/textSelectMultiInput';
import { comboLists } from "src/app/@domain/consts/comboLists";

export const ReportSaleByRetailerBySalespersonPage = () => {

  const frmModel = useForm({ mode: "onChange", defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(null);

  const { api, spinner, dropdown } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const frmCriteria = useForm({
    mode: "onChange", defaultValues: {
      startDate: new Date().setMonth(new Date().getMonth() - 6),
      endDate: new Date(),
      availability: ''
    }
  });

  const { control, getValues } = frmCriteria;

  const [inventoryEditDialogModel, setInventoryEditDialogModel] = useState(new InventoryEditDialogModel());
  const inventoryEditDialogController = new InventoryEditDialogController(inventoryEditDialogModel, setInventoryEditDialogModel);
  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [selectedShirtTypes, setSelectedShirtTypes] = useState([]);

  /**
   * 
   */
  useEffect(() => {
    loadRetailers();
    return () => { };
  }, []);

  /**
   * 
   */
  const loadRetailers = async () => {
    const result = await api.retailerApi.getDropdownItems();
    setRetailers(result);
  }

  /**
   * 
   * @param {*} data 
   */
  const loadData = async data => {

    try {
      spinner.show();
      console.log(data);
      const result = await api.reportApi.getSalesByRetailerBySalesperson(data);
      setGvItems(result);
      moduleDataContext.moduleGvData(result);
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  };

  /**
   *
   */
  const contextObj = useMemo(() => {
    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;
    return moduleDataContext;
  }, [store]);

  /**
   *
   */
  const onSearch = async () => {

    const obj = getValues();
    obj.retailers = selectedRetailers;
    obj.shirtTypes = selectedShirtTypes;

    const criteria = {
      startDate: toAPIDate(obj.startDate),
      endDate: toAPIDate(obj.endDate),
      retailers: selectedRetailers,
      shirtTypes: selectedShirtTypes
    };
    setSearchCriteria(criteria);
    loadData(criteria);

  };

  /**
   *
   */
  const onExport = async () => {
    try {
      spinner.show();
      const result = await api.reportApi.exportSalesByRetailerBySalesperson(searchCriteria);
      saveXLSX(result, 'SalesByRetailerBySalesperson');
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }
  };

  /**
   * 
   * @param {*} item 
   */
  const onEdit = async item => {

    try {

      console.log(item);
      const dto = {
        startDate: searchCriteria.startDate,
        endDate: searchCriteria.endDate,
        fabricID: item.fabricID
      };

      spinner.show();
      const result = await api.inventoryApi.getInventoryEdit(dto)
      spinner.hide();
      await inventoryEditDialogController.show(result);

    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  }

  const columns = [
    { id: "retailerShortName", align: "center", disablePadding: false, label: "Retailer Name", sort: true },
    { id: "salespersonName", align: "center", disablePadding: false, label: "Salesperson Name", sort: true },
    { id: "quantity", align: "center", disablePadding: false, label: "Current Period", sort: true, width: 150 },
    { id: "pastQuantity", align: "center", disablePadding: false, label: "Prior Period", sort: true, width: 150, },
    { id: "percentageChange", align: "center", disablePadding: false, label: "% Change", sort: true, width: 150, },
  ];

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportCriteria = props => {

    const { frmCriteria } = props;

    return <FormProvider {...frmCriteria}>
      <div style={{ flex: 1, display: "flex" }}>
        <DatePicker id="startDate" label="Report Start Date" control={control} />
        &nbsp;
        <DatePicker id="endDate" label="Report End Date" control={control} />
        &nbsp;
        <TextSelectMultiInput id="retailers" label="Retailers" control={control} items={retailers} title="Select Retailers"
          selectedItems={selectedRetailers} setSelectedItems={setSelectedRetailers} />
        &nbsp;
        <TextSelectMultiInput id="shirtTypes" label="Shirt Types" control={control} items={comboLists.shirtTypes} title="Select Shirt Types"
          selectedItems={selectedShirtTypes} setSelectedItems={setSelectedShirtTypes} />
      </div>
    </FormProvider>

  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportRow = props => {

    const { item } = props;

    return <TableRow hover tabIndex={-1} key={item.itemID}>
      <TBodyCell align="left" value={item.retailerShortName} />
      <TBodyCell align="left" value={item.salespersonName} />
      <TBodyCell align="center" value={item.quantity} />
      <TBodyCell align="center" value={item.pastQuantity} />
      <TBodyCell align="center" value="" />
    </TableRow>
  }

  return <ModuleContext.Provider value={contextObj}>
    <InventoryEdit controller={inventoryEditDialogController} />
    <FusePageCarded
      header={<ReportHeader title="Sales By Retailer By Salesperson" reportCriteria={<ReportCriteria frmCriteria={frmCriteria} />} onSearch={onSearch} />}
      content={<ReportBody columns={columns} gvItems={gvItems} onExport={onExport} ReportRow={ReportRow} />}
      scroll={isMobile ? "normal" : "content"}
    />
  </ModuleContext.Provider>

};
