import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { isUoN } from "src/app/@core/common";

export const TextArea = props => {

  const { id, label, control, disabled = false, rows, rules, defaultValue = '', value = '' } = props;

  if (!control) {
    return <TextField
      size="small"
      label={label}
      className="mt-8 mb-2 mx-8"
      fullWidth
      variant="outlined"
      disabled={disabled}
      rows={isUoN(rows) ? 4 : rows}
      multiline
      value={value}
    />
  } else {
    return <Controller
      name={id}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField
          size="small"
          {...field}
          helperText={error ? error.message : null}
          error={!!error}
          id={id}
          label={label}
          className="mt-8 mb-2 mx-8"
          fullWidth
          variant="outlined"
          disabled={disabled}
          rows={isUoN(rows) ? 4 : rows}
          multiline
        />
      )}
    />
  }

};
