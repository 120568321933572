import { APIBase } from './@base.api';

export class ReportApi extends APIBase {

  /**
   * 
   * @param {*} context 
   */
  constructor(context) {
    super(context);
    this.endPoints = {
      getInventoryByFabricID: "Report/GetInventoryByFabricID",
      exportInventoryByFabricID: "Report/ExportInventoryByFabricID",
      getProductionsToReview: "Report/GetProductionsToReview",
      exportProductionsToReview: "Report/ExportProductionsToReview",
      getSalesByFabricID: "Report/GetSalesByFabricID",
      exportSalesByFabricID: "Report/ExportSalesByFabricID",
      getShirtsOnOrderSummary: "Report/GetShirtsOnOrderSummary",
      exportShirtsOnOrderSummary: "Report/ExportShirtsOnOrderSummary",
      getProductionsToCut: "Report/GetProductionsToCut",
      exportProductionsToCut: "Report/ExportProductionsToCut",
      getDailyReceivedCutComplete: "Report/GetDailyReceivedCutComplete",
      exportDailyReceivedCutComplete: "Report/ExportDailyReceivedCutComplete",
      getDailyShirts: "Report/GetDailyShirts",
      exportDailyShirts: "Report/ExportDailyShirts",
      getDailyShirtsAverage: "Report/GetDailyShirtsAverage",
      exportDailyShirtsAverage: "Report/ExportDailyShirtsAverage",
      getSalesByRetailerBySalesperson: "Report/GetSalesByRetailerBySalesperson",
      exportSalesByRetailerBySalesperson: "Report/ExportSalesByRetailerBySalesperson",
      getShirtsOnOrderSummaryTotal: "Report/GetShirtsOnOrderSummaryTotal",
      exportShirtsOnOrderSummaryTotal: "Report/ExportShirtsOnOrderSummaryTotal",
    };
  }

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  exportShirtsOnOrderSummaryTotal = data => this.httpDownload(this.endPoints.exportShirtsOnOrderSummaryTotal, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  getShirtsOnOrderSummaryTotal = data => this.httpPost(this.endPoints.getShirtsOnOrderSummaryTotal, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  exportSalesByRetailerBySalesperson = data => this.httpDownload(this.endPoints.exportSalesByRetailerBySalesperson, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  getSalesByRetailerBySalesperson = data => this.httpPost(this.endPoints.getSalesByRetailerBySalesperson, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  exportDailyShirtsAverage = data => this.httpDownload(this.endPoints.exportDailyShirtsAverage, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  getDailyShirtsAverage = data => this.httpPost(this.endPoints.getDailyShirtsAverage, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  exportDailyShirts = data => this.httpDownload(this.endPoints.exportDailyShirts, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  getDailyShirts = data => this.httpPost(this.endPoints.getDailyShirts, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  exportDailyReceivedCutComplete = data => this.httpDownload(this.endPoints.exportDailyReceivedCutComplete, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  getDailyReceivedCutComplete = data => this.httpPost(this.endPoints.getDailyReceivedCutComplete, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  exportProductionsToCut = data => this.httpDownload(this.endPoints.exportProductionsToCut, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  getProductionsToCut = data => this.httpPost(this.endPoints.getProductionsToCut, data);

  /**
   * 
   * @returns 
   */
  exportShirtsOnOrderSummary = () => this.httpDownload(this.endPoints.exportShirtsOnOrderSummary);

  /**
   * 
   * @returns 
   */
  getShirtsOnOrderSummary = () => this.httpPost(this.endPoints.getShirtsOnOrderSummary);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  getSalesByFabricID = data => this.httpPost(this.endPoints.getSalesByFabricID, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  exportSalesByFabricID = data => this.httpDownload(this.endPoints.exportSalesByFabricID, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  exportProductionsToReview = data => this.httpDownload(this.endPoints.exportProductionsToReview, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  getProductionsToReview = data => this.httpPost(this.endPoints.getProductionsToReview, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  exportInventoryByFabricID = data => this.httpDownload(this.endPoints.exportInventoryByFabricID, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  getInventoryByFabricID = data => this.httpPost(this.endPoints.getInventoryByFabricID, data);

}
