import { useContext } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import { DomainContext } from "src/app/@core/@contexts/domain.context";

export class FormsBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    this.model = model;
    this.setModel = setModel;
    this.domainContext = useContext(DomainContext);
    this.user = useSelector(selectUser);

  }

  api = () => this.domainContext.api;
  spinner = () => this.domainContext.spinner; 
  messageBox = () => this.domainContext.messageBox; 

}