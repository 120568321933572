import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { useState } from "react";
import { SelectItemsDialogModel, SelectItemsDialogController, SelectItemsDialog } from "src/app/@core/forms/dialog/selectItems.dialog";
import { HtmlTooltip } from "./htmlTooltip";

export const TextSelectMultiInput = props => {

  const { id, label, control, style, rules, defaultValue = '', value = '', items, title, selectedItems, setSelectedItems } = props;

  const [selectItemsDialogModel, setSelectItemsDialogModel] = useState(new SelectItemsDialogModel);
  const selectItemsDialogController = new SelectItemsDialogController(selectItemsDialogModel, setSelectItemsDialogModel);

  /**
   * 
   * @param {*} e 
   */
  const onClick = async e => {
    const selected = await selectItemsDialogController.show(title, items, selectedItems);
    setSelectedItems(selected);
  }


  /**
   * 
   * @returns
   */
  const getSelectedItemsText = () => {

    if (!selectedItems || selectedItems.length === 0) return '';

    let result = '';
    if (selectedItems.length > 0) result = selectedItems.reduce((a, c) => `${a},${c.name}`, result);
    if (result.length > 0) result = result.substring(1);

    return result;
  }

  /**
   * 
   * @returns 
   */
  const getSelectedItemsTooltip = () => {
    if (!selectedItems || selectedItems.length === 0) return '';
    return <>{selectedItems.map((item, index) => <div key={item.itemID}>{item.name}</div>)}</>
  }

  if (!control) {
    return <>
      <SelectItemsDialog controller={selectItemsDialogController} />
      <TextField
        size="small"
        label={label}
        className="mt-8 mb-2 mx-8"
        fullWidth
        variant="outlined"
        inputProps={{}}
        style={style}
        value={value}
      />
    </>
  } else {
    return <>
      <SelectItemsDialog controller={selectItemsDialogController} />
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          return (
            <HtmlTooltip title={getSelectedItemsTooltip()}>
              <TextField
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                id={id}
                label={label}
                className="mt-8 mb-2 mx-8"
                fullWidth
                variant="outlined"
                inputProps={{}}
                style={style}
                {...field}
                onClick={onClick}
                value={getSelectedItemsText()}
              />
            </HtmlTooltip>
          );
        }}
      />
    </>
  }
};
