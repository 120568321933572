export class RetailerModel {

  cleanse = obj => {}

  /**
   *
   * @returns
   */
  createNew = () => {
    return {
      retailerName: '',
      retailerShortName: '',
      retailerCity: '',
      retailerState: '',
      isActive: false,
    };
  };
}

export const retailerModel = new RetailerModel();
