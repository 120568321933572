import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { OrderAddStep01 } from './order.add.step01';
import { OrderAddStep02 } from './order.add.step02';
import { OrderAddStep03 } from './order.add.step03';
import { OrderAddStep04 } from './order.add.step04';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import { DialogBaseV1 } from 'src/app/@core/forms/dialog/@dialogBaseV1';
import { stableSort } from 'src/app/@core/common';

/**
 *
 */
export class OrderAddDialogController extends DialogBaseV1 {
  /**
   *
   * @param {*} context
   */
  constructor(context) {
    super(context, 'orderAddDialogDataObj');
    this.loadForm = null;
  }

  /**
   *
   * @param {*} loadFormFunc
   */
  bootstrap(loadFormFunc) {
    this.loadForm = loadFormFunc;
  }

  /**
   *
   * @returns
   */
  contacts = () => this.model().contacts;

  /**
   *
   * @returns
   */
  retailers = () => this.model().retailers;

  /**
   * 
   * @returns 
   */
  loadRetailers = async () => {
    const items = await this.api().retailerApi.getByLocation();
    const result = stableSort(items, 'name', 'asc');
    return result;
  }

  /**
   * 
   * @returns 
   */
  loadContacts = async () => {
    const items = await this.api().contactApi.getDropdownItems();
    const result = stableSort(items, 'lastName', 'asc');
    return result;
  }

  /**
   * 
   */
  loadFormDataToStore = async () => {

    const contacts = await this.loadContacts();
    const retailers = await this.loadRetailers();

    this.updateStore(x => {
      x[this.dataObjName].contacts = contacts;
      x[this.dataObjName].retailers = retailers;
    });

  }

  /**
   *
   * @returns
   */
  show = async () => {
    try {
      this.spinner().show();

      if (this.loadForm) this.loadForm();
      this.loadFormDataToStore();

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

    return super.show();
  };
}

const steps = [
  'Select Shirt User, Retailer, and Salesperson',
  'Enter Fabric IDs',
  'Add Additional Shirts',
  'Cut Trial',
];

const fabricModel = { fabricID: '', quantity: 1, trial: false };

/**
 *
 * @param {*} props
 * @returns
 */
export const OrderAddDialog = (props) => {

  const modelData = {
    retailers: [],
    salespersons: [],
    shirtUser: null,
    retailer: { id: '', itemID: '', name: '' },
    salesperson: null,
    shirtType: { value: '' },
    fabrics: {},
    moreShirt: false,
    cutTrial: false,
    frmCommon: useForm({ mode: 'onChange', defaultValues: {} }),
    frmFabric: useForm({ mode: 'onChange', defaultValues: { fabrics: [] } }),
  };

  const { api, spinner, messageBox, orderAddDialogController } = useContext(DomainContext);

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [localModel, setLocalModel] = useState(modelData);

  /**
   * 
   * @param {*} func 
   */
  const updateLocalModel = func => {
    const model = localModel;
    func(model);
    setLocalModel(model);
  }

  /**
   *
   */
  useEffect(() => {
    orderAddDialogController.bootstrap(loadForm);

    return () => { };
  }, []);

  /**
   *
   * @returns
   */
  const loadForm = () => {
    setActiveStep(0);
    modelData.frmFabric.setValue('fabrics', Array(20).fill(fabricModel));
    setLocalModel(modelData);
  };

  /**
   *
   */
  const onClose = () => orderAddDialogController.hide();

  /**
   *
   * @returns
   */
  const totalSteps = () => steps.length;

  /**
   *
   * @returns
   */
  const isLastStep = () => activeStep === totalSteps() - 1;

  /**
   *
   */
  const hasOneShirt = () => {
    const fabricIDs = [];
    for (let i = 0; i < localModel.frmFabric.getValues().fabrics.length; i++) {
      const fabric = localModel.frmFabric.getValues().fabrics[i];
      const fabricID = fabric.fabricID.trim();
      if (fabricID === '') continue;
      if (!fabricIDs.includes(fabricID)) fabricIDs.push(fabricID);
      if (fabricIDs.length > 1) return false;
    }

    return true;
  };

  /**
   *
   */
  const hasNoShirt = () => {
    for (let i = 0; i < localModel.frmFabric.getValues().fabrics.length; i++) {
      const fabric = localModel.frmFabric.getValues().fabrics[0];
      const fabricID = fabric.fabricID.trim();
      if (fabricID !== '') return false;
    }

    return true;
  };

  /**
   *
   */
  const getFabrics = async () => {
    const fabrics = [];
    try {
      spinner.show();
      const frmEls = localModel.frmFabric.getValues();
      // const result = await api.fabricApi.getCreateOrderFabrics(dto);
      const result = frmEls.fabrics.filter(x => x.fabricID != '');
      if (result.length > 0) fabrics.push(...result);

    } catch (err) {
      console.error(err);
    } finally {
      spinner.hide();
    }

    return fabrics;
  };

  /**
   *
   * @returns
   */
  const executeStep1 = () => {

    if (!localModel.shirtType || localModel.shirtType.value.length === 0) {
      messageBox.showError('Please select a Shirt Type.');
      return false;
    }

    if (hasNoShirt()) {
      messageBox.showError('A shirt is required to go to the next step.');
      return false;
    }

    updateLocalModel(x => x.moreShirt = false);

    return true;
  };

  /**
   *
   */
  const executeStep0 = () => {

    if (!localModel.shirtUser) {
      messageBox.showError('Please select a Shirt User.');
      return false;
    }

    if (!localModel.retailer) {
      messageBox.showError('Please select a Retailer.');
      return false;
    }

    if (!localModel.salesperson) {
      messageBox.showError('Please select a Salesperson.');
      return false;
    }

    return true;

  };

  /**
   * 
   * @param {*} validFabrics 
   */
  const validateValidFabrics = validFabrics => {

    const invalidFabricIDs = [];
    const fabrics = localModel.frmFabric.getValues().fabrics;
    for (let i = 0; i < fabrics.length; i++) {
      const fabricID = fabrics[i].fabricID;
      if (fabricID === '') continue;
      const validFabric = validFabrics.find(x => x.fabricID === fabricID);
      if (!validFabric) invalidFabricIDs.push(fabricID);
    }

    if (invalidFabricIDs.length === 0) return true;

    messageBox.showError('Please remove the following invalid fabrics to continue: ' + invalidFabricIDs.toString());
    return false;

  }

  /**
   * 
   */
  const validateDuplicateFabrics = () => {

    const fabrics = localModel.frmFabric.getValues().fabrics;
    const fabricIDs = fabrics.filter(x => x.fabricID !== '').map(x => x.fabricID);

    const uniqueSet = new Set(fabricIDs);
    const duplicates = fabricIDs.filter(x => {
      if (!uniqueSet.has(x)) return true;
      uniqueSet.delete(x);
      return false;
    });

    if (duplicates.length === 0) return true;

    messageBox.showError('Please remove the following duplicate fabrics to continue: ' + duplicates.toString());
    return false;

  }

  /**
   *
   * @returns
   */
  const handleNext = async () => {
    if (isLastStep()) return;

    if (activeStep === 0 && !executeStep0()) return;
    if (activeStep === 1 && !executeStep1()) return;

    if (activeStep === 1) {

      // if (!validateDuplicateFabrics()) return false;

      const fabrics = await getFabrics();
      console.log(fabrics);
      // if (!validateValidFabrics(fabrics)) return false;

      if (fabrics.length === 0) {
        messageBox.showError('A shirt is required to go to the next step.');
        return false;
      }

      setLocalModel({ ...localModel, fabrics: fabrics });

    }

    let nextStep = activeStep;
    if (activeStep < 2) nextStep++;
    else if (activeStep == 2) {
      if (localModel.moreShirt) nextStep--;
      else nextStep++;
    }

    setActiveStep(nextStep);
  };

  /**
   *
   * @returns
   */
  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   *
   * @returns
   */
  const showNextButton = () => activeStep < 3;

  /**
   *
   * @returns
   */
  const showCreateButton = () => activeStep === 3 ;

  /**
   *
   */
  const hasTrialShirt = () => {
    const fabrics = localModel.fabrics;
    for (const prop in fabrics) {
      const fabric = fabrics[prop];
      if (!fabric) continue;
      if (fabric.trial) return true;
    }
    return false;
  };

  /**
   *
   */
  const onSubmit = async () => {
    if (hasNoShirt()) return;

    const dto = {
      shirtUserID: localModel.shirtUser.contactID,
      retailerID: Number(localModel.retailer.id),
      salespersonID: localModel.salesperson.contactID,
      shirtType: localModel.shirtType.value,
      fabrics: localModel.fabrics,
      cutTrial: localModel.cutTrial,
    };

    if (localModel.cutTrial && !hasTrialShirt()) {
      messageBox.showError('Please select a Trial Shirt.');
      return false;
    }

    try {

      spinner.show();
      const result = await api.orderApi.createOrder(dto);
      orderAddDialogController.hide(result);

    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  };

  return (
    <Dialog
      fullScreen
      style={{ margin: 'auto', maxWidth: 800, maxHeight: 800 }}
      open={orderAddDialogController.visible()}
      onClose={onClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>
        <DialogHeaderContent title='Create Order' onClose={onClose} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color='inherit'>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === 0 && (
              <OrderAddStep01
                controller={orderAddDialogController}
                localModel={localModel}
                setLocalModel={setLocalModel}
              />
            )}
            {activeStep === 1 && (
              <OrderAddStep02
                localModel={localModel}
                setLocalModel={setLocalModel}
              />
            )}
            {activeStep === 2 && (
              <OrderAddStep03
                localModel={localModel}
                setLocalModel={setLocalModel}
              />
            )}
            {activeStep === 3 && (
              <OrderAddStep04
                localModel={localModel}
                setLocalModel={setLocalModel}
              />
            )}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={onClose}>
          Cancel
        </Button>
        {activeStep > 0 && (
          <Button variant='contained' color='secondary' onClick={handleBack}>
            Previous
          </Button>
        )}
        {showNextButton() && (
          <Button variant='contained' color='secondary' onClick={handleNext}>
            Next
          </Button>
        )}
        {showCreateButton() && (
          <Button variant='contained' color='secondary' onClick={onSubmit}>
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
