import { useContext } from 'react';
import Button from '@mui/material/Button';
import { Motion20 } from 'src/app/@core/forms/controls/motion20';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getFullName, getFullNameByLastName } from 'src/app/@core/common';

/**
 *
 * @param {*} props
 * @returns
 */
export const OrderAddStep01 = (props) => {

  const { controller, localModel, setLocalModel } = props;

  const { shirtUserPickerDialogController,
    shirtUserAddDialogController,
    salesPersonAddDialogController,
    api, spinner
  } = useContext(DomainContext);

  /**
   *
   * @returns
   */
  const onSelectShirtUser = async () => {

    const shirtUser = await shirtUserPickerDialogController.show();
    if (!shirtUser) return;

    loadShirtUser(shirtUser);

  };

  /**
   * 
   * @param {*} item 
   */
  const onRetailerChange = item => {
    if (!item) return;
    const retailer = controller.retailers().find(x => x.id == item.id);
    const salespersons = controller.contacts().filter(x => x.retailerID == item.id);
    const salesperson = salespersons.length > 0 ? salespersons[0] : null;
    setLocalModel({ ...localModel, retailer: retailer, salesperson: salesperson, salespersons: salespersons });
  }

  /**
   * 
   * @param {*} item 
   */
  const onSalespersonChange = item => {
    setLocalModel({ ...localModel, ...{ salesperson: item } });
  }

  /**
   *
   * @returns
   */
  const onAddShirtUser = async () => {

    const shirtUser = await shirtUserAddDialogController.show();
    if (!shirtUser) return;

    loadShirtUser(shirtUser);

  };

  /**
   * 
   * @param {*} shirtUser 
   */
  const loadShirtUser = shirtUser => {

    const retailer = controller.retailers().find(x => x.id == shirtUser.retailerID);
    const salespersons = controller.contacts().filter(x => x.retailerID == shirtUser.retailerID);

    setLocalModel({
      ...localModel,
      shirtUser: shirtUser,
      retailer: retailer,
      salesperson: salespersons[0],
      retailers: controller.retailers(),
      salespersons: salespersons
    });

  }

  /**
   *
   * @returns
   */
  const onAddSalesPerson = async () => {

    const contact = await salesPersonAddDialogController.show();
    if (!contact) return;

    await loadSalesperson(contact);

  };

  /**
   * 
   * @param {*} contact 
   */
  const loadSalesperson = async contact => {

    try {

      spinner.show();

      const contacts = await controller.loadContacts();
      const salespersons = contacts.filter(x => x.retailerID == localModel.retailer.id);
      const salesperson = salespersons.find(x => x.contactID === contact.contactID)

      setLocalModel({ ...localModel, salesperson: salesperson, salespersons: salespersons });
      controller.updateStore(x => x[controller.dataObjName].contacts = contacts);
  
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  }

  return (
    <div
      style={{
        margin: '20px 10px',
        padding: 40,
        border: '1px solid #cbcbcb',
        borderRadius: 10,
      }}
    >
      <div className='flex flex-row flex-start m-0'>
        <div style={{ flex: 1 }}>
          <TextField
            size="small"
            label='Please select a Shirt User'
            className="mt-8 mb-2 mx-8"
            fullWidth
            variant="outlined"
            disabled={true}
            value={getFullName(localModel.shirtUser)}
          />
        </div>
        <div style={{ width: 200, marginLeft: 20, textAlign: 'left' }}>
          <Motion20>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<FuseSvgIcon>heroicons-outline:search</FuseSvgIcon>}
              style={{ marginTop: 8, justifySelf: 'end' }}
              onClick={onSelectShirtUser}
            >
              Select
            </Button>
            &nbsp;
            <Button
              variant='contained'
              color='secondary'
              startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
              style={{ marginTop: 8, justifySelf: 'end' }}
              onClick={onAddShirtUser}
            >
              Create
            </Button>
          </Motion20>
        </div>
      </div>
      <div className='flex flex-row'>
        <div style={{ flex: 1 }}>
          <Autocomplete
            className='mt-9 mb-2 mx-8'
            disablePortal
            options={localModel.retailers}
            value={localModel.retailer}
            fullWidth
            onChange={(e, data) => onRetailerChange(data)}
            getOptionLabel={(x) => x.name}
            renderOption={(props, option) => <li {...props} key={option.itemID}>{option.name}</li>}
            renderInput={params => <TextField {...params} className='' label='Please select a Retailer' size='small' />}
          />
        </div>
        <div style={{ width: 200, marginLeft: 20 }}></div>
      </div>
      <div className='flex flex-row'>
        <div style={{ flex: 1 }}>
          <Autocomplete
            className='mt-9 mb-2 mx-8'
            disablePortal
            options={localModel.salespersons}
            value={localModel.salesperson}
            fullWidth
            filterOptions={createFilterOptions({ matchFrom: 'any', limit: 500, })}
            onChange={(e, data) => onSalespersonChange(data)}
            getOptionLabel={x => getFullNameByLastName(x)}
            renderOption={(props, option) => <li {...props} key={option.contactID}>{getFullNameByLastName(option)}</li>}
            renderInput={(params) => <TextField {...params} className='' label='Please select a Saleperson' size='small' />}
          />
        </div>
        <div style={{ width: 200, marginLeft: 20 }}>
          <Motion20>
            <Button variant='contained' color='secondary' startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
              style={{ marginTop: 8, justifySelf: 'end' }}
              onClick={onAddSalesPerson}>
              Create
            </Button>
          </Motion20>
        </div>
      </div>
    </div>
  );
};
