import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const PHeader = props => {

  return <div>
    <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between container">
      <Typography
        component={motion.span}
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="text-24 md:text-32 font-extrabold tracking-tight"
      >
        {props.title}
      </Typography>
    </div>
    <hr />

  </div>

}
