import { OrdersPage } from 'src/app/@domain/modules/order/@orders.page';
import { authRoles } from 'src/app/auth';
import AdminRoleExample from 'src/app/main/auth/admin-role-example/AdminRoleExample';
import { OrderCreatePage } from 'src/app/@domain/modules/orderCreate/orderCreate.page';
import { OrderEditPage } from 'src/app/@domain/modules/orderEdit/orderEdit.page';

const HomeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: '/productionLog',
      element: <OrdersPage />,
    },
    {
      path: '/createOrder',
      element: <OrderCreatePage />,
    },
    {
      path: '/editOrder',
      element: <OrderEditPage />,
    },
    {
      path: '/auth/admin',
      element: <AdminRoleExample />,
    },
  ],
};

export default HomeConfig;

