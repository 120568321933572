import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from 'src/app/@core/forms/controls/textInput';
import { TextArea } from 'src/app/@core/forms/controls/textArea';
import { IntegerInput } from 'src/app/@core/forms/controls/integerInput';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { getImgFromID, isUoN, toLocalImg, toMMDDYYYY } from 'src/app/@core/common';

/**
 *
 */
export class InventoryEditDialogModel extends DialogModelBase {
  /**
   *
   */
  constructor() {

    super();

    this.frmLocal = useForm({ mode: 'onChange', defaultValues: { physicalInventoryDate: toMMDDYYYY(new Date()) } });
    this.frmUpdate = useForm({ mode: 'onChange', defaultValues: { physicalInventoryDate: toMMDDYYYY(new Date()) } });
    this.fabricCheckItems = [];
    this.fabricCheckTotal = {};
    this.getInventoryFunc = null;
  }
}
/**
 *
 */
export class InventoryEditDialogController extends DialogBase {
  /**
   *
   * @param {*} model
   * @param {*} setModel
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   *
   * @param {*} frmVals
   * @param {*} frmUpdateVals
   */
  submit = async (frmVals, frmUpdateVals) => {

    try {

      this.spinner().show();
      const modelVals = frmVals;
      const patch = frmUpdateVals;
      patch.fabricID = modelVals.name;
      patch.metersOnHand = patch.metersOnHand.trim();

      if (patch.metersOnHand.length === 0) return;

      patch.metersOnHand = Number(patch.metersOnHand);
      patch.physicalInventoryDate = new Date(patch.physicalInventoryDate);
      await this.api().inventoryApi.updatePhysicalInventory(patch);

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

    let item = await this.model.getInventoryFunc();
    item = this.loadCurrentObj(item);
    console.log(item);
    this.model.frmLocal.reset(item);
    this.model.frmUpdate.reset({ metersOnHand: '' });

    this.updateModel(x => {
      x.fabricCheckItems = item.fabricCheckItems;
      x.fabricCheckTotal = item.fabricCheckTotal;
    });

  };

  /**
   *
   * @param {*} frmUpdateVals
   */
  onClearMetersOnHand = (frmUpdateVals) => {
    const patch = frmUpdateVals;
    patch.metersOnHand = '';
    this.model.frmUpdate.reset(patch);
  };

  /**
   *
   * @param {*} obj
   * @returns
   */
  loadCurrentObj = obj => {

    const result = { ...obj };
    result.arrival = toMMDDYYYY(result.arrival);
    result.physicalInventoryDate = toMMDDYYYY(result.physicalInventoryDate);
    if (isUoN(result.usagePerShirt)) result.usagePerShirt = ' ';
    if (isUoN(result.physicalShirtsonHand)) result.physicalShirtsonHand = ' ';
    if (isUoN(result.shirtsToSell)) result.shirtsToSell = ' ';
    if (isUoN(result.soldMonthly)) result.soldMonthly = ' ';
    if (isUoN(result.sellThruMonths)) result.sellThruMonths = ' ';
    if (isUoN(result.metersOnOrder)) result.metersOnOrder = ' ';
    if (isUoN(result.shirtsHoldingPhysicalInventory)) result.shirtsHoldingPhysicalInventory = ' ';
    if (isUoN(result.shirtsToCutAtPhysicalInventoryDate)) result.shirtsToCutAtPhysicalInventoryDate = ' ';
    if (isUoN(result.shirtsSoldSincePhysicalInventoryDate)) result.shirtsSoldSincePhysicalInventoryDate = ' ';
    if (isUoN(result.fabricCheckItems)) result.fabricCheckItems = [];

    return result;

  };

  /**
   * 
   * @param {*} getInventoryFunc 
   * @returns 
   */
  show = async (getInventoryFunc) => {

    let item = await getInventoryFunc();
    item = this.loadCurrentObj(item);
    this.model.frmLocal.reset(item);
    return super.show(x => {
      x.fabricCheckItems = item.fabricCheckItems;
      x.fabricCheckTotal = item.fabricCheckTotal;
      x.getInventoryFunc = getInventoryFunc;
    });
  };
}
/**
 *
 * @param {*} props
 * @returns
 */
export const InventoryEdit = (props) => {

  const { controller } = props;
  const { frmLocal, frmUpdate, fabricCheckItems, fabricCheckTotal } = controller.model;
  const { control, getValues, handleSubmit } = frmLocal;
  const { fabricID } = getValues();

  /**
   *
   */
  const onUpdate = async () => {
    controller.submit(getValues(), frmUpdate.getValues());
  };

  return (
    <Dialog
      fullScreen
      style={{ margin: 'auto', maxWidth: 1400, maxHeight: 800 }}
      open={controller.visible()}
      onClose={controller.close}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>
        <DialogHeaderContent
          title='Edit Inventory'
          onClose={controller.close}
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        <FormProvider {...frmLocal}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'end', paddingTop: 10 }}>
              <img src={getImgFromID(fabricID)} width={'50%'} />
            </div>
            <div style={{ flex: 1 }}>
              <TextInput id='name' label='Fabric ID' control={control} disabled={true} />
              <TextArea id='fabricIDDescription' label='Fabric ID Description' control={control} rows={3} />
              <TextInput id='currentAvailabilityStatus' label='Current Availability Status' control={control} disabled={true} />
              <TextInput id='arrival' label='Most Recent Arrival' control={control} disabled={true} />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextInput id='physicalInventoryDate' label='Physical Inventory Date' control={control} disabled={true} />
                <TextInput id='metersonHand' label='Physical Inventory Meters on Hand' control={control} disabled={true} />
                <TextInput id='physicalInventoryDate' label='Updated Date' control={frmUpdate.control} disabled={true} style={{ marginRight: -10 }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%' }}>
                  <IntegerInput id='metersOnHand' label='Please enter a new Meters on Hand value' control={frmUpdate.control} maxLength={6} />
                </div>
                <div style={{ flex: 1, paddingTop: 7, paddingLeft: 20 }}>
                  <Button variant='contained' color='secondary' onClick={onUpdate}>Update</Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button variant='contained' color='secondary' onClick={controller.onClearMetersOnHand}>Clear</Button>
                </div>
              </div>
              <TextInput id='usagePerShirt' label='Usage (m) per shirt; not yds' control={control} disabled={true} />
              <TextInput id='physicalShirtsonHand' label='Physical Inventory Shirts on Hand' control={control} disabled={true} />
              <TextInput id='shirtsHoldingPhysicalInventory' label='PLUS...New Shirts holding Physical Inventory Date' control={control} disabled={true} />
              <TextInput id='shirtsToCutAtPhysicalInventoryDate' label='LESS...New to cut and pending at Physical Inventory Date' control={control} disabled={true} />
              <TextInput id='shirtsSoldSincePhysicalInventoryDate' label='LESS...New Shirts sold since Physical Inventory Date' control={control} disabled={true} />
              <TextInput id='shirtsToSell' label='Shirts to Sell' control={control} disabled={true} />
              <TextInput id='soldMonthly' label='Custom Shirts Sold Monthly' control={control} disabled={true} />
              <TextInput id='sellThruMonths' label='Sell Thru Months' control={control} disabled={true} />
              <TextInput id='metersOnOrder' label='Meters On Order' control={control} disabled={true} />
            </div>
            <div style={{ width: '20%' }}>
            </div>
          </div>
          <br />
          <div className='w-full flex flex-col'>
            <Table stickyHeader size='small'>
              <TableHead>
                <tr>
                  <th colSpan={4}></th>
                  <th colSpan={2} style={{ backgroundColor: '#f1f5f9' }}>
                    New
                  </th>
                  <th colSpan={2} style={{ backgroundColor: '#f1f5f9' }}>
                    Repair
                  </th>
                  <th></th>
                </tr>
                <TableRow>
                  <TableCell component='th' scope='row' align='center'>
                    Last Name
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    First Name
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Retailer
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Salesperson
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    To Cut or Pending Approval
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Holding
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    To Cut or Pending Approval
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Holding
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Production Received
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Production Type
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Production Status
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Updated
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fabricCheckItems && fabricCheckItems.map((item) => {
                  return (
                    <TableRow tabIndex={-1} key={item.itemID}>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='left'
                      >
                        {item.lastName}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='left'
                      >
                        {item.firstName}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='left'
                      >
                        {item.retailerShortName}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='left'
                      >
                        {item.salesperson}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='center'
                      >
                        {item.newOnOrder}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='center'
                      >
                        {item.newHolding}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='center'
                      >
                        {item.repairOnOrder}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='center'
                      >
                        {item.repairHolding}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='left'
                      >
                        {moment(item.receiveDate).format('MM/DD/yyyy')}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='left'
                      >
                        {item.prodTypeText}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='left'
                      >
                        {item.prodStatusText}
                      </TableCell>
                      <TableCell
                        className='border-top cell-border-right'
                        component='td'
                        scope='row'
                        align='left'
                      >
                        {moment(item.lastStatusUpdateDate).format('MM/DD/yyyy')}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <tr>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='left'
                  ></TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='left'
                  ></TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='left'
                  ></TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='right'
                    style={{ backgroundColor: '#ededed' }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                    style={{ backgroundColor: '#ededed' }}
                  >
                    {fabricCheckTotal && fabricCheckTotal.newOnOrder}
                  </TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                    style={{ backgroundColor: '#ededed' }}
                  >
                    {fabricCheckTotal && fabricCheckTotal.newHolding}
                  </TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                    style={{ backgroundColor: '#ededed' }}
                  >
                    {fabricCheckTotal && fabricCheckTotal.repairOnOrder}
                  </TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                    style={{ backgroundColor: '#ededed' }}
                  >
                    {fabricCheckTotal && fabricCheckTotal.repairHolding}
                  </TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='left'
                  ></TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='left'
                  ></TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='left'
                  ></TableCell>
                  <TableCell
                    className='border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='left'
                  ></TableCell>
                </tr>
              </TableBody>
            </Table>
          </div>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={controller.close}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
