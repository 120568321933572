import { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { DialogBaseV1 } from "../dialog/@dialogBaseV1";

export class ErrorService extends DialogBaseV1 {

  /**
   * 
   * @param {*} context 
   */
  constructor(context) {
    super(context, 'errorControlObj');
  }

  /**
   *
   * @returns
   */
  hasError = () => this.model().hasError

  /**
   *
   * @returns
   */
  message = () => this.model().message

  /**
   *
   * @param {*} message
   */
  show = message => {
    const store = { ...this.store };
    // store.error.hasError = true;
    // store.error.message = message;
    // this.context.setStore(store);
    return super.show();
  }
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const ErrorControl = props => {

  const { error } = useContext(DomainContext)
  const { hasError, visible, message, hide } = error

  /**
   * 
   */
  const onClose = () => {
    hide();
  }

  return <>
    {
      visible() &&
      <div className="flex items-center justify-center h-full"
        style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, backgroundColor: '#29292978', zIndex: 9000 }}>
        <div style={{ backgroundColor: '#fff', borderRadius: 8, minWidth: 400, minHeight: 100 }}>
          <div style={{ padding: '20px 20px 10px 20px' }}>
            <Typography className="text-16 md:text-18 font-extrabold tracking-tight" style={{color: '#f00'}}>
              Error
            </Typography>
          </div>
          <hr />
          <div style={{ padding: 20 }}>
            {message()}
          </div>
          <hr />
          <div style={{ textAlign: 'right', padding: 20 }}>
            <Button variant="contained" color="primary" onClick={onClose}>Close</Button>
          </div>
        </div>
      </div>
    }
  </>
}