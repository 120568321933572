import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export const CheckBox = (props) => {
  const { id, label, control } = props;

  return (
    <Controller
      name={id}
      control={control}
      render={({ field }) => (
        <>
          <input
            {...field}
            id={id}
            type="checkbox"
            checked={field.value}
            style={{ width: 20 }}
          />
          &nbsp;
          {label}
        </>
      )}
    />
  );
};
