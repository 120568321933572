import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextInput } from "src/app/@core/forms/controls/textInput";
import { TextArea } from "src/app/@core/forms/controls/textArea";
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";
import { DialogBase, DialogModelBase } from "src/app/@core/forms/dialog/@dialogBaseV2";
import { prodStatusConst } from "src/app/@domain/consts/prodStatus.const";
import { getFullName } from "src/app/@core/common";

export class OrderReverseDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.order = null;
    this.title = '';
    this.notes = '';
    this.contactName = '';
    this.retailerName = '';
    this.status = '';
  }

}

export class OrderReverseDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   */
  submit = async () => {
    this.hide({
      orderID: this.model.order().orderID,
      notes: this.model.notes,
      status: this.model.status
    });
  }

  /**
   * 
   * @returns 
   */
  show = async (prevStatus, order, contact, retailer) => {

    
    const cutTrial = order().isCutTrial;
    let title = '';
    let notes = '';
    let contactName = getFullName(contact());
    let retailerName = retailer().name;
    let status = '';

    if (cutTrial === 'No') {
      
      if (prevStatus === prodStatusConst.NoTrialCut) {
        title = 'Reverse from Complete to Cut';
        notes = 'Cut';
        status = 'Cut';
      } else if (prevStatus === prodStatusConst.NoTrialReceived) {
        title = 'Reverse from Cut to Received';
        notes = 'Received';
        status = 'Received';
      }
        
    } if (cutTrial === 'Yes') {

      if (prevStatus === prodStatusConst.TrialCut) {
        title = 'Reverse from Pending Approval to Cut';
        notes = 'Trial Cut';
        status = 'Trial Cut';
      } else if (prevStatus === prodStatusConst.TrialReceived) {
        title = 'Reverse from Cut to Received';
        notes = 'Trial Received';
        status = 'Trial Received';
      } else if (prevStatus === prodStatusConst.PostTrialCut) {
        title = 'Reverse from Complete to Cut';
        notes = 'Post Trial Cut';
        status = 'Post Trial Cut';
      } else if (prevStatus === prodStatusConst.PostTrialReceived) {
        title = 'Reverse from Cut to Received';
        notes = 'Post Trial Received';
        status = 'Post Trial Received';
      } else if (prevStatus === prodStatusConst.PendingApproval) {
        title = 'Reverse from Received to Pending';
        notes = 'Post Trial Pending';
        status = 'Post Trial Pending';
      }

    }

    return super.show(x => {
      x.order = order;
      x.title = title;
      x.notes = notes;
      x.contactName = contactName;
      x.retailerName = retailerName;
      x.status = status;
    });
  }

}

/**
 *
 * @param {*} props
 * @returns
 */
export const OrderReverseDialog = props => {

  const { controller } = props;

  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 500, maxHeight: 400 }}
      open={controller.visible()}
      onClose={controller.close}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title={controller.model.title} onClose={controller.close} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="flex flex-col" style={{ marginRight: 20 }}>
          <TextInput label="Contact" value={controller.model.contactName} disabled={true} />
          <TextInput label="Retailer" value={controller.model.retailerName} disabled={true} />
          <TextArea label="Please confirm notes" value={controller.model.notes} disabled={true} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={controller.close}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={controller.submit}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
