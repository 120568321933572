import { useState } from 'react';
import Button from '@mui/material/Button';
import { Table, TableBody, TableRow, TableCell, TableHead, } from '@mui/material';
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon';
import { Motion20 } from 'src/app/@core/forms/controls/motion20';
import { NoWrapControl } from 'src/app/@core/forms/controls/noWrapControl';
import { ShirtsAddDialogModel, ShirtsAddDialogController, ShirtsAddDialog, } from './shirts.add.dialog';
import { ShirtsEditDialogModel, ShirtsEditDialogController, ShirtsEditDialog, } from './shirts.edit.dialog';
import { ShirtsDeleteDialogModel, ShirstDeleteDialog, ShirtsDeleteDialogController, } from './shirts.delete.dialog';
import { InventoryEdit, InventoryEditDialogModel, InventoryEditDialogController } from 'src/app/@domain/modules/inventory/inventory.edit';
import { toLocalImg } from 'src/app/@core/common';

export const ShirtsControl = props => {

  const { controller } = props;

  const [shirtsAddDialogModel, setShirtsAddDialogModel] = useState(new ShirtsAddDialogModel());
  const shirtsAddDialogController = new ShirtsAddDialogController(shirtsAddDialogModel, setShirtsAddDialogModel);

  const [shirtsEditDialogModel, setShirtsEditDialogModel] = useState(new ShirtsEditDialogModel());
  const shirtsEditDialogController = new ShirtsEditDialogController(shirtsEditDialogModel, setShirtsEditDialogModel);

  const [shirtsDeleteDialogModel, setShirtsDeleteDialogModel] = useState(new ShirtsDeleteDialogModel());
  const shirtsDeleteDialogController = new ShirtsDeleteDialogController(shirtsDeleteDialogModel, setShirtsDeleteDialogModel);

  const [inventoryEditDialogModel, setInventoryEditDialogModel] = useState(new InventoryEditDialogModel());
  const inventoryEditDialogController = new InventoryEditDialogController(inventoryEditDialogModel, setInventoryEditDialogModel);

  const summary = {
    newOrder: 0,
    newHolding: 0,
    repairOrder: 0,
    repairHolding: 0
  };
  const items = controller.shirts();
  summary.newOrder = items.reduce((a, b) => a + b.newOnOrder, 0);
  summary.newHolding = items.reduce((a, b) => a + b.newHolding, 0);
  summary.repairOrder = items.reduce((a, b) => a + b.repairOnOrder, 0);
  summary.repairHolding = items.reduce((a, b) => a + b.repairHolding, 0);

  /**
   * 
   */
  const loadShirts = async () => {
    try {
      controller.spinner().show();
      const shirts = await controller.api().orderApi.getShirts(controller.order().orderID);
      controller.updateModel(x => x.shirts = shirts);
    } catch (err) { console.log(err); }
    finally { controller.spinner().hide(); }
  }

  /**
   *
   */
  const onAdd = async () => {
    const result = await shirtsAddDialogController.show(controller);
    if (!result) return;
    await loadShirts();
  };

  /**
   * 
   * @param {*} shirt 
   */
  const onEdit = async shirt => {
    const result = await shirtsEditDialogController.show(controller, shirt);
    if (!result) return;
    await loadShirts();
  };

  /**
   * 
   * @param {*} shirt 
   */
  const onEditInventory = async shirt => {

    const currentDate = new Date();
    const dto = {
      fabricID: shirt.fabricID,
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate()),
      endDate: currentDate,
    };

    try {

      controller.spinner().show();
      const result = await controller.api().inventoryApi.getInventoryEdit(dto)
      inventoryEditDialogController.show(result);

    } catch (err) { console.log(err); }
    finally { controller.spinner().hide(); }

  };

  /**
   * 
   * @param {*} shirt 
   */
  const onDelete = async shirt => {
    const result = await shirtsDeleteDialogController.show(shirt);
    if (!result) return;
    await loadShirts();
  };

  return (
    <>
      <ShirtsAddDialog controller={shirtsAddDialogController} />
      <ShirtsEditDialog controller={shirtsEditDialogController} />
      <ShirstDeleteDialog controller={shirtsDeleteDialogController} />
      <InventoryEdit controller={inventoryEditDialogController} />

      <div className='w-full flex flex-col'>
        <div className='w-full flex flex-row'>
          <div
            className='flex flex-col justify-end'
            style={{ fontSize: '1.2em', fontWeight: 600 }}
          >
            Shirts
          </div>
          <div style={{ flex: 1, textAlign: 'right', paddingBottom: 5 }}>
            <Motion20>
              <Button variant='contained' color='secondary' onClick={onAdd} startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}>
                Add Shirt
              </Button>
            </Motion20>
          </div>
        </div>
        <Table stickyHeader size='small'>
          <TableHead>
            <tr>
              <td />
              <td />
              <td />
              <TableCell colSpan={2} component='th' scope='row' align='center'>
                New
              </TableCell>
              <TableCell colSpan={2} component='th' scope='row' align='center'>
                Repairt
              </TableCell>
              <td />
              <td />
            </tr>
            <TableRow>
              <TableCell component='th' scope='row' align='center' width={50} />
              <TableCell component='th' scope='row' align='center'>
                Fabric
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                Fabric Image
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                New Ordered
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                New Holding
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                Repairt Ordered
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                Repair Holding
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                Production Type
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                Availability
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controller.shirts().map(item => (
              <TableRow key={item.itemID}>
                <TableCell className='border-left cell-border-top cell-border-right' component='td' scope='row' align='center'>
                  <NoWrapControl>
                    <FuseSvgIcon className='text-48 cursor-pointer' size={20} color='action' onClick={() => onEdit(item)}>
                      material-solid:edit
                    </FuseSvgIcon>
                    <FuseSvgIcon
                      className='text-48 cursor-pointer' size={20} color='action' onClick={() => onDelete(item)}>
                      material-solid:delete
                    </FuseSvgIcon>
                  </NoWrapControl>
                </TableCell>
                <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                  <div style={{ fontSize: '.85em' }}>
                    <NoWrapControl>
                      <div className='cursor-pointer' style={{ textDecoration: 'underline' }} onClick={() => onEditInventory(item)}>
                        {item.fabricID}
                      </div>
                    </NoWrapControl>
                    {item.lineItemType}
                    <br />
                    <p dangerouslySetInnerHTML={{ __html: item.fabricInfo }}></p>
                  </div>
                </TableCell>
                <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='center'>
                  <img src={toLocalImg(item.fabricSwatch)} width={300} />
                </TableCell>
                <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='center'>
                  {item.newOnOrder}
                </TableCell>
                <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='center'>
                  {item.newHolding}
                </TableCell>
                <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='center'>
                  {item.repairOnOrder}
                </TableCell>
                <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='center'>
                  {item.repairHolding}
                </TableCell>
                <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='center'>
                  {item.prodType}
                </TableCell>
                <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='center'>
                  {item.availability}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
            <tr>
              <td />
              <td />
              <td />
              <TableCell component='th' scope='row' align='center'>
                {summary.newOrder}
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                {summary.newHolding}
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                {summary.repairOrder}
              </TableCell>
              <TableCell component='th' scope='row' align='center'>
                {summary.repairHolding}
              </TableCell>
              <td />
              <td />
            </tr>
          </TableHead>
        </Table>
      </div>

    </>
  );
};
