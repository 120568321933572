import { SelectInput } from "src/app/@core/forms/controls/selectInput";
import { TextInput } from "src/app/@core/forms/controls/textInput";
import { comboLists } from "src/app/@domain/consts/comboLists";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderAddStep02 = props => {

  const { localModel, setLocalModel } = props;
  const { frmCommon, frmFabric } = localModel;

  /**
   * 
   * @param {*} e 
   */
  const onShirtTypeChange = e => {
    setLocalModel({ ...localModel, ...{ shirtType: e.target } });
  }

  return <div style={{ margin: "20px 10px", padding: "20px 40px", border: "1px solid #cbcbcb", borderRadius: 10 }}>
    <div style={{ marginLeft: -10, marginRight: 10 }}>
      <SelectInput label="Shirt Types" defaultValue={localModel.shirtType.value} items={comboLists.shirtTypes} onChange={onShirtTypeChange} />
    </div>
    <div style={{ fontWeight: 600 }}>
      Please enter FabricIDs and Quatities
    </div>
    <div style={{ marginLeft: -10, marginTop: 5 }}>
      <div className="flex flex-row">
        <TextInput id="fabrics[0].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[0].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[1].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[1].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
      <div className="flex flex-row">
        <TextInput id="fabrics[2].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[2].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[3].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[3].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
      <div className="flex flex-row">
        <TextInput id="fabrics[4].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[4].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[5].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[5].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
      <div className="flex flex-row">
        <TextInput id="fabrics[6].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[6].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[7].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[7].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
      <div className="flex flex-row">
        <TextInput id="fabrics[8].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[8].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[9].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[9].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
      <div className="flex flex-row">
        <TextInput id="fabrics[10].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[10].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[11].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[11].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
      <div className="flex flex-row">
        <TextInput id="fabrics[12].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[12].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[13].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[13].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
      <div className="flex flex-row">
        <TextInput id="fabrics[14].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[14].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[15].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[15].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
      <div className="flex flex-row">
        <TextInput id="fabrics[16].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[16].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[17].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[17].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
      <div className="flex flex-row">
        <TextInput id="fabrics[18].quantity" label="Qty" control={frmFabric.control} style={{ width: 200 }} />
        <TextInput id="fabrics[18].fabricID" label="Fabric ID" control={frmFabric.control} />
        <TextInput id="fabrics[19].quantity" label="Qty" control={frmFabric.control} style={{ width: 200, marginLeft: 50 }} />
        <TextInput id="fabrics[19].fabricID" label="Fabric ID" control={frmFabric.control} />
      </div>
    </div>
  </div>
}