import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { contactModel } from "src/app/@domain/models/contact.model";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import { TextInput } from 'src/app/@core/forms/controls/textInput';
import { dropDownModel } from 'src/app/@domain/models/dropdown.model';
import { HtmlTooltip } from 'src/app/@core/forms/controls/htmlTooltip';

/**
 * 
 */
export class RetailerFilterDialogModel extends DialogModelBase {

  /**
   *
   */
  constructor() {
    super();
    this.retailers = [];
    this.selectedRetailer = dropDownModel.empty;
  }

}

/**
 * 
 */
export class RetailerFilterDialogController extends DialogBase {

  /**
   *
   * @param {*} model
   * @param {*} setModel
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  retailers = () => this.model.retailers;
  currentRetailer = () => this.model.selectedRetailer;
  onChange = item => this.model.onChange(item);

  /**
   * 
   * @param {*} retailers 
   * @returns 
   */
  show = async (retailers, selectedRetailer) => super.show(x => {
    x.retailers = retailers;
    x.selectedRetailer = selectedRetailer;
  });

  /**
   * 
   * @param {*} retailer 
   */
  submit = async retailer => {
    this.hide(retailer)
  }

}

/**
 * 
 */
export const RetailerFilterDialog = props => {

  const { controller } = props;
  const { retailers, currentRetailer } = controller;

  const [selectedRetailer, setSelectedRetailer] = useState(currentRetailer());

  /**
   * 
   * @returns 
   */
  const onClose = () => controller.hide(null);

  /**
   * 
   * @returns 
   */
  const onSubmit = () => controller.submit(selectedRetailer);

  /**
   * 
   * @returns 
   */
  const onClearFilter = () => controller.hide(dropDownModel.empty);

  /**
   * 
   * @param {*} item 
   * @returns 
   */
  const onRetailerChange = item => {
    if (!item) return;
    const retailer = retailers().find(x => x.id == item.id);
    setSelectedRetailer(retailer);
  }

  return (
    <Dialog fullScreen style={{ margin: 'auto', maxWidth: 600, maxHeight: 250, zIndex: 10000 }}
      open={controller.visible()}
      onClose={onClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title" style={{ padding: 0 }}>
        <div style={{ padding: '16px 24px' }}>
          <DialogHeaderContent title="Retailer Filter" onClose={onClose} />
        </div>
      </DialogTitle>
      <DialogContent dividers={true} style={{ padding: 20 }}>
        <div className="flex -mx-4">
          <TextInput id="lastName" label="Column" value="Retailer" disabled style={{ width: '30%' }} />
          <Autocomplete
            style={{ flex: 1, marginRight: 20 }}
            disablePortal
            options={retailers()}
            value={currentRetailer()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            ListboxProps={{ style: { maxHeight: 100 } }}
            onChange={(e, data) => onRetailerChange(data)}
            getOptionLabel={(x) => x.name}
            renderOption={(props, option) => <li {...props} key={option.itemID}>{option.name}</li>}
            renderInput={params => <TextField {...params} className="mt-8 mb-2 mx-8" label='Please select a Retailer' size='small' />}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex" style={{ margin: 10 }}>
          <Button variant="contained" color="primary" onClick={onClose}>Cancel</Button>
          &nbsp;&nbsp;
          <Button variant="contained" color="secondary" onClick={onSubmit}>Select</Button>
          &nbsp;&nbsp;
          <Button variant="contained" color="secondary" onClick={onClearFilter}>Clear Filter</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const RetailerFilter = props => {

  const { retailers, selectedRetailer, onChange } = props;

  const [model, setModel] = useState(new RetailerFilterDialogModel());
  const controller = new RetailerFilterDialogController(model, setModel);

  /**
   * 
   */
  const RetailerFilterClick = async () => {
    const retailer = await controller.show(retailers, selectedRetailer);
    if (!retailer) return;
    onChange(retailer)
  }

  /**
   * 
   */
  const RetailerFilterCancelClick = () => {
    onChange(dropDownModel.empty);
  }

  return <div>
    <RetailerFilterDialog controller={controller} />
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
      {selectedRetailer.id === 0 && <FilterAltOutlinedIcon fontSize="small" onClick={RetailerFilterClick} />}
      {selectedRetailer.id > 0 && <HtmlTooltip title={selectedRetailer.name}><FilterAltIcon fontSize="small" onClick={RetailerFilterClick} /></HtmlTooltip>}
      {selectedRetailer.id > 0 && <FilterAltOffIcon fontSize="small" onClick={RetailerFilterCancelClick} />}
    </div>    </div>
}

