import { motion } from 'framer-motion';
import Typography from '@mui/material/Typography';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const NoDataFound = props => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.1 } }} className="flex flex-1 items-center justify-center h-full">
      <Typography color="text.secondary" variant="h5">No data found</Typography>
    </motion.div>
  );
}
