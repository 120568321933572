import { itemTypeConst } from "../consts/item-type.consts";

export class DropdownItem {

  /**
   * 
   * @param {*} items 
   */
  constructor(items) {
    this.items = items;
  }

  currentAvailabilityStatuses = () => this.items.currentAvailabilityStatuses;
  collections = () => this.items.collection;  
  productionStatuses = () => this.items.productionStatuses;
  shirtTypes = () => this.items.shirtTypes;
  prodTypes = () => this.items.prodTypes;

}