import { useContext, useState } from 'react';
import _ from '@lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon';
import { isUoN, toLocalImg, toMMDDYYYY } from 'src/app/@core/common';
import { NoDataFound } from 'src/app/@core/forms/controls/noDataFound';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { ModuleContext } from 'src/app/@core/@contexts/module.context';
import InventoryTableHead from './inventory.table-head';
import { InventoryEdit, InventoryEditDialogModel, InventoryEditDialogController } from './inventory.edit';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const InventoryTable = props => {

  const { gvItems } = props;

  const { api, spinner } = useContext(DomainContext);
  const { frmModel, page, rowsPerPage, searchCriteria } = useContext(ModuleContext);
  const { reset } = frmModel();
  const [order, setOrder] = useState({ direction: 'asc', id: null });

  const [inventoryEditDialogModel, setInventoryEditDialogModel] = useState(new InventoryEditDialogModel());
  const inventoryEditDialogController = new InventoryEditDialogController(inventoryEditDialogModel, setInventoryEditDialogModel);

  /**
   *
   * @param {*} event
   * @param {*} property
   */
  function onSort(event, property) {
    const id = property;
    let direction = 'desc';
    if (order.id === property && order.direction === 'desc') direction = 'asc';
    setOrder({ direction, id });
  }

  /**
   *
   * @param {*} fabricID
   */
  const onEdit = async (item) => {

    searchCriteria.fabricID = item.fabricID;
    const dto = {
      fabricID: item.fabricID,
      startDate: searchCriteria.startDate,
      endDate: searchCriteria.endDate,
    };

    // const result = await getInventory(dto);
    inventoryEditDialogController.show(async () => await getInventory(dto));

  };

  /**
   * 
   * @param {*} dto 
   * @returns 
   */
  const getInventory = async dto => {

    let result = [];
    try {

      spinner.show();
      result = await api.inventoryApi.getInventoryEdit(dto)

    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

    return result;

  }

  if (isUoN(gvItems) || gvItems.length === 0) return <NoDataFound />;

  return (
    <div className='w-full flex flex-col min-h-full'>
      <InventoryEdit controller={inventoryEditDialogController} />

      <Table
        stickyHeader
        className='min-w-xl'
        size='small'
        aria-labelledby='tableTitle'
      >
        <InventoryTableHead
          order={order}
          onRequestSort={onSort}
          rowCount={gvItems.length}
        />

        <TableBody>
          {_.orderBy(
            gvItems,
            [
              (o) => {
                switch (order.id) {
                  case 'categories': {
                    return o.categories[0];
                  }
                  default: {
                    return o[order.id];
                  }
                }
              },
            ],
            [order.direction]
          )
            .slice(
              page() * rowsPerPage(),
              page() * rowsPerPage() + rowsPerPage()
            )
            .map((item) => {
              return (
                <TableRow hover tabIndex={-1} key={item.itemID}>
                  <TableCell
                    className='p-4 md:p-5 border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                  >
                    <FuseSvgIcon
                      className='text-48 cursor-pointer'
                      size={20}
                      color='action'
                      onClick={() => onEdit(item)}
                    >
                      material-solid:edit
                    </FuseSvgIcon>
                  </TableCell>
                  <TableCell
                    className='p-4 md:p-5 border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='left'
                  >
                    {item.fabricID}
                  </TableCell>
                  <TableCell
                    className='p-4 md:p-5 border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                  >
                    <img
                      src={toLocalImg(item.fabricImagePath)}
                      style={{ height: '100px' }}
                    />
                  </TableCell>
                  <TableCell
                    className='p-4 md:p-5 border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='left'
                  >
                    {item.fabricIDDescription}
                  </TableCell>
                  <TableCell
                    className='p-4 md:p-5 border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                  >
                    {item.currentAvailabilityStatus}
                  </TableCell>
                  <TableCell
                    className='p-4 md:p-5 border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                  >
                    {toMMDDYYYY(item.arrival)}
                  </TableCell>
                  <TableCell
                    className='p-4 md:p-5 border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                  >
                    {item.shirtsToSell}
                  </TableCell>
                  <TableCell
                    className='p-4 md:p-5 border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                  >
                    {item.soldMonthly}
                  </TableCell>
                  <TableCell
                    className='p-4 md:p-5 border-top cell-border-right'
                    component='td'
                    scope='row'
                    align='center'
                  >
                    {item.sellThruMonths}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};
