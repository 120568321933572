import { memo } from 'react';
import Box from '@mui/material/Box';

function FuseSplashScreen() {
  return (
    <div id="fuse-splash-screen">
      <div>
        {/* IMPL: Use Hamilton logo in splash screen */}
        <object data="img/logo.svg" height="100"> </object>
      </div>
    </div>
  );
}

export default memo(FuseSplashScreen);
