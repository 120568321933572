import { motion } from 'framer-motion';
import Typography from '@mui/material/Typography';
import { TableBody, TableCell, TableRow } from "@mui/material";
import { TBodyCell } from './dataTable.control';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const EmptyRowTable = props => {

  const { colSpan } = props;

  return <TableRow>
    <TableCell className="p-4 md:p-5 tbody-cell" colSpan={colSpan} style={{ height: 30 }} component="td" scope="row">
    </TableCell>
  </TableRow>
}

