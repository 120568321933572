import { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import { Table, TableBody, TableRow, TableCell, TableHead, } from "@mui/material";
import TableSortLabel from '@mui/material/TableSortLabel';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { DialogBaseV1 } from "src/app/@core/forms/dialog/@dialogBaseV1";
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { isUoN, isUNE, stableSort } from 'src/app/@core/common';
import { NoDataFoundTable } from "src/app/@core/forms/dataTable/noDataFound.table";

export class ShirtUserPickerDialogController extends DialogBaseV1 {

  /**
   * 
   * @param {*} context 
   */
  constructor(context) {
    super(context, 'shirtUserPickerDialogDataObj');
    this.setSearchText = null;
  }

  items = () => this.model().items;
  gvItems = () => this.model().gvItems;

  /**
   * 
   * @param {*} title 
   * @param {*} message 
   * @returns 
   */
  show = async () => {

    try {

      this.spinner().show();

      const store = { ...this.store() };
      const contacts = await this.context.api.contactApi.getShirtUsers();
      store.shirtUserPickerDialogDataObj.items = contacts;
      store.shirtUserPickerDialogDataObj.gvItems = contacts;
      this.context.setStore(store);

      this.setSearchText('');

      return super.show();

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  };

};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const ShirtUserPickerDialog = props => {

  const { shirtUserPickerDialogController: controller } = useContext(DomainContext)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [sortOrder, setSortOrder] = useState({ direction: 'asc', id: null });
  const [searchText, setSearchText] = useState('');
  controller.setSearchText = setSearchText;

  /**
   *
   */
  useEffect(() => {

    const store = { ...controller.store() };
    const items = controller.items();
    const text = searchText.toLowerCase();
    if (text.length > 0) {

      const result = items.filter(item => {

        if (isUoN(item)) return false;

        let fn = false;
        let ln = false;
        let st = false;

        if (!isUoN(item.firstName)) fn = item.firstName.toLowerCase().includes(text);
        if (!isUoN(item.lastName)) ln = item.lastName.toLowerCase().includes(text);
        // if (!isUoN(item.store)) st = item.store.toLowerCase().includes(text);

        return fn | ln;

      });

      store.shirtUserPickerDialogDataObj.gvItems = result;
      setPage(0);

    } else {
      store.shirtUserPickerDialogDataObj.gvItems = items;
    }

    controller.context.setStore(store);

  }, [searchText]);

  /**
   * 
   */
  const onClose = () => {
    controller.hide();
  }

  /**
   * 
   * @param {*} text 
   */
  const onSearchText = text => {
    setSearchText(text);
  }

  /**
   * 
   * @param {*} item 
   */
  const onRowClick = item => {
    controller.hide(item);
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const Header = props => {

    const columns = [
      {
        id: 'title',
        align: 'center',
        disablePadding: false,
        label: 'Title',
        sort: true,
        width: 0,
      },
      {
        id: 'firstName',
        align: 'center',
        disablePadding: false,
        label: 'First Name',
        sort: true,
        width: 0,
      },
      {
        id: 'lastName',
        align: 'center',
        disablePadding: false,
        label: 'LastName',
        sort: true,
        width: 0,
      },
      {
        id: 'middleName',
        align: 'center',
        disablePadding: false,
        label: 'Middle Name',
        sort: true,
        width: 0,
      },
      {
        id: 'store',
        align: 'center',
        disablePadding: false,
        label: 'Store',
        sort: true,
        width: 0,
      },
      {
        id: 'email',
        align: 'center',
        disablePadding: false,
        label: 'Email',
        sort: true,
        width: 0,
      },
      {
        id: 'businessPhone',
        align: 'center',
        disablePadding: false,
        label: 'Business Phone',
        sort: true,
        width: 0,
      },
      {
        id: 'street',
        align: 'center',
        disablePadding: false,
        label: 'Street1',
        sort: true,
        width: 0,
      },
      {
        id: 'city',
        align: 'center',
        disablePadding: false,
        label: 'City',
        sort: true,
        width: 0,
      },
      {
        id: 'state',
        align: 'center',
        disablePadding: false,
        label: 'State',
        sort: true,
        width: 0,
      },
      {
        id: 'zip',
        align: 'center',
        disablePadding: false,
        label: 'Zip',
        sort: true,
        width: 0,
      },
    ];

    /**
     * 
     * @param {*} property 
     * @returns 
     */
    const createSortHandler = (property) => (event) => {
      onSort(event, property);
    };

    /**
     *
     * @param {*} event
     * @param {*} property
     */
    const onSort = (event, property) => {
      const id = property;
      let direction = "desc";
      if (sortOrder.id === property && sortOrder.direction === "desc") direction = "asc";
      setSortOrder({ direction, id });
    }

    return (
      <TableHead>
        <TableRow className="h-48 sm:h-64">
          {
            columns.map(column => {
              return (
                <TableCell
                  width={column.width}
                  className="p-4 md:p-5"
                  key={column.id}
                  align="center"
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={sortOrder.id === column.id ? sortOrder.direction : false}>
                  {
                    column.sort && (
                      <Tooltip
                        title="Sort"
                        placement={column.align === 'right' ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}>
                        <TableSortLabel
                          active={sortOrder.id === column.id}
                          direction={sortOrder.direction}
                          onClick={createSortHandler(column.id)}
                          className="font-semibold">
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    )
                  }
                </TableCell>
              );
            }, this)
          }
        </TableRow>
      </TableHead>

    );
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const Body = props => {

    if (isUoN(controller)) return <NoDataFoundTable colSpan={11} />
    if (isUoN(controller.gvItems)) return <NoDataFoundTable colSpan={11} />

    return <TableBody>
      {
        stableSort(controller.gvItems(), sortOrder.id, sortOrder.direction)
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(item => {
            return (
              <TableRow hover tabIndex={-1} key={item.itemID}
                style={{ cursor: 'pointer' }}
                onClick={() => onRowClick(item)}>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="left" width={100}>
                  {item.title}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="left">
                  {item.firstName}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="left" width={200}>
                  {item.lastName}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="left" width={200}>
                  {item.middleName}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="left" width={200}>
                  {item.store}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="left" width={200}>
                  {item.email}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="center" width={200}>
                  {item.businessPhone}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="left" width={200}>
                  {item.Street1}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="left" width={200}>
                  {item.city}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="center" width={200}>
                  {item.state}
                </TableCell>
                <TableCell className="cell-border-top cell-border-right" component="td" scope="row" align="center" width={200}>
                  {item.zip}
                </TableCell>
              </TableRow>
            )
          })
      }
    </TableBody>

  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const TableNav = props => {

    /**
     * 
     * @param {*} event 
     * @param {*} value 
     */
    const onChangePage = (event, value) => setPage(value);


    /**
     * 
     * @param {*} event 
     */
    const onChangeRowsPerPage = event => setRowsPerPage(event.target.value);

    return <div style={{ width: '100%' }}>
      <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
        <div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
          <Paper className="flex items-center w-full sm:max-w-400 space-x-8 px-16 rounded-full border-1 shadow-0">
            <FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>
            <Input
              placeholder="First Name, Last Name, or Store"
              className="flex flex-1"
              disableUnderline
              fullWidth
              autoFocus={true}
              value={searchText}
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={e => onSearchText(e.target.value)}
            />
          </Paper>
        </div>
        <div style={{ width: 300 }}>
          <TablePagination
            className="shrink-0"
            component="div"
            count={isUNE(controller.gvItems()) ? 0 : controller.gvItems().length}
            labelRowsPerPage=''
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </div>
      </div>
    </div>

  }

  return (
    <Dialog fullScreen style={{ margin: 'auto', maxWidth: 1400, maxHeight: 800, zIndex: 10000 }}
      open={controller.visible()}
      onClose={onClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title" style={{ padding: 0 }}>
        <div style={{ padding: '16px 24px' }}>
          <DialogHeaderContent title="Please select a Shirt User" onClose={onClose} />
        </div>
        <hr />
        <TableNav />
      </DialogTitle>
      <DialogContent dividers={true} style={{ padding: 0 }}>
        <Table stickyHeader className="min-w-xl" size="small">
          <Header />
          <Body />
        </Table>
      </DialogContent>
      <DialogActions>
        <div className="flex" style={{ margin: 10 }}>
          <Button variant="contained" color="primary" onClick={onClose}>Close</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

