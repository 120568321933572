import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FusePageCarded from 'src/app/@core/@fuse/FusePageCarded.FullWidth';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { ModuleContext, initialModuleData, moduleDataContext } from 'src/app/@core/@contexts/module.context';
import InventoryHeader from './inventory.header';
import { InventoryTable } from './inventory.table';

export const InventoryPage = () => {

  const frmModel = useForm({ mode: 'onChange', defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);

  const { spinner } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [searchCriteria, setSearchCriteria] = useState({});

  /**
   * 
   */
  const loadData = () => {}

  /**
   * 
   */
  const contextObj = useMemo(() => {

    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.setGvItems = setGvItems;
    moduleDataContext.loadModelDataFunc = loadData;
    moduleDataContext.searchCriteria = searchCriteria;
    moduleDataContext.setSearchCriteria = setSearchCriteria;
    moduleDataContext.spinner = spinner;

    return moduleDataContext

  }, [store]);


  /**
   * 
   */
  useEffect(() => {
    loadData();
    return () => { };
  }, [])

  /**
   * 
   */
  return <ModuleContext.Provider value={contextObj}>
    <FusePageCarded
      header={<InventoryHeader gvItems={gvItems} />}
      content={<InventoryTable gvItems={gvItems} />}
      scroll={isMobile ? 'normal' : 'normal'
      }
    />
  </ModuleContext.Provider>

}

