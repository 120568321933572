import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export const SelectInputEmpty = props => {

  const { id, label } = props;

  return <FormControl className="mt-8 mb-2 mx-8" fullWidth >
      <InputLabel id={id} style={{ marginTop: -5 }}>{label}</InputLabel>
      <Select id={id} labelId={label} label={label} defaultValue="" size="small" />
    </FormControl>  

}

