import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { motion } from "framer-motion";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import FusePageCarded from "src/app/@core/@fuse/FusePageCarded.FullWidth";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { ModuleContext, initialModuleData, moduleDataContext } from "src/app/@core/@contexts/module.context";
import { NoDataFound } from "src/app/@core/forms/controls/noDataFound";
import { TBodyCell, TBodyCellEdit, THead } from "src/app/@core/forms/dataTable/dataTable.control";
import { isUNE, isUoN, toBool, stableSort } from "src/app/@core/common";
import { RetailerAdd } from "./retailer.add";
import { RetailerEdit } from "./retailer.edit";
import { retailerModel } from "src/app/@domain/models/retailer.model";

export const RetailersPage = () => {

  const frmModel = useForm({
    mode: "onChange",
    defaultValues: {
      retailerName: "",
      retailerShortName: "",
      retailerCity: "",
      retailerState: "",
      isActive: true,
    },
  });

  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentObj, setCurrentObj] = useState(null);

  const [sortOrder, setSortOrder] = useState({ direction: 'asc', id: null });
  const onSort = prop => {
    const direction = (sortOrder.id === prop && sortOrder.direction === 'desc') ? 'asc' : 'desc';
    setSortOrder({ direction, id: prop });
  }

  /**
   *
   */
  const loadData = async () => {

    try {

      spinner.show();

      const result = await api.retailerApi.getAllSP();
      if (isUoN(result)) return;

      const sorted = stableSort(result.filter(x => x.isActive), 'retailerName', 'asc');
      setGvItems(sorted);
      moduleDataContext.moduleGvData(sorted);

    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  };

  /**
   *
   */
  const contextObj = useMemo(() => {
    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;
    return moduleDataContext;
  }, [store]);

  const { api, spinner } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  /**
   *
   */
  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  /**
   *
   * @param {*} props
   * @returns
   */
  const RetailersHeader = (props) => {

    const { gvItems } = props;
    const { frmModel, page, rowsPerPage } = useContext(ModuleContext);

    const { reset } = frmModel();

    /**
     *
     * @param {*} event
     * @param {*} value
     */
    const onChangePage = (event, value) => {
      page(value);
    };

    /**
     *
     * @param {*} event
     */
    const onChangeRowsPerPage = (event) => {
      rowsPerPage(event.target.value);
    };

    /**
     *
     */
    const onAdd = () => {
      reset(retailerModel.createNew());
      setOpenAdd(true);
    };

    return (
      <div style={{ width: "100%" }}>
        <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between container">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-24 md:text-32 font-extrabold tracking-tight"
          >
            Retailers
          </Typography>
        </div>
        <hr />
        <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={onAdd}
              startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
            >
              Create
            </Button>
          </motion.div>
          <TablePagination
            className="shrink-0"
            component="div"
            count={isUNE(gvItems) ? 0 : gvItems.length}
            rowsPerPage={rowsPerPage()}
            page={page()}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </div>
      </div>
    );
  };

  const RetailersTable = (props) => {

    const { gvItems, setGvItems } = props;

    const { api, spinner } = useContext(DomainContext);
    const { frmModel, moduleGvData, page, searchText, rowsPerPage } = useContext(ModuleContext);
    const { reset } = frmModel();

    const [order, setOrder] = useState({ direction: "asc", id: null });

    /**
     *
     */
    useEffect(() => {

      if (searchText().length !== 0) {

        const result = moduleGvData().filter((item) => {
          if (isUoN(item)) return false;

          let projectCd = false;
          let projectStatus = false;

          if (!isUoN(item.projectCd))
            projectCd = item.projectCd
              .toLowerCase()
              .includes(searchText().toLowerCase());
          if (!isUoN(item.projectStatus))
            projectStatus = item.projectStatus
              .toLowerCase()
              .startsWith(searchText().toLowerCase());

          return projectCd | projectStatus;
        });

        setGvItems(result);
        page(0);

      } else {
        setGvItems(moduleGvData());
      }

    }, [searchText()]);

    /**
     *
     * @param {*} id
     */
    const onEdit = async (id) => {
      spinner.show();
      try {
        const obj = await api.retailerApi.get(id);
        const currentObj = loadCurrentObj(obj);
        reset(currentObj);
        setOpenEdit(true);
      } catch (err) { console.log(err); }
      spinner.hide();
    };


    /**
     *
     * @param {*} obj
     * @returns
     */
    const loadCurrentObj = (obj) => {
      const result = { ...obj };
      setCurrentObj(result);
      return result;
    };

    if (isUoN(gvItems) || gvItems.length === 0) return <NoDataFound />;

    const columns = [
      {
        id: 'actions',
        align: 'center',
        disablePadding: false,
        sort: false,
        width: 30,
      },
      {
        id: 'retailerName',
        align: 'left',
        disablePadding: false,
        label: 'Retailer Name',
        sort: true,
        width: 500,
      },
      {
        id: 'retailerShortName',
        align: 'left',
        disablePadding: false,
        label: 'Retailer Short Name',
        sort: true,
        width: 0,
      },
      {
        id: 'isActive',
        align: 'left',
        disablePadding: false,
        label: 'Active',
        sort: true,
        width: 100,
      },
    ];

    return (
      <div className="w-full flex flex-col min-h-full">
        <Table stickyHeader className="min-w-xl" size="small" aria-labelledby="tableTitle"        >
          <THead columns={columns} sortOrder={sortOrder} onSort={onSort} />
          <TableBody>
            {
              stableSort(gvItems, sortOrder.id, sortOrder.direction)
                .slice(page() * rowsPerPage(), page() * rowsPerPage() + rowsPerPage())
                .map((item) => {
                  return <TableRow hover tabIndex={-1} key={item.itemID}>
                    <TBodyCellEdit item={item} onEdit={() => onEdit(item.retailerID)} />
                    <TBodyCell align="left" value={item.retailerName} />
                    <TBodyCell align="left" value={item.retailerShortName} />
                    <TBodyCell align="center" value={toBool(item.isActive)} />
                  </TableRow>
                })
            }
          </TableBody>
        </Table>

      </div>
    );
  };

  /**
   *
   */
  return (
    <ModuleContext.Provider value={contextObj}>
      <RetailerAdd open={openAdd} setOpen={setOpenAdd} />
      <RetailerEdit open={openEdit} setOpen={setOpenEdit} currentObj={currentObj} />
      <FusePageCarded
        header={<RetailersHeader gvItems={gvItems} />}
        content={<RetailersTable gvItems={gvItems} setGvItems={setGvItems} />}
        scroll={isMobile ? "normal" : "normal"}
      />
    </ModuleContext.Provider>
  );
};
