const { GUID } = require("src/app/@core/common");

export const comboLists = {

  /**
   * 
   */
  prodTypes: [
    { itemID: GUID.newID(), id: 'Trial', name: 'Trial' },
    { itemID: GUID.newID(), id: 'Post-Trial', name: 'Post-Trial' },
    { itemID: GUID.newID(), id: 'No-Trial', name: 'No-Trial' },
  ],

  /**
   * 
   */
  shirtTypes: [
    { itemID: GUID.newID(), id: 'Custom', name: 'Custom' },
    { itemID: GUID.newID(), id: 'Ready to Wear', name: 'Ready to Wear' },
    { itemID: GUID.newID(), id: 'Repair', name: 'Repair' },
  ],

  /**
   * 
   */
  availablility: [
    { itemID: GUID.newID(), id: 'All', name: 'All' },
    { itemID: GUID.newID(), id: 'Available', name: 'Available' },
  ],

  /**
   * 
   */
  collection: [
    { itemID: GUID.newID(), id: 'All Collections', name: 'All Collections' },
    { itemID: GUID.newID(), id: 'Business', name: 'Business' },
    { itemID: GUID.newID(), id: 'Fall 2008', name: 'Fall 2008' },
    { itemID: GUID.newID(), id: 'Promo', name: 'Promo' },
    { itemID: GUID.newID(), id: 'Ready to Wear', name: 'Ready to Wear' },
    { itemID: GUID.newID(), id: 'Tuxedo', name: 'Tuxedo' },
  ]

}