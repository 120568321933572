import { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormProvider } from "react-hook-form";
import { TextInput } from "src/app/@core/forms/controls/textInput";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { ModuleContext } from "src/app/@core/@contexts/module.context";
import { CheckBox } from "src/app/@core/forms/controls/checkbox";
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";

/**
 *
 * @param {*} props
 * @returns
 */
export const RetailerAdd = (props) => {
  const { open, setOpen } = props;
  const { api, dropdown, spinner } = useContext(DomainContext);
  const { comments, frmModel, loadModelDataFunc } = useContext(ModuleContext);

  const { control, getValues, formState, handleSubmit } = frmModel();

  /**
   *
   */
  const onClose = () => {
    setOpen(false);
  };

  /**
   *
   */
  const onSubmit = async (data) => {
    try {
      spinner.show();
      const obj = data;
      console.log(obj);
      obj.DateCreated = new Date();
      await api.retailerApi.insert(obj);
      await loadModelDataFunc();
      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      spinner.hide();
    }
  };
  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 400, maxHeight: 500 }}
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title="Add New Retailer" onClose={onClose} />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className="dialog-form_inner">
        <DialogContent dividers={true}>
          <FormProvider {...frmModel()}>
            <div className="flex flex-col" style={{ marginRight: 20 }}>
              <TextInput
                id="retailerName"
                label="Retailer Name"
                rules={{ required: "Retailer Name is required" }}
                control={control}
              />
              <TextInput
                id="retailerShortName"
                label="Retailer Short Name"
                rules={{ required: "Retailer Short Name is required" }}
                control={control}
              />
              <TextInput
                id="retailerCity"
                label="City"
                rules={{ required: "City is required" }}
                control={control}
              />
              <TextInput
                id="retailerState"
                label="State"
                rules={{ required: " State is required" }}
                control={control}
                maxLength={2}
              />
              <div
                className="flex -mx-4"
                style={{ marginLeft: 10, marginTop: 10 }}
              >
                <CheckBox id="isActive" label="Active" control={control} />
              </div>
            </div>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
