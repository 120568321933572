import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextInput } from "src/app/@core/forms/controls/textInput";
import { TextArea } from "src/app/@core/forms/controls/textArea";
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";
import { DialogBase, DialogModelBase } from "src/app/@core/forms/dialog/@dialogBaseV2";
import { prodStatusConst } from "src/app/@domain/consts/prodStatus.const";
import { getFullName } from "src/app/@core/common";

export class OrderReversePendingDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.order = null;
    this.title = '';
    this.notes = '';
    this.contactName = '';
    this.retailerName = '';
    this.status = '';
    this.prevStatus = 0;
  }

}

export class OrderReversePendingDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   */
  submit = async () => {
    this.hide({
      orderID: this.model.order().orderID,
      notes: this.model.notes,
      status: this.model.status,
    });
  }

  /**
   * 
   * @returns 
   */
  show = async (prevStatus, order, contact, retailer) => {

    let title = '';
    let notes = '';
    let contactName = getFullName(contact());
    let retailerName = retailer().name;
    let status = '';

    title = 'Resume Production';

    if (prevStatus === prodStatusConst.NoTrialReceived) {
      notes = 'No Trial Received';
      status = 'No Trial Received';
    } else if (prevStatus === prodStatusConst.NoTrialCut) {
      notes = 'No Trial Cut';
      status = 'No Trial Cut';
    } else if (prevStatus === prodStatusConst.NoTrialOrTrialComplete) {
      notes = 'Complete';
      status = 'Complete'
    } else if (prevStatus === prodStatusConst.TrialReceived) {
      notes = 'Trial Received';
      status = 'Trial Received';
    } else if (prevStatus === prodStatusConst.TrialCut) {
      notes = 'Trial Cut';
      status = 'Trial Cut';
    } else if (prevStatus === prodStatusConst.NoTrialOrTrialComplete) {
      notes = 'Trial Complete';
      status = 'Trial Complete'
    } else if (prevStatus === prodStatusConst.PostTrialReceived) {
      notes = 'Trial Approved- Production Complete';
      status = 'Post Trial Received'
    } else if (prevStatus === prodStatusConst.PostTrialCut) {
      notes = 'Post Trial Cut';
      status = 'Post Trial Cut'
    } else if (prevStatus === prodStatusConst.PostTrialComplete) {
      notes = 'Post Trial Complete';
      status = 'Post Trial Complete'
    }

    if (status === '') throw new Error(`Status ${prevStatus} not found. If you have any question, please contact support.`)

    notes = 'Reverse from Pending to ' + notes;

    return super.show(x => {
      x.order = order;
      x.title = title;
      x.notes = notes;
      x.contactName = contactName;
      x.retailerName = retailerName;
      x.status = status;
      x.prevStatus = prevStatus;
    });
  }

}

/**
 *
 * @param {*} props
 * @returns
 */
export const OrderReversePendingDialog = props => {

  const { controller } = props;

  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 500, maxHeight: 400 }}
      open={controller.visible()}
      onClose={controller.close}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title={controller.model.title} onClose={controller.close} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="flex flex-col" style={{ marginRight: 20 }}>
          <TextInput label="Contact" value={controller.model.contactName} disabled={true} />
          <TextInput label="Retailer" value={controller.model.retailerName} disabled={true} />
          <TextArea label="Please confirm notes" value={controller.model.notes} disabled={true} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={controller.close}>Cancel</Button>
        {controller.model.nextStatus == prodStatusConst.PostTrialReceived &&
          <Button variant="contained" color="error" onClick={controller.submit}>No</Button>
        }
        <Button variant="contained" color="secondary" onClick={controller.submit}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
