export const DialogHeaderContent = props => {

  const { title, icon, onClose } = props;

  return <div className="flex">
    <div style={{ flex: 1 }}>
      {icon && icon()}
      {title}
    </div>
    <div style={{ width: '40px', display: 'flex', justifyContent: 'end' }}>
      <img src="img/dialog_close.png" width="30" style={{ cursor: 'pointer', height: 30 }} onClick={onClose} />
    </div>
  </div>



}