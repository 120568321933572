import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { TableRow } from "@mui/material";
import { TBodyCell } from "src/app/@core/forms/dataTable/dataTable.control";
import { ReportDialogBody } from "src/app/@core/forms/report/report.control";
import { saveXLSX, toMMDDYYYY } from "src/app/@core/common";

/**
 * 
 */
export class ReportDailyShirtsDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.gvItems = [];
    this.reportDate = null;
    this.startDate = null;
    this.endDate = null;
    this.title = '';
    this.shirtTypes = '';
    this.storedProd = '';
  }

}

/**
 * 
 */
export class ReportDailyShirtsDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   */
  exportToXlsx = async () => {

    if (!this.model.reportDate) this.exportDailyShirtsAverage();
    else this.exportDailyShirts();

  }

  /**
   * 
   */
  exportDailyShirtsAverage = async () => {

    try {
      this.spinner().show();
      const dto = {
        startDate: this.model.startDate,
        endDate: this.model.endDate,
        shirtTypes: this.model.shirtTypes,
        storedProc: this.model.storedProc
      };
      const result = await this.api().reportApi.exportDailyShirtsAverage(dto);
      saveXLSX(result, `${this.model.title.replace(/\s/g, '', '')}Report`);
    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

  /**
   * 
   */
  exportDailyShirts = async () => {

    try {
      this.spinner().show();
      const dto = {
        reportDate: this.model.reportDate,
        shirtTypes: this.model.shirtTypes,
        storedProc: this.model.storedProc
      };
      const result = await this.api().reportApi.exportDailyShirts(dto);
      saveXLSX(result, `${this.model.title.replace(/\s/g, '', '')}Report`);
    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

  /**
   * 
   * @param {*} startDate 
   * @param {*} endDate 
   * @param {*} shirtTypes 
   * @param {*} storedProc 
   * @returns 
   */
  showAverage = async (startDate, endDate, shirtTypes, storedProc) => {

    let title = ''
    if (storedProc === "pGetWholesaleShirtsAverageReport") title = "Wholesale Shirts Received Average";
    else if (storedProc === "pGetRetailShirtsReceivedAverageReport") title = "Retail Shirts Received Average";
    else if (storedProc === "pGetOtherRetailShirtsReceivedAverageReport") title = "Other Retail Shirts Received Average";
    else if (storedProc === "pGetShirtsReceivedAverageReport") title = "Shirts Received Average";
    else if (storedProc === "pGetShirtsCutAverageReport") title = "Shirts Cut Average";
    else if (storedProc === "pGetShirtsCompleteAverageReport") title = "Shirts Complete Average";

    try {

      this.spinner().show();
      const dto = {
        startDate,
        endDate,
        shirtTypes,
        storedProc
      };
      const result = await this.api().reportApi.getDailyShirtsAverage(dto);
      console.log(result);
      return super.show(x => {
        x.startDate = startDate;
        x.endDate = endDate;
        x.title = title;
        x.shirtTypes = shirtTypes;
        x.storedProc = storedProc;
        x.gvItems = result;
      });

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

  /**
   * 
   * @param {*} reportDate 
   * @param {*} shirtTypes 
   * @param {*} storedProc 
   * @returns 
   */
  show = async (reportDate, shirtTypes, storedProc) => {

    let title = ''
    if (storedProc === "pGetWholesaleShirtsReceived") title = "Wholesale Shirts Received";
    else if (storedProc === "pGetRetailShirtsReceived") title = "Retail Shirts Received";
    else if (storedProc === "pGetOtherRetailShirtsReceived") title = "Other Retail Shirts Received";
    else if (storedProc === "pGetShirtsReceived") title = "Shirts Received";
    else if (storedProc === "pGetShirtsCut") title = "Shirts Cut";
    else if (storedProc === "pGetShirtsComplete") title = "Shirts Complete";

    try {

      this.spinner().show();
      const dto = {
        reportDate,
        shirtTypes,
        storedProc
      };
      const result = await this.api().reportApi.getDailyShirts(dto);

      return super.show(x => {
        x.reportDate = reportDate;
        x.title = title;
        x.shirtTypes = shirtTypes;
        x.storedProc = storedProc;
        x.gvItems = result;
      });

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

};

/**
 *
 * @param {*} props
 * @returns
 */
export const ReportDailyShirtDialog = props => {

  const { controller } = props;
  const { title, gvItems } = controller.model;
  const { exportToXlsx } = controller;

  const columns = [
    { id: "lastName", align: "center", disablePadding: false, label: "Last Name", sort: true },
    { id: "firstName", align: "center", disablePadding: false, label: "First Name", sort: true },
    { id: "retailerName", align: "center", disablePadding: false, label: "Retailer", sort: true },
    { id: "salesperson", align: "center", disablePadding: false, label: "Salesperson", sort: true },
    { id: "newShirts", align: "center", disablePadding: false, label: "New", sort: true },
    { id: "lineItemType", align: "center", disablePadding: false, label: "Shirt Type", sort: true },
    { id: "repairShirts", align: "center", disablePadding: false, label: "Repair", sort: true },
    { id: "orderReceiveDate", align: "center", disablePadding: false, label: "Received Date", sort: true, width: 100 },
    { id: "prodDescText", align: "center", disablePadding: false, label: "Prod Type", sort: true, width: 100 },
    { id: "prodStatusText", align: "center", disablePadding: false, label: "Prod Status", sort: true, width: 100 },
    { id: "updatedlastStatusUpdateDate", align: "center", disablePadding: false, label: "Updated ", sort: true, width: 100 },
    { id: "cleanersDate", align: "center", disablePadding: false, label: "Clean", sort: true, width: 100 },
    { id: "shipDate", align: "center", disablePadding: false, label: "Ship", sort: true, width: 100 },
    { id: "arriveDate", align: "center", disablePadding: false, label: "Arrive", sort: true, width: 100 },
  ];


  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportRow = props => {

    const { item } = props;

    return <TableRow hover tabIndex={-1} key={item.itemID}>
      <TBodyCell align="left" value={item.lastName} />
      <TBodyCell align="left" value={item.firstName} />
      <TBodyCell align="left" value={item.retailerName} />
      <TBodyCell align="left" value={item.salesperson} />
      <TBodyCell align="center" value={item.newShirts} />
      <TBodyCell align="left" value={item.lineItemType} />
      <TBodyCell align="center" value={item.repairShirts} />
      <TBodyCell align="center" value={toMMDDYYYY(item.orderReceiveDate)} />
      <TBodyCell align="center" value={item.prodDescText} />
      <TBodyCell align="center" value={item.prodStatusText} />
      <TBodyCell align="center" value={toMMDDYYYY(item.updatedlastStatusUpdateDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.cleanersDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.shipDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.arriveDate)} />
    </TableRow>
  }

  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 1400, maxHeight: 600 }}
      open={controller.visible()}
      onClose={controller.hide}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title={title} onClose={controller.hide} />
      </DialogTitle>
      <DialogContent dividers={true} style={{ paddingTop: 0 }}>
        <div className="flex -mx-4">
          <ReportDialogBody
            columns={columns}
            gvItems={gvItems}
            onExport={exportToXlsx}
            ReportRow={ReportRow}
            controller={controller} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={controller.hide}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
