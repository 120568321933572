import { useContext } from 'react';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { DialogBaseV1 } from '../dialog/@dialogBaseV1';
import Dialog from '@mui/material/Dialog';

export class SpinnerService extends DialogBaseV1 {
  /**
   *
   * @param {*} context
   */
  constructor(context) {
    super(context, 'spinnerControlObj');
  }
}

/**
 *
 * @param {*} props
 * @returns
 */
export const SpinnerControl = (props) => {
  const { spinner } = useContext(DomainContext);
  return <>{spinner.visible() && <Dialog open={true}><FuseLoading /></Dialog>}</>;   
};
