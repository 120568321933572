import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import TableHead from '@mui/material/TableHead';

const columns = [
  {
    id: 'eidt',
    align: 'center',
    disablePadding: false,
    sort: false,
    width: 30,
  },
  {
    id: 'fabricID',
    align: 'left',
    disablePadding: false,
    label: 'Fabric ID',
    sort: true,
    width: 100,
  },
  {
    id: 'image',
    align: 'left',
    disablePadding: false,
    label: 'Image',
    sort: true,
    width: 100,
  },
  {
    id: 'fabricIDDescription',
    align: 'left',
    disablePadding: false,
    label: 'Fabric ID Description',
    sort: true,
    width: 300,
  },
  {
    id: 'currentAvailabilityStatus',
    align: 'left',
    disablePadding: false,
    label: 'Current Availability Status',
    sort: true,
    width: 100,
  },
  {
    id: 'arrival',
    align: 'left',
    disablePadding: false,
    label: 'Most Recent Arrival',
    sort: true,
    width: 100,
  },
  {
    id: 'shirtsToSell',
    align: 'left',
    disablePadding: false,
    label: 'Shirts To Sell',
    sort: true,
    width: 100,
  },
  {
    id: 'soldMonthly',
    align: 'left',
    disablePadding: false,
    label: 'Custom Shirts Sold Monthly',
    sort: true,
    width: 100,
  },
  {
    id: 'sellThruMonths',
    align: 'left',
    disablePadding: false,
    label: 'Sell Thru Months',
    sort: true,
    width: 100,
  },
];

function InventoryTableHead(props) {

  /**
   * 
   * @param {*} property 
   * @returns 
   */
  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className="h-48 sm:h-64">
        {
          columns.map(column => {
            return (
              <TableCell
                width={column.width}
                className="p-4 md:p-5"
                key={column.id}
                align="center"
                padding={column.disablePadding ? 'none' : 'normal'}
                sortDirection={props.order.id === column.id ? props.order.direction : false}>
                {
                  column.sort && (
                    <Tooltip
                      title="Sort"
                      placement={column.align === 'right' ? 'bottom-end' : 'bottom-start'}
                      enterDelay={300}>
                      <TableSortLabel
                        active={props.order.id === column.id}
                        direction={props.order.direction}
                        onClick={createSortHandler(column.id)}
                        className="font-semibold">
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  )
                }
              </TableCell>
            );
          }, this)
        }
      </TableRow>
    </TableHead>
  );
}

export default InventoryTableHead;
