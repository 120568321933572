import { Controller } from 'react-hook-form';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { isUoN } from 'src/app/@core/common';
import { SelectInputEmpty } from './selectInputEmpty';
import FormHelperText from '@mui/material/FormHelperText';
import InputBase from '@mui/material/InputBase';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const SelectInput = props => {
  const {
    id,
    label,
    control,
    disabled = false,
    items,
    defaultValue = '',
    placeHolder = 'Please select options',
    onChange = null,
    rules,
  } = props;

  /**
   *
   * @param {*} e
   */
  const change = (e) => {
    if (onChange) onChange(e);
  };

  /**
   *
   */
  if (isUoN(items)) return <SelectInputEmpty {...props} />;

  let localItems = [];
  if (!isUoN(items)) localItems = items;

  if (!control) {
    return (
      <FormControl className="mt-8 mb-2 mx-8" fullWidth disabled={disabled}>
        <InputLabel sx={{ backgroundColor: 'white', padding: '0 2px' }} size='small'>{label}</InputLabel>
        <Select
          labelId={label}
          label={label}
          defaultValue={defaultValue}
          value={defaultValue}
          size='small'
          onChange={change}>
          <MenuItem disabled value=''>
            <em>{placeHolder}</em>
          </MenuItem>
          {localItems.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl className="mt-8 mb-2 mx-8" fullWidth disabled={disabled}>
              <InputLabel sx={{ backgroundColor: 'white', padding: '0 2px' }} size='small' id={id}>{label}</InputLabel>
              <Select
                {...field}
                id={id}
                value={field.value || defaultValue}
                size='small'
                input={<InputBase style={{ border: '1px solid', borderRadius: '4px', borderColor: !!error ? 'red' : '#ced4da', padding: '0 10px ' }} />}>
                <MenuItem disabled value={placeHolder}>
                  <em>{placeHolder}</em>
                </MenuItem>
                {localItems.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!error}>
                {error ? error.message : null}
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
};
