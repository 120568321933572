import { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { DatePicker } from 'src/app/@core/forms/controls/datePicker';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { toAPIDate } from 'src/app/@core/helpers';
import {
  DialogBase,
  DialogModelBase,
} from 'src/app/@core/forms/dialog/@dialogBaseV2';
/**
 *
 */
export class ProductionEditDialogModel extends DialogModelBase {
  /**
   *
   */
  constructor() {
    super();
    this.frmLocal = useForm({ mode: 'onChange', defaultValues: {} });
  }
}
export class ProductionEditDialoDialogController extends DialogBase {
  /**
   *
   * @param {*} model
   * @param {*} setModel
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   *
   * @param {*} frmVals
   *
   */
  submit = async (frmVals) => {
    try {
      this.spinner().show();
      const dto = {
        orderID: frmVals.orderID,
        productionID: frmVals.productionID,
        receiveDate: toAPIDate(frmVals.receiveDate),
        cutDate: toAPIDate(frmVals.cutDate),
        completeDate: toAPIDate(frmVals.completeDate),
        cleanersDate: toAPIDate(frmVals.cleanersDate),
        shipDate: toAPIDate(frmVals.shipDate),
        arrivedate: toAPIDate(frmVals.arriveDate),
      };
      const result = await this.api().orderApi.updateProdActivity(dto);
      this.hide(result);
    } catch (err) {
    } finally {
      this.spinner().hide();
    }
  };
  /**
   *
   * @param {*} item
   * @returns
   */
  show = (item) => {
    this.model.frmLocal.reset(item);
    return super.show();
  };
}
/**
 *
 */
export const ProductionEdit = (props) => {
  const { controller } = props;
  const { frmLocal } = controller.model;
  const { control, getValues } = frmLocal;

  /**
   *
   */
  const onSubmit = () => {
    controller.submit(getValues());
  };

  return (
    <Dialog
      fullScreen
      style={{ margin: 'auto', maxWidth: 350, maxHeight: 530 }}
      open={controller.visible()}
      onClose={controller.close}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>
        Update Production Dates
      </DialogTitle>
      <DialogContent dividers={true}>
        <FormProvider {...frmLocal}>
          <div className='w-full flex flex-col' style={{ paddingRight: 20 }}>
            <DatePicker id='receiveDate' label='Receive Date' control={control} />
            <br />
            <DatePicker id='cutDate' label='Cut Date' control={control} />
            <br />
            <DatePicker id='completeDate' label='Complete Date' control={control} />
            <br />
            <DatePicker id='cleanersDate' label='Cleaners Date' control={control} />
            <br />
            <DatePicker id='shipDate' label='Ship Date' control={control} />
            <br />
            <DatePicker id='arriveDate' label='Arrive Date' control={control} />
          </div>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={controller.close}>
          Cancel
        </Button>
        <Button variant='contained' color='secondary' onClick={onSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
