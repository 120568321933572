import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { TableRow } from "@mui/material";
import { TBodyCell } from "src/app/@core/forms/dataTable/dataTable.control";
import { ReportDialogBody } from "src/app/@core/forms/report/report.control";
import { saveXLSX, toMMDDYYYY } from "src/app/@core/common";

/**
 * 
 */
export class ProductionToCutReportDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.gvItems = [];
    this.retailerShortName = '';
  }

}

/**
 * 
 */
export class ProductionToCutReportDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   */
  exportToXlsx = async () => {
    try {
      this.spinner().show();
      const result = await this.api().reportApi.exportProductionsToCut(this.model.retailerShortName);
      saveXLSX(result, 'ProductionToCutReport');
    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }
  }

  /**
   * 
   * @param {*} retailerShortName 
   * @returns 
   */
  show = async retailerShortName => {

    try {
      this.spinner().show();
      const result = await this.api().reportApi.getProductionsToCut(retailerShortName);
      return super.show(x => {
        x.retailerShortName = retailerShortName;
        x.gvItems = result;
      });
    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

};

/**
 *
 * @param {*} props
 * @returns
 */
export const ProductionToCutReportDialog = props => {

  const { controller } = props;
  const { gvItems } = controller.model;
  const { exportToXlsx } = controller;

  const columns = [
    { id: "lastName", align: "center", disablePadding: false, label: "Last Name", sort: true },
    { id: "firstName", align: "center", disablePadding: false, label: "First Name", sort: true },
    { id: "retailerName", align: "center", disablePadding: false, label: "Retailer Name", sort: true },
    { id: "newShirts", align: "center", disablePadding: false, label: "New", sort: true, width: 100 },
    { id: "repairShirts", align: "center", disablePadding: false, label: "Repair", sort: true, width: 100 },
    { id: "receiveDate", align: "center", disablePadding: false, label: "Received", sort: true, width: 100 },
    { id: "prodDescText", align: "center", disablePadding: false, label: "Prod Type", sort: true, width: 100 },
    { id: "prodStatusText", align: "center", disablePadding: false, label: "Prod Status", sort: true, width: 100 },
    { id: "updatedlastStatusUpdateDate", align: "center", disablePadding: false, label: "Updated ", sort: true, width: 100 },
    { id: "cleanersDate", align: "center", disablePadding: false, label: "Clean", sort: true, width: 100 },
    { id: "shipDate", align: "center", disablePadding: false, label: "Ship", sort: true, width: 100 },
    { id: "arriveDate", align: "center", disablePadding: false, label: "Arrive", sort: true, width: 100 },
  ];


  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportRow = props => {

    const { item } = props;

    return <TableRow hover tabIndex={-1} key={item.itemID}>
      <TBodyCell align="left" value={item.lastName} />
      <TBodyCell align="left" value={item.firstName} />
      <TBodyCell align="left" value={item.retailerName} />
      <TBodyCell align="center" value={item.newShirts} />
      <TBodyCell align="center" value={item.repairShirts} />
      <TBodyCell align="center" value={toMMDDYYYY(item.receiveDate)} />
      <TBodyCell align="center" value={item.prodDescText} />
      <TBodyCell align="center" value={item.prodStatusText} />
      <TBodyCell align="center" value={toMMDDYYYY(item.updatedlastStatusUpdateDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.cleanersDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.shipDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.arriveDate)} />
    </TableRow>
  }

  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 1200, maxHeight: 600 }}
      open={controller.visible()}
      onClose={controller.hide}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title="Production To Cut" onClose={controller.hide} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="flex -mx-4">
          <ReportDialogBody
            columns={columns}
            gvItems={gvItems}
            onExport={exportToXlsx}
            ReportRow={ReportRow}
            controller={controller}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={controller.hide}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
