import { OrdersPage } from 'src/app/@domain/modules/order/@orders.page';
import { authRoles } from 'src/app/auth';
import AdminRoleExample from 'src/app/main/auth/admin-role-example/AdminRoleExample';

const OrderCreateConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: '/createOrder',
      element: <OrdersPage />,
    },
    {
      path: '/auth/admin',
      element: <AdminRoleExample />,
    },
  ],
};

export default OrderCreateConfig;

