/**
 *
 */
export class DialogBaseV1 {
  /**
   *
   * @param {*} context
   * @param {*} dataObjName
   */
  constructor(context, dataObjName) {
    this.context = context;
    this.dataObjName = dataObjName;
  }

  /**
   *
   * @returns
   */
  store = () => this.context.store;

  /**
   *
   * @returns
   */
  api = () => this.context.api;

  /**
   *
   * @returns
   */
  spinner = () => this.context.spinner;

  /**
   *
   * @returns
   */
  model = () => this.store()[this.dataObjName];

  /**
   *
   * @returns
   */
  visible = () => this.model().visible;

  /**
   *
   * @returns
   */
  resolve(data) {
    if (!this.model().resolve) return;
    this.model().resolve(data);
  }

  /**
   * 
   * @param {*} func 
   */
  updateStore = func => {
    const store = { ...this.store() };
    func(store);
    this.context.setStore(store);
  }

  /**
   *
   */
  hide(data) {
    const store = { ...this.store() };
    store[this.dataObjName].visible = false;
    this.context.setStore(store);
    this.resolve(data);
  }

  /**
   *
   * @returns
   */
  show() {
    const store = { ...this.store() };
    store[this.dataObjName].visible = true;
    this.context.setStore(store);
    return new Promise(
      (resolve) => (store[this.dataObjName].resolve = resolve)
    );
  }
  
  getRetailers() {
    const store = { ...this.store() };
    return store[t];
  }
}
