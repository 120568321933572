import { APIBase } from "./@base.api";

export class RetailerApi extends APIBase {
  /**
   *
   */
  constructor(context) {
    super(context);
    this.endPoints = {
      getAll: "Retailer/GetAll",
      getAllSP: "Retailer/GetAllSP",
      insert: "Retailer/Insert",
      update: "Retailer/Update",
      delete: "Retailer/Delete",
      get: "Retailer/get",
      getDropdownItems: "Retailer/GetDropdownItems",
      getByLocation: "Retailer/GetByLocation",

    };
  }

  /**
   *
   * @returns
   */
  getByLocation = () => this.httpGet(this.endPoints.getByLocation);
  
  /**
   *
   * @returns
   */
  getAllSP = () => this.httpGet(this.endPoints.getAllSP);

  /**
   * 
   * @returns 
   */
  getDropdownItems = () => this.httpGet(this.endPoints.getDropdownItems);

}
