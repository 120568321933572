import { useContext } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput } from 'src/app/@core/forms/controls/textInput'
import { SelectInput } from 'src/app/@core/forms/controls/selectInput'
import { TextArea } from 'src/app/@core/forms/controls/textArea'
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2'
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent'
import { DomainContext } from 'src/app/@core/@contexts/domain.context'
import { CheckBox } from 'src/app/@core/forms/controls/checkbox'
import { comboLists } from "src/app/@domain/consts/comboLists";
import { isUoN } from 'src/app/@core/common'
/**
 *
 */
export class ShirtsEditDialogModel extends DialogModelBase {
  /**
   *
   */
  constructor() {
    super()
    this.frmLocal = useForm({ mode: 'onChange', defaultValues: {} });
    this.order = null;
    this.shirt = null;
    this.prodTypes = [];
  }
}
/**
 *
 */
export class ShirtsEditDialogController extends DialogBase {
  /**
   *
   * @param {*} model
   * @param {*} setModel
   */
  constructor(model, setModel) {
    super(model, setModel)
  }

  /**
   *
   * @param {*} frmVals
   */
  submit = async (frmVals) => {
    try {

      this.spinner().show()

      const dto = {
        fabricID: frmVals.fabricID,
        prodID: this.model.shirt.prodID,
        productionItemID: frmVals.productionItemID,
        quantity: frmVals.newOnOrder,
        quantityHolding: frmVals.newHolding,
        lineItemType: frmVals.lineItemType,
        alternativeDescription: frmVals.alternativeDescription ? frmVals.alternativeDescription : '',
        reversed: frmVals.reversed === 0 ? false : true,
      }

      const result = await this.api().orderApi.updateShirt(dto);
      this.hide(result);

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }
  }

  /**
   *
   * @param {*} controller
   * @param {*} shirt
   * @returns
   */
  show = async (controller, shirt) => {

    const prodTypes = [... new Set(controller.shirts().map(x => x.prodType))]
      .map(x => { return { id: x, name: x } });
    if (isUoN(shirt.availability)) shirt.availability = ' ';
    if (isUoN(shirt.description)) shirt.description = ' ';
    if (isUoN(shirt.alternativeDescription)) shirt.alternativeDescription = '';
    this.model.frmLocal.reset(shirt)

    return super.show((x) => {
      x.order = controller.order;
      x.shirt = shirt;
      x.prodTypes = prodTypes;
    })
  }
}
/**
 *
 * @param {*} props
 * @returns
 */
export const ShirtsEditDialog = (props) => {
  const { controller } = props
  const { frmLocal } = controller.model
  const { control, getValues } = frmLocal
  const { fabricSwatch } = frmLocal.getValues()

  const { dropdown } = useContext(DomainContext)

  /**
   *
   */
  const onSubmit = async () => {
    controller.submit(getValues())
  }

  return (
    <Dialog
      fullScreen
      style={{ margin: 'auto', maxWidth: 700, maxHeight: 650 }}
      open={controller.visible()}
      onClose={controller.close}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title="Edit Shirt" onClose={controller.close} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <FormProvider {...frmLocal}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: "30%", paddingTop: 10 }}>
              <img src={fabricSwatch} width="90%" />
            </div>
            <div style={{ flex: 1 }}>
              <TextInput id="fabricID" label="Fabric ID" control={control} />
              <div className="flex -mx-4" style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                <CheckBox id="reversed" label="Reversed" control={control} />
              </div>
              <TextInput id="availability" label="Availability" control={control} disabled={true} />
              <SelectInput id="lineItemType" label="Shirt Types" items={comboLists.shirtTypes} control={control} />
              <SelectInput id="prodType" label="Production Types" control={control} items={controller.model.prodTypes} />
              <TextArea id="description" label="Description" control={control} rows={3} disabled={true} />
              <TextArea id="alternativeDescription" label="Alternative Description" control={control} rows={3} />
              <TextInput id="newOnOrder" label="Ordered Quanlity" control={control} />
              <TextInput id="newHolding" label="Holding" control={control} />
            </div>
          </div>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={controller.close}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
