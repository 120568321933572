import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const PCreateButton = props => {

  const { onAdd } = props;

  return <motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
    <Button variant="contained" color="secondary" onClick={onAdd} startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}>
      Create
    </Button>
  </motion.div>

}
