export const prodStatusConst = {
  TrialReceived: 0,
  TrialCut: 1,
  TrialSewing: 2,
  TrialPackaging: 3,
  TrialShipped: 4,
  PendingApproval: 5,
  PostTrialReceived: 6,
  PostTrialCut: 7,
  PostTrialPackaging: 8,
  PostTrialComplete: 10,
  NoTrialReceived: 12,
  NoTrialCut: 13,
  NoTrialSewing: 14,
  NoTrialPackaging: 15,
  NoTrialShipped: 16,
  NoTrialOrTrialComplete: 17,
  Pending: 19,
  HoldingForPickUp: 20,
  HoldingForCourier: 21,
  TrialNotApproved: 22,
  Cancelled: 23,
}

