import { useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import Button from '@mui/material/Button';
import { DialogHeaderContent } from "./dialogHeaderContent";
import { DialogBaseV1 } from "../dialog/@dialogBaseV1";
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

export class MessageBoxService extends DialogBaseV1 {

  /**
   * 
   * @param {*} context 
   */
  constructor(context) {
    super(context, 'messageBoxControlObj');
  }

  /**
   *
   * @returns
   */
  title = () => this.model().title

  /**
   *
   * @returns
   */
  message = () => this.model().message;

  /**
   * 
   * @returns 
   */
  icon = () => this.model().icon;

  /**
   * 
   * @param {*} message 
   */
  showMessage = message => {
    const store = { ...this.store() };
    store.messageBoxControlObj.title = 'Message';
    store.messageBoxControlObj.message = message;
    store.messageBoxControlObj.icon = () => <InfoIcon style={{ marginRight: 5 }} />;
    this.context.setStore(store);
    return super.show();
  }
  
  /**
   * 
   * @param {*} message 
   */
  showError = message => {
    const store = { ...this.store() };
    store.messageBoxControlObj.title = 'Error';
    store.messageBoxControlObj.message = message;
    store.messageBoxControlObj.icon = () => <ErrorIcon style={{ color: 'red', marginRight: 5 }} />;
    this.context.setStore(store);
    return super.show();
  }

};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const MessageBoxControl = props => {

  const { messageBox } = useContext(DomainContext)

  /**
   * 
   */
  const onClose = () => {
    messageBox.resolve('close');
    messageBox.hide();
  }

  return (
    <Dialog fullScreen style={{ margin: 'auto', maxWidth: 500, maxHeight: 250, zIndex: 10000 }}
      open={messageBox.visible()}
      onClose={onClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title={messageBox.title()} icon={messageBox.icon()} onClose={onClose} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <div dangerouslySetInnerHTML={{ __html: messageBox.message() }} />
      </DialogContent>
      <DialogActions>
        <div className="flex" style={{ margin: 10 }}>
          <Button variant="contained" color="primary" onClick={onClose}>Close</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

