import { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { ModuleContext } from 'src/app/@core/@contexts/module.context';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const ContactDeavtivate = props => {

  const { open, setOpen } = props;
  const { api, dropdown, spinner } = useContext(DomainContext);
  const { comments, frmModel, loadModelDataFunc } = useContext(ModuleContext);

  const { control, getValues } = frmModel();

  /**
   * 
   */
  const onClose = () => {
    setOpen(false);
  };

  /**
   * 
   */
  const onSubmit = () => {

    // spinner.show();
    // const obj = drillWellModel.toRemoteObj(getValues());
    // obj.modifiedBy = 'admin';
    // obj.modifiedOn = new Date();
    // obj.drillWellComments = drillWellCommentModel.toRemoteObjs(comments());
    // api.drillWellApi.insert(obj).then(result => {
    //   onClose();
    //   loadModelDataFunc();
    //   comments([]);  
    // }).finally(() => spinner.hide());

  }

  return <Dialog fullScreen style={{ margin: 'auto', maxWidth: 300, maxHeight: 200 }}
    open={open}
    onClose={onClose}
    scroll='paper'
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description">
    <DialogTitle id="scroll-dialog-title">
      Deactivate Contact
    </DialogTitle>
    <DialogContent dividers={true}>
      <div className="flex flex-col" style={{ marginRight: 20 }}>
        Do you want to deactivate this contact?
      </div>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" onClick={onClose}>No</Button>
      <Button variant="contained" color="error" onClick={onSubmit}>Yes</Button>
    </DialogActions>
  </Dialog>
}
