import { APIBase } from "./@base.api";

export class ContactApi extends APIBase {
  /**
   *
   */
  constructor(context) {
    super(context);
    this.endPoints = {
      getAll: "Contact/GetAll",
      getAllSP: "Contact/GetAllSP",
      insert: "Contact/Insert",
      update: "Contact/Update",
      delete: "Contact/Delete",
      get: "Contact/get",
      getShirtUsers: "Contact/GetShirtUsers",
      getDropdownItems: "Contact/GetDropdownItems",
    };
  }

  /**
   * 
   * @returns 
   */
  getDropdownItems = () => this.httpGet(this.endPoints.getDropdownItems);

  /**
   * 
   * @returns 
   */
  getShirtUsers = () => this.httpGet(this.endPoints.getShirtUsers);

  /**
   *
   * @returns
   */
  getAllSP = () => this.httpGet(this.endPoints.getAllSP);
}
