import { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Controller, FormProvider } from "react-hook-form";
import { TextInput } from "src/app/@core/forms/controls/textInput";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { ModuleContext } from "src/app/@core/@contexts/module.context";
import { TextArea } from "src/app/@core/forms/controls/textArea";
import { isUoN } from "src/app/@core/common";
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";

/**
 *
 * @param {*} props
 * @returns
 */
export const ContactAdd = (props) => {
  
  const { open, setOpen, retailers } = props;
  const { api, spinner } = useContext(DomainContext);

  const { frmModel, loadModelDataFunc } = useContext(ModuleContext);

  const { control, getValues } = frmModel();

  /**
   *
   */
  const onClose = () => {
    setOpen(false);
  };

  /**
   *
   */
  const onSubmit = async () => {
    spinner.show();
    try {
      const obj = getValues();
      if (!isUoN(obj.retailer)) obj.retailerID = obj.retailer.id;
      obj.isActive = true;
      obj.dateCreated = new Date();
      await api.contactApi.insert(obj);
      onClose();
      loadModelDataFunc();
      } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 1000, maxHeight: 600 }}
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title="Add New Contact" onClose={onClose} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <FormProvider {...frmModel()}>
          <div>
            <div className="flex -mx-4">
              <TextInput id="lastName" label="Last Name" control={control} />
              <TextInput id="firstName" label="First Name" control={control} />
              <TextInput id="middleName" label="Middle Name" control={control} />
              <TextInput id="title" label="Title" control={control} />
            </div>
            <div className="flex">
              <div style={{ width: '50%', marginLeft: 5, marginRight: 10 }}>
                <Controller
                  name="retailer"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disablePortal
                      id="retailer"
                      className="mt-8"
                      fullWidth
                      options={retailers}
                      value={null}
                      onChange={(e, data) => field.onChange(data)}
                      getOptionLabel={x => x.name}
                      renderOption={(props, option) => <li {...props} key={option.itemID}>{option.name}</li>}
                      renderInput={params => <TextField {...params} className="" label="Retailer" size="small" />} />
                  )}
                />
              </div>
              <div style={{ width: '50%', marginRight: 10 }}>
                <TextInput id="email" label="Email" control={control} />
              </div>
            </div>
            <div className="flex -mx-4">
              <TextArea id="contactNotes" label="Comments" control={control} />
            </div>
          </div>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
