import { FormControl } from "@mui/base";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderAddStep03 = props => {

  const { localModel, setLocalModel } = props;
  const {moreShirt} = localModel;

  /**
   * 
   * @param {*} val 
   */
  const onChange = val => {   
    const obj = {...localModel, moreShirt: val};
    setLocalModel(obj);
  }

  return <div style={{ margin: "20px 10px", padding: 40, border: "1px solid #cbcbcb", borderRadius: 10 }}>
    <div className="flex flex-row">
      <FormControl className="flex flex-row">
        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginTop: 10, marginRight: 20 }}>
          Add additional shirts?
        </FormLabel>
        <RadioGroup row defaultValue="0" name="radio-buttons-group" >
          <FormControlLabel value="1" control={<Radio checked={moreShirt} onChange={() => onChange(true)} />} label="Yes" />
          <FormControlLabel value="0" control={<Radio checked={!moreShirt} onChange={() => onChange(false)} />} label="No" />
        </RadioGroup>
      </FormControl>
    </div>
  </div>
}