import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from 'src/app/@core/forms/controls/textArea';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';

/**
 * 
 */
export class OrderPendingDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.orderID = 0;
    this.frmLocal = useForm({ mode: 'onChange', defaultValues: {} });
  }

}

/**
 * 
 */
export class OrderPendingDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   */
  submit = async () => {

    const noteDescription = this.model.frmLocal.getValues().noteDescription;

    try {

      this.spinner().show();
      const result = await this.api().orderApi.pendingOrder({
        orderID: this.model.orderID,
        noteDescription
      });
      this.hide(result);

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

  /**
   * 
   * @returns 
   */
  show = async func => {
    this.model.frmLocal.reset();
    return super.show(func);
  }

};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderPendingDialog = props => {

  const { controller } = props;
  const { frmLocal } = controller.model;
  const { control } = frmLocal;

  return <Dialog fullScreen style={{ margin: 'auto', maxWidth: 500, maxHeight: 400 }}
    open={controller.visible()}
    onClose={() => controller.hide(false)}
    scroll='paper'
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description">
    <DialogTitle id="scroll-dialog-title">
      <DialogHeaderContent title={"Set Order To Pending"} onClose={() => controller.hide(false)} />
    </DialogTitle>
    <DialogContent dividers={true}>
      <div className="flex flex-col" style={{ marginRight: 20 }}>
        <FormProvider {...frmLocal}>
          <div style={{ paddingLeft: 10, marginBottom: 5 }}>
            The whole order will be set to pending. Please enter the reason.
          </div>
          <TextArea id="noteDescription" label="Notes" control={control} rows={10} />
        </FormProvider>
      </div>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" onClick={() => controller.hide(false)}>Cancel</Button>
      <Button variant="contained" color="secondary" onClick={controller.submit}>Confirm</Button>
    </DialogActions>
  </Dialog>
}
