import { FormProvider, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextArea } from 'src/app/@core/forms/controls/textArea';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import {
  DialogBase,
  DialogModelBase,
} from 'src/app/@core/forms/dialog/@dialogBaseV2';

/**
 *
 */
export class NotesEditDialogModel extends DialogModelBase {
  /**
   *
   */
  constructor() {
    super();
    this.productionID = '';
    this.frmLocal = useForm({ mode: 'onChange', defaultValues: {} });
  }
}

export class NotesEditDialogController extends DialogBase {
  /**
   *
   * @param {*} model
   * @param {*} setModel
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   *
   * @param {*} frmVals
   */
  submit = async (frmVals) => {
    try {
      this.spinner().show();

      const dto = frmVals;
      dto.notesContactID = this.user.data.contactID;
      dto.noteDate = new Date();
      if (!dto.noteDescription) dto.noteDescription = '';

      const result = await this.api().productionNoteApi.update(dto);

      this.hide(result);
    } catch (err) {
      console.log(err);
    } finally {
      this.spinner().hide();
    }
  };

  /**
   *
   * @param {*} item
   * @returns
   */
  show = (item) => {
    this.model.frmLocal.reset(item);
    return super.show((x) => (x.productionID = item.productionID));
  };
}

/**
 *
 * @param {*} props
 * @returns
 */
export const NotesEditDialog = (props) => {
  const { controller } = props;
  const { frmLocal } = controller.model;
  const { control, getValues, handleSubmit } = frmLocal;

  /**
   *
   */
  const onClose = () => {};

  /**
   *
   */
  const onSubmit = async () => {
    controller.submit(getValues());
  };

  return (
    <Dialog
      fullScreen
      style={{ margin: 'auto', maxWidth: 500, maxHeight: 450 }}
      open={controller.visible()}
      onClose={controller.hide}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>
        <DialogHeaderContent title='Edit Note' onClose={controller.hide} />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className='dialog-form_inner'>
        <DialogContent dividers={true}>
          <FormProvider {...frmLocal}>
            <div>
              <div className='flex -mx-4'>
                <TextArea
                  id='noteDescription'
                  label='Notes'
                  control={control}
                  rows={13}
                  rules={{ required: 'Notes is required' }}
                />
              </div>
            </div>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={controller.hide}>
            Cancel
          </Button>
          <Button variant='contained' color='secondary' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
