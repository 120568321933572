import { useContext, useState } from "react";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { ModuleContext } from "src/app/@core/@contexts/module.context";
import { ContactAdd } from "./contact.add";
import { PHeader } from "src/app/@domain/@shared/controls/page/header.page";
import { PSearchInput } from "src/app/@domain/@shared/controls/page/searchInput.page";
import { PCreateButton } from "src/app/@domain/@shared/controls/page/createButton.page";
import { TPagination } from "src/app/@core/forms/dataTable/dataTable.control";

/**
 *
 * @param {*} props
 * @returns
 */
const ContactsHeader = (props) => {

  const { gvItems, retailers } = props;
  const { dropdown } = useContext(DomainContext);
  const { frmModel, page, rowsPerPage, searchText } = useContext(ModuleContext);

  const { reset } = frmModel();
  const [open, setOpen] = useState(false);
  const [selectedRetailer, setSelectedRetailer] = useState({ itemID: '', id: 0, name: '' });

  /**
   *
   * @param {*} event
   * @param {*} value
   */
  const onChangePage = (event, value) => {
    page(value);
  };

  /**
   *
   * @param {*} event
   */
  const onChangeRowsPerPage = (event) => {
    rowsPerPage(event.target.value);
  };

  /**
   *
   */
  const onAdd = () => {
    reset(contactModel.createNew());
    setOpen(true);
  };

  /**
   * 
   * @param {*} item 
   * @returns 
   */
  const onRetailerChange = item => {
    if (!item) return;
    const retailer = retailers.find(x => x.id == item.id);
    setSelectedRetailer(retailer);
    console.log(item);
  }

  return (
    <div style={{ width: "100%" }}>
      <PHeader title="Contacts" />
      <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
        <PSearchInput searchText={searchText} />
        <PCreateButton onAdd={onAdd} />
        <TPagination gvItems={gvItems} rowsPerPage={rowsPerPage} page={page} onChangePage={onChangePage} onChangeRowsPerPage={onChangeRowsPerPage} />
      </div>
      <ContactAdd open={open} setOpen={setOpen} retailers={retailers} />
    </div>
  );
};

export default ContactsHeader;
