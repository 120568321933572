import { useContext, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "app/store/userSlice";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { showMessage } from 'app/store/fuse/messageSlice';
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon';
import { NoWrapControl } from 'src/app/@core/forms/controls/noWrapControl';
import { isUoN } from 'src/app/@core/common';
import { OrderAdvanceDialog, OrderAdvanceDialogController, OrderAdvanceDialogModel } from 'src/app/@domain/modules/prodStatus/orderAdvance.dialog';
import { OrderReverseDialog, OrderReverseDialogController, OrderReverseDialogModel } from 'src/app/@domain/modules/prodStatus/orderReverse.dialog';
import { OrderReversePendingDialog, OrderReversePendingDialogController, OrderReversePendingDialogModel } from 'src/app/@domain/modules/prodStatus/orderReversePending.dialog';
import { OrderPendingDialog, OrderPendingDialogController, OrderPendingDialogModel } from 'src/app/@domain/modules/prodStatus/orderPending.dialog';
import { ModuleContext } from 'src/app/@core/@contexts/module.context';

/**
 * 
 */
export class OrderStatusController {

  /**
   * 
   * @param {*} controllerBase 
   */
  constructor(controllerBase) {
    this.base = controllerBase;
    this.orderAdvanceDialogController = null;
    this.orderReverseDialogController = null;
    this.orderReversePendingDialogController = null;
    this.orderPendingDialogController = null;
    this.orderCancelDialogController = null;
  }

  /**
   *
   */
  cancelOrder = async () => {

    const result = await this.orderCancelDialogController.show(x => x.orderID = this.base.model.orderID);
    if (!result) return;

    this.base.dispatch(showMessage({ message: `This order has been Cancelled.` }));
    await this.base.refresh();

  };

  /**
   * 
   * @param {*} orderID 
   * @returns 
   */
  executeReversePending = async orderID => {

    const order = await this.base.api().orderApi.getEditOrder(orderID);
    if (!order.orderStatus.includes('Pending')) return false;

    this.base.spinner().show();
    const reversePendingStatus = await this.base.api().orderApi.canReversePendingOrder(orderID);
    this.base.spinner().hide();

    // reversePendingStatus = nul: this is not a pending order
    if (isUoN(reversePendingStatus)) return false;

    if (reversePendingStatus < 0) {
      this.base.messageBox().showMessage("This order can not be reversed from pending. If you have any question, please contact support.");
      return true;
    }

    try {

      const canReversePendingResult = await this.orderReversePendingDialogController.show(reversePendingStatus, this.base.order, this.base.shirtUser, this.base.retailer);
      if (!canReversePendingResult) return true;

      this.base.spinner().show();
      const reversePendingResult = await this.base.api().orderApi.reversePendingOrder(canReversePendingResult)
      this.base.spinner().hide();
      if (!reversePendingResult) return true;
  
      this.base.dispatch(showMessage({ message: `This order has been resumed to production.` }));
      await this.base.refresh();
  
    } catch (err) {
      this.base.messageBox().showError(err);
    }

    return true;

  }

  /**
   * 
   */
  advanceOrder = async () => {

    try {

      this.base.spinner().show();

      const orderID = this.base.order().orderID;
      if (await this.executeReversePending(orderID)) return;

      const nextStatus = await this.base.api().orderApi.canAdvance(orderID);
      if (!nextStatus) {
        this.base.messageBox().showMessage("This order can not be advanced. If you have any question, please contact support.");
        return;
      }

      this.base.spinner().hide();
      const canAdvanceResult = await this.orderAdvanceDialogController.show(nextStatus, this.base.order, this.base.shirtUser, this.base.retailer);
      if (!canAdvanceResult) {
        return;
      }

      this.base.spinner().show();
      const advanceResult = await this.base.api().orderApi.advanceOrder(canAdvanceResult)
      if (advanceResult === null) return;

      this.base.dispatch(showMessage({ message: `This order has been advanced to ${advanceResult.orderStatus}.` }));
      await this.base.refresh();

    } catch (err) { console.log(err); }
    finally { this.base.spinner().hide(); }

  }

  /**
   * 
   */
  reverseOrder = async () => {

    try {

      this.base.spinner().show();

      const orderID = this.base.order().orderID;
      if (await this.executeReversePending(orderID)) return;

      const prevStatus = await this.base.api().orderApi.canReverse(orderID);
      if (isUoN(prevStatus)) {
        this.base.messageBox().showMessage("This order can not be reversed. If you have any question, please contact support.");
        return;
      }

      this.base.spinner().hide();
      const canReverseResult = await this.orderReverseDialogController.show(prevStatus, this.base.order, this.base.shirtUser, this.base.retailer);
      if (!canReverseResult) return;

      this.base.spinner().show();
      const reverseResult = await this.base.api().orderApi.reverseOrder(canReverseResult)
      if (!reverseResult) return;

      this.base.spinner().hide();
      this.base.dispatch(showMessage({ message: `This order has been reversed to ${canReverseResult.status}.` }));
      await this.base.refresh();

    } catch (err) { console.log(err); }
    finally { this.base.spinner().hide(); }

  }

  /**
   *
   */
  pendingOrder = async () => {

    try {

      this.base.spinner().show();

      const canPendinggOrder = await this.base.api().orderApi.canPendingOrder(this.base.model.orderID);
      if (!canPendinggOrder) return;
  
      this.base.spinner().hide();
      const result = await this.orderPendingDialogController.show(x => x.orderID = this.base.model.orderID);
      if (!result) return;
  
      this.base.dispatch(showMessage({ message: `This order has been set to Pending.` }));
      await this.base.refresh();
    
    } catch (err) { console.log(err); }
    finally { this.base.spinner().hide(); }
    
  };

  /**
   * 
   */
  loadProductionLogStatusController = async () => {

    try {

      this.base.spinner().show();

      const contacts = await this.base.api().contactApi.getDropdownItems();
      const retailers = await this.base.api().retailerApi.getDropdownItems();
      const order = await this.base.api().orderApi.getEditOrder(this.base.model.orderID);
      const shirtUser = contacts.find(x => x.contactID === order.contactID);
      const retailer = retailers.find(x => x.id === order.retailerID);
  
      this.base.model.order = order;
      this.base.model.shirtUser = shirtUser;
      this.base.model.retailer = retailer;
  
    } catch (err) { console.log(err); }
    finally { this.base.spinner().hide(); }

  }

  /**
   * 
   */
  execAdvanceOrder = async () => {

    try {

      this.base.spinner().show();

      await this.loadProductionLogStatusController();
      await this.advanceOrder();
    
    } catch (err) { console.log(err); }
    finally { this.base.spinner().hide(); }

  }

  /**
   * 
   */
  execReverseOrder = async () => {
    await this.loadProductionLogStatusController();
    await this.reverseOrder();
  }

  /**
   * 
   */
  execPendingOrder = async () => {
    await this.loadProductionLogStatusController();
    await this.pendingOrder();
  }

}

class ProductionLogStatusController {

  /**
   * 
   * @param {*} item 
   */
  constructor(item) {
    this.domainContext = useContext(DomainContext);
    this.moduleContext = useContext(ModuleContext);
    this.user = useSelector(selectUser);
    this.dispatch = useDispatch();
    this.model = {
      orderID: item.orderID,
      order: null,
      shirtUser: null,
      retailer: null
    };
    this.refreshItem = this.moduleContext.refreshItem;

  }

  api = () => this.domainContext.api;
  spinner = () => this.domainContext.spinner;
  messageBox = () => this.domainContext.messageBox;
  order = () => this.model.order;
  shirtUser = () => this.model.shirtUser;
  retailer = () => this.model.retailer;

  /**
   * 
   */
  refresh = async () => {
    this.refreshItem(this.model.orderID);
  }

}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const ProductionLogStatusControl = props => {

  const { item } = props;

  const controllerBase = new ProductionLogStatusController(item);
  const controller = new OrderStatusController(controllerBase);

  const [orderAdvanceDialogModel, setOrderAdvanceDialogModel] = useState(new OrderAdvanceDialogModel());
  const orderAdvanceDialogController = new OrderAdvanceDialogController(orderAdvanceDialogModel, setOrderAdvanceDialogModel);
  controller.orderAdvanceDialogController = orderAdvanceDialogController;

  const [orderReverseDialogModel, setOrderReverseDialogModel] = useState(new OrderReverseDialogModel());
  const orderReverseDialogController = new OrderReverseDialogController(orderReverseDialogModel, setOrderReverseDialogModel);
  controller.orderReverseDialogController = orderReverseDialogController;

  const [orderReversePendingDialogModel, setOrderReversePendingDialogModel] = useState(new OrderReversePendingDialogModel());
  const orderReversePendingDialogController = new OrderReversePendingDialogController(orderReversePendingDialogModel, setOrderReversePendingDialogModel);
  controller.orderReversePendingDialogController = orderReversePendingDialogController;

  const [orderPendingDialogModel, setOrderPendingDialogModel] = useState(new OrderPendingDialogModel());
  const orderPendingDialogController = new OrderPendingDialogController(orderPendingDialogModel, setOrderPendingDialogModel);
  controller.orderPendingDialogController = orderPendingDialogController;

  return <div style={{ minWidth: 60 }}>

    <OrderAdvanceDialog controller={orderAdvanceDialogController} />
    <OrderReverseDialog controller={orderReverseDialogController} />
    <OrderReversePendingDialog controller={orderReversePendingDialogController} />
    <OrderPendingDialog controller={orderPendingDialogController} />

    <NoWrapControl>
      <div style={{ transform: 'rotate(90deg)', marginRight: 2 }}>
        <FuseSvgIcon className='text-48 cursor-pointer' size={20} color='success' onClick={() => controller.execReverseOrder()}>
          material-twotone:arrow_circle_down
        </FuseSvgIcon>
      </div>
      <img src='img/stopsign.png' className='cursor-pointer' width='20' onClick={() => controller.execPendingOrder()} />
      <div style={{ transform: 'rotate(-90deg)', marginLeft: 2 }}>
        <FuseSvgIcon className='text-48 cursor-pointer' size={20} color='success' onClick={() => controller.execAdvanceOrder()}>
          material-twotone:arrow_circle_down
        </FuseSvgIcon>
      </div>
    </NoWrapControl>
  </div>

}


