import Input from "@mui/material/Input";
import Paper from "@mui/material/Paper";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { motion } from "framer-motion";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const PSearchInput = props => {

  const { searchText } = props;

  return <Paper component={motion.div} initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    className="flex items-center w-full sm:max-w-400 space-x-8 px-16 rounded-full border-1 shadow-0">
    <FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>
    <Input
      placeholder="LastName or FirstName or Retailer or Email"
      className="flex flex-1"
      disableUnderline
      fullWidth
      value={searchText()}
      inputProps={{ "aria-label": "Search" }}
      onChange={e => searchText(e.target.value)}
    />
  </Paper>

}

