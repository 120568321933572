import { FormsBase } from "src/app/@core/forms/@formsBase";
import { isUoN, toMMDDYYYY } from "src/app/@core/common";

/**
 * 
 */
export class DialogModelBase {

  /**
   * 
   */
  constructor() {
    this.visible = false;
    this.resolve = null;
    this.page = 0;
    this.rowsPerPage = 25;
  }

}

/**
 * 
 */
export class DialogBase extends FormsBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
 * 
 * @param {*} val 
 * @returns 
 */
  page = val => {
    if (isUoN(val)) return this.model.page;
    this.updateModel(x => x.page = val);
  }

  /**
   * 
   * @param {*} val 
   * @returns 
   */
  rowsPerPage = val => {
    if (isUoN(val)) return this.model.rowsPerPage;
    this.updateModel(x => {
      x.page = 0;
      x.rowsPerPage = val;
    });
  }

  /**
   * 
   * @returns 
   */
  visible = () => this.model.visible;

  /**
   *
   * @returns
   */
  resolve(data) {
    if (!this.model.resolve) return;
    this.model.resolve(data);
  }

  /**
   * 
   * @param {*} func 
   */
  updateModel = func => {
    const model = { ...this.model };
    func(model);
    this.setModel(model);
  }

  /**
   * 
   * @param {*} data 
   */
  hide = data => {
    this.updateModel(x => { x.visible = false; });
    this.resolve(data);
  }

  /**
   * 
   * @param {*} data 
   */
  close = () => {
    this.updateModel(x => { x.visible = false; });
    this.resolve(false);
  }

  /**
   *
   * @returns
   */
  show(func) {
    return new Promise(resolve => this.updateModel(x => {
      if (func) func(x);
      x.visible = true;
      x.resolve = resolve;
      x.page = 0;
      x.rowsPerPage = 25;
    }));
  }

}