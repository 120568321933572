import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { SelectInput } from 'src/app/@core/forms/controls/selectInput';
import { DatePicker } from 'src/app/@core/forms/controls/datePicker';
import Button from '@mui/material/Button';
import FusePageCarded from 'src/app/@core/@fuse/FusePageCarded.FullWidth';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { ModuleContext, initialModuleData, moduleDataContext } from 'src/app/@core/@contexts/module.context';
import { NoWrapControl } from 'src/app/@core/forms/controls/noWrapControl';
import { comboLists } from "src/app/@domain/consts/comboLists";

export const ReportSalesByRetailerByDatePage = () => {

  const frmModel = useForm({ mode: 'onChange', defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);

  const { api, spinner, dropdown } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const criteriaModel = useForm({
    mode: 'onChange',
    defaultValues: {}
  });
  const { control, getValues } = criteriaModel;

  /**
   * 
   */
  const loadData = () => {
  }

  /**
   * 
   */
  const contextObj = useMemo(() => {

    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;

    return moduleDataContext

  }, [store]);

  /**
   * 
   */
  useEffect(() => {
    loadData();
    return () => { };
  }, [])

  /**
   * 
   */
  const onSearch = () => { }

  /**
   * 
   */
  const onExport = () => { }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const Header = props => {

    return (
      <div style={{ width: '100%' }}>
        <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between container">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-24 md:text-32 font-extrabold tracking-tight">
            Sales By Date By Retailer
          </Typography>
        </div>
        <hr />
        <div className="flex flex-row container" style={{ marginTop: 10 }}>
          <div style={{ flex: 1, display: 'flex' }}>
            <DatePicker id="startDate" label="Report Start Date" control={control} />
            &nbsp;
            <DatePicker id="endDate" label="Report End Date" control={control} />
            &nbsp;
            <SelectInput id="retailer" label="Retailers" control={control} items={comboLists.availablility} />
            &nbsp;
            <SelectInput id="shirtType" label="Shirt Types" control={control} items={comboLists.shirtTypes} />
          </div>
          <div style={{ width: 200 }}>
            <motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
              <NoWrapControl>
                <Button variant="contained" color="secondary" onClick={onSearch} style={{ marginTop: 7 }}>View Report</Button>
                &nbsp;
                <Button variant="contained" color="secondary" onClick={onExport} style={{ marginTop: 7 }}>Export To Excel</Button>
              </NoWrapControl>
            </motion.div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const Content = props => {

    return (
      <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32">
      </div>

    )
  }

  /**
   * 
   */
  return <ModuleContext.Provider value={contextObj}>
    <FusePageCarded
      header={<Header />}
      content={<Content />}
      scroll={isMobile ? 'normal' : 'content'
      }
    />
  </ModuleContext.Provider>

}

