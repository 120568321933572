import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl } from '@mui/base';
import { FormControlLabel, FormLabel, Radio, RadioGroup, Table, TableBody, TableRow, TableCell, TableHead, Alert } from '@mui/material';
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { NoDataFoundTable } from 'src/app/@core/forms/dataTable/noDataFound.table';

/**
 * 
 */
export class ConvertToTrialDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.shirts = [];
    this.cutTrial = false;
  }

}

/**
 * 
 */
export class ConvertToTrialDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   * @param {*} val 
   * @returns 
   */
  cutTrialChange = val => this.updateModel(x => x.cutTrial = val);

  /**
   * 
   * @param {*} trialShirt 
   */
  trialShirtChange = trialShirt => {
    this.updateModel(x => x.shirts.forEach(x => x.trial = x.itemID === trialShirt.itemID ? true : false));
  }

  /**
   * 
   */
  submit = async () => {

    const trialShirt = this.model.shirts.find(x => x.trial);
    if (!trialShirt) {
      this.messageBox().showError('Please select a Trial Shirt');
      return;
    }

    try {

      this.spinner().show();

      const result = await this.api().orderApi.convertToTrial(trialShirt);

      this.hide(result);
      return;


    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

    this.hide(false);

  }

  /**
   * 
   * @param {*} shirts 
   * @returns 
   */
  show = shirts => {
    shirts.forEach(x => x.trial = false);
    return super.show(x => { x.shirts = shirts; x.cutTrial = false; });
  }

};

/**
 *
 * @param {*} props
 * @returns
 */
export const ConvertToTrialDialog = props => {

  const { controller } = props;
  const { model, hide, cutTrialChange, trialShirtChange, submit } = controller;
  const { shirts, cutTrial } = model;

  /**
   * 
   * @returns 
   */
  const onClose = () => hide(null);


  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 1000, maxHeight: 600 }}
      open={controller.visible()}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title="Set Trial" onClose={onClose} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className='flex flex-row'>
          <Alert severity="warning" style={{ color: 'red' }}>
            The Production Status of this Order will be changed from No Trial to Trial.
            <br />
            Are you sure?
          </Alert>
        </div>
        <div className='flex flex-row'>
          <FormControl className='flex flex-row'>
            <FormLabel id='demo-row-radio-buttons-group-label' style={{ marginTop: 10, marginRight: 20 }}>
              Cut Trial
            </FormLabel>
            <RadioGroup row defaultValue='0' name='radio-buttons-group'>
              <FormControlLabel value='1' control={<Radio checked={cutTrial} onChange={() => cutTrialChange(true)} />} label='Yes' />
              <FormControlLabel value='0' control={<Radio checked={!cutTrial} onChange={() => cutTrialChange(false)} />} label='No' />
            </RadioGroup>
          </FormControl>
        </div>
        <div className='flex flex-col'>
          {cutTrial &&
            <div style={{ fontWeight: 600, margin: '10px 0 5px 0' }}>
              Please select trial shirt
            </div>
          }
          <div className='w-full flex flex-col min-h-full'>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  {cutTrial && <TableCell component='th' scope='row' align='center' style={{ width: 50 }}></TableCell>}
                  <TableCell component='th' scope='row' align='center' style={{ width: 50 }}>
                    Quantity
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Fabric ID
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Fabric ID Description
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Availability
                  </TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    Line Item Type
                  </TableCell>
                </TableRow>
              </TableHead>
              {shirts.length == 0 && <NoDataFoundTable colSpan={6} />}
              {
                shirts.length > 0 &&
                <TableBody>
                  {shirts.map((item, index) => (
                    <TableRow hover key={index}>
                      {cutTrial &&
                        <TableCell className='border-left cell-border-top cell-border-right' component='td' scope='row' align='center'>
                          <Radio checked={item.trial} onChange={() => trialShirtChange(item)} style={{padding: 0}} />
                        </TableCell>
                      }
                      <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='center'>
                        {item.newOnOrder}
                      </TableCell>
                      <TableCell
                        className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                        {item.fabricID}
                      </TableCell>
                      <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                        {item.description}
                      </TableCell>
                      <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                        {item.availability}
                      </TableCell>
                      <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                        {item.lineItemType}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              }
            </Table>
          </div>
          <div style={{ fontWeight: 600, margin: '10px 0 5px 0' }}>
            <div>
              New Shirts: {shirts.reduce((partialSum, obj) => partialSum + obj.newOnOrder, 0)}
            </div>
            <div>
              Repair Shirts: 0
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Cancel
        </Button>
        {
          cutTrial &&
          <Button variant="contained" color="secondary" onClick={submit}>
            Confirm
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};
