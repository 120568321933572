import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from 'src/app/@core/forms/controls/textArea';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import {
  DialogBase,
  DialogModelBase,
} from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { SelectInput } from 'src/app/@core/forms/controls/selectInput';
/**
 *
 */
export class NotesOrderEditAddDialogModel extends DialogModelBase {
  /**
   *
   */
  constructor() {
    super();
    this.order = { orderID: 0 };
    this.prodTypes = [];
    this.frmLocal = useForm({ mode: 'onChange', defaultValues: {} });
  }
}

/**
 *
 */
export class NotesOrderEditAddDialogController extends DialogBase {
  /**
   *
   * @param {*} model
   * @param {*} setModel
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   *
   * @param {*} frmVals
   */
  submit = async (frmVals) => {

    try {
      this.spinner().show();

      const dto = frmVals;
      dto.orderID = this.model.order.orderID;
      dto.productionID = `${dto.orderID}-${dto.production}`;
      if (!dto.noteDescription) dto.noteDescription = '';
      const result = await this.api().productionNoteApi.insertNote(dto);

      this.hide(result);
    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  };

  /**
   *
   * @param {*} orderEdit
   * @returns
   */
  show = (orderEdit) => {

    const order = orderEdit.order;
    const prodTypes = [... new Set(orderEdit.shirts.map(x => x.prodType))]
      .map(x => { return { id: x, name: x } });

    this.model.frmLocal.reset({});

    return super.show((x) => {
      x.order = order;
      x.prodTypes = prodTypes
      if (prodTypes.length === 1) this.model.frmLocal.setValue('production', prodTypes[0].id);
    });

  };

}

/**
 *
 * @param {*} props
 * @returns
 */
export const NotesOrderEditAddDialog = (props) => {

  const { controller } = props;
  const { frmLocal } = controller.model;
  const { control, handleSubmit } = frmLocal;

  /**
   *
   */
  const onSubmit = (data) => {
    controller.submit(data);
  };
  /**
   *
   */

  return (
    <Dialog
      fullScreen
      style={{ margin: 'auto', maxWidth: 500, maxHeight: 550 }}
      open={controller.visible()}
      onClose={controller.close}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>
        <DialogHeaderContent title='Add New Note' onClose={controller.close} />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className='dialog-form_inner'>
        <DialogContent dividers={true}>
          <FormProvider {...frmLocal}>
            <div className='flex -mx-4'>
              <SelectInput
                id='production'
                label='Production'
                control={control}
                items={controller.model.prodTypes}
                rules={{ required: 'Production is required' }}
              />
            </div>
            <div className='flex -mx-4'>
              <TextArea
                id='noteDescription'
                label='Notes'
                control={control}
                rows={13}
                rules={{ required: 'Notes is required' }}
              />
            </div>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={controller.close}
          >
            Cancel
          </Button>
          <Button variant='contained' color='secondary' type='submit'>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
