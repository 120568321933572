import { useContext } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput } from 'src/app/@core/forms/controls/textInput'
import { SelectInput } from 'src/app/@core/forms/controls/selectInput'
import { DomainContext } from 'src/app/@core/@contexts/domain.context'
import { ModuleContext } from 'src/app/@core/@contexts/module.context'
import { TextArea } from 'src/app/@core/forms/controls/textArea'
import {
  DialogBase,
  DialogModelBase,
} from 'src/app/@core/forms/dialog/@dialogBaseV2'
/**
 *
 */
export class ShirtsDeleteDialogModel extends DialogModelBase {
  /**
   *
   */
  constructor() {
    super()
    this.frmLocal = useForm({ mode: 'onChange', defaultValues: {} })
  }
}
/**
 *
 */
export class ShirtsDeleteDialogController extends DialogBase {
  /**
   *
   * @param {*} model
   * @param {*} setModel
   */
  constructor(model, setModel) {
    super(model, setModel)
  }

  /**
   *
   * @param {*} frmVals
   */
  submit = async (frmVals) => {
    try {
      this.spinner().show()
      const dto = frmVals.productionItemID
      const result = await this.api().orderApi.deleteShirt(dto)
      console.log(result)
      this.hide(result)
    } catch (err) {
      console.log(err)
    } finally {
      this.spinner().hide()
    }
  }

  /**
   *
   * @param {*} shirt
   * @returns
   */
  show = async (shirt) => {
    const dataShow = {
      productionItemID: shirt.productionItemID,
      fabricID: shirt.fabricID,
      fabricSwatch: shirt.fabricSwatch,
      fabricDescription: shirt.fabricDescription || '',
      lineItemType: shirt.lineItemType,
      prodTypeText: shirt.prodTypeText || '',
      description: shirt.description || '',
      alternativeDescription: shirt.alternativeDescription || '',
      newOnOrder: shirt.newOnOrder,
      newHolding: shirt.newHolding,
      availability: shirt.availability
    }
    this.model.frmLocal.reset(dataShow)
    return super.show()
  }
}
/**
 *
 * @param {*} props
 * @returns
 */
export const ShirstDeleteDialog = (props) => {
  const { controller } = props
  const { frmLocal } = controller.model
  const { control, getValues } = frmLocal
  const { fabricSwatch } = frmLocal.getValues()
  /**
   *
   */
  const onSubmit = async () => {
    controller.submit(getValues())
  }

  return (
    <Dialog
      fullScreen
      style={{ margin: 'auto', maxWidth: 700, maxHeight: 700 }}
      open={controller.visible()}
      onClose={controller.close}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="w-full flex flex-row">
          <div>Delete Shirt</div>
          <div
            className="danger"
            style={{
              flex: 1,
              fontSize: '0.65em',
              alignSelf: 'center',
              textAlign: 'right',
            }}
          >
            This record cannot be recovered
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <FormProvider {...frmLocal}>
          <div className="w-full flex flex-col">
            <div className="flex flex-row">
              <div style={{ width: '47%' }}>
                <TextInput id="fabricID" label="Fabric ID" control={control} disabled />
                <TextArea
                  id="fabricDescription"
                  label="Fabric Description"
                  control={control}
                  rows={6}
                  disabled
                />
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                <img src={fabricSwatch} width={80} />
              </div>
            </div>
            <div className="w-full flex flex-row">
              <TextInput id="lineItemType" label="Shirt Type" control={control} disabled />
              <TextInput id="prodTypeText" label="Production Type" control={control} disabled />

            </div>
            <div className="flex flex-row">
              <TextArea
                id="description"
                label="Description"
                control={control}
                rows={6}
                disabled
              />
              <TextArea
                id="alternativeDescription"
                label="Alternative Description"
                control={control}
                rows={6}
                disabled
              />
            </div>
            <div className="flex flex-row">
              <TextInput
                id="newOnOrder"
                label="Ordered Quanlity"
                control={control}
                disabled
              />
              <TextInput id="newHolding" label="Holding" control={control} disabled />
            </div>
            <div className="flex flex-row">
              <TextInput
                id="availability"
                label="Availability"
                control={control}
                disabled
              />
            </div>
          </div>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={controller.close}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={onSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
