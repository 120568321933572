import { APIBase } from './@base.api'

export class OrderApi extends APIBase {
  /**
   *
   */
  constructor(context) {
    super(context)
    this.endPoints = {
      get: 'Order/Get',
      getProductionLog: 'Order/GetProductionLog',
      advanceWorkflow: 'Order/AdvanceWorkflow',
      reverseWorkflow: 'Order/ReverseWorkflow',
      createOrder: 'Order/CreateOrder',
      getEditOrder: 'Order/GetEditOrder',
      updateShirtUser: 'Order/UpdateShirtUser',
      updateRetailer: 'Order/UpdateRetailer',
      updateSalesperson: 'Order/UpdateSalesperson',
      updateReceivedDate: 'Order/UpdateReceivedDate',
      getShirts: 'Order/getShirts',
      searchProductionsByOrderID: 'Order/SearchProductionsByOrderID',
      convertToNoTrial: 'Order/ConvertToNoTrial',
      convertToTrial: 'Order/ConvertToTrial',
      cancelOrder: 'Order/CancelOrder',
      getProdActivities: 'Order/GetProdActivities',
      updateProdActivity: 'Order/UpdateProdActivity',
      getEditOrderShirts: 'Order/GetEditOrderShirts',
      addFabricsToOrder: 'Order/AddFabricsToOrder',
      canAdvance: 'Order/CanAdvance',
      canReverse: 'Order/CanReverse',
      advanceOrder: 'Order/AdvanceOrder',
      reverseOrder: 'Order/reverseOrder',
      updateShirt: 'Order/UpdateShirt',
      deleteShirt: 'Order/DeleteShirt',
      canPendingOrder: 'Order/CanPendingOrder',
      pendingOrder: 'Order/PendingOrder',
      canReversePendingOrder: 'Order/CanReversePendingOrder',
      reversePendingOrder: 'Order/ReversePendingOrder',
      getProdByOrderID: 'Order/OrderGetProdByOrderID',
    }
  }

  /**
   *
   * @param {*} data
   * @returns
   */
  getProdByOrderID = data => this.httpPost(this.endPoints.getProdByOrderID, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  reversePendingOrder = data => this.httpPost(this.endPoints.reversePendingOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  canReversePendingOrder = data => this.httpPost(this.endPoints.canReversePendingOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  reverseOrder = data => this.httpPost(this.endPoints.reverseOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  advanceOrder = data => this.httpPost(this.endPoints.advanceOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  canReverse = data => this.httpPost(this.endPoints.canReverse, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  canAdvance = data => this.httpPost(this.endPoints.canAdvance, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  addFabricsToOrder = data =>
    this.httpPost(this.endPoints.addFabricsToOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  getEditOrderShirts = data =>
    this.httpPost(this.endPoints.getEditOrderShirts, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  canPendingOrder = data =>
    this.httpPost(this.endPoints.canPendingOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  pendingOrder = data => this.httpPost(this.endPoints.pendingOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  cancelOrder = data => this.httpPost(this.endPoints.cancelOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  convertToTrial = data => this.httpPost(this.endPoints.convertToTrial, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  convertToNoTrial = data =>
    this.httpPost(this.endPoints.convertToNoTrial, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  getShirts = data => this.httpPost(this.endPoints.getShirts, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  updateShirtUser = data =>
    this.httpPost(this.endPoints.updateShirtUser, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  updateRetailer = data => this.httpPost(this.endPoints.updateRetailer, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  updateSalesperson = data => this.httpPost(this.endPoints.updateSalesperson, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  updateReceivedDate = data => this.httpPost(this.endPoints.updateReceivedDate, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  getEditOrder = data => this.httpPost(this.endPoints.getEditOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  createOrder = data => this.httpPost(this.endPoints.createOrder, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  reverseWorkflow = data =>
    this.httpPost(this.endPoints.reverseWorkflow, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  advanceWorkflow = data =>
    this.httpPost(this.endPoints.advanceWorkflow, data)

  /**
   *
   * @param {*} data
   * @returns
   */
  searchProductionsByOrderID = data =>
    this.httpPost(this.endPoints.searchProductionsByOrderID, data)

  /**
   *
   * @returns
   */
  getProductionLog = () => this.httpPost(this.endPoints.getProductionLog, null)

  /**
   *
   * @returns  {*} orderId
   */
  getProdActivities = (orderId) =>
    this.httpPost(this.endPoints.getProdActivities, orderId)
  /**
   *
   * @returns  {*} orderId
   */
  updateProdActivity = data =>
    this.httpPost(this.endPoints.updateProdActivity, data)
  /**
   *
   * @param {*} data
   */
  updateShirt = data => this.httpPost(this.endPoints.updateShirt, data)

  /**
   *
   * @param {*} id
   */
  deleteShirt = (id) => this.httpPost(this.endPoints.deleteShirt, id)
}
