import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import FusePageCarded from '@fuse/core/FusePageCarded/FusePageCarded';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { ModuleContext, initialModuleData, moduleDataContext } from 'src/app/@core/@contexts/module.context';

export const AdminResetInventoryPage = () => {

  const frmModel = useForm({ mode: 'onChange', defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);

  /**
   * 
   */
  const loadData = () => {
  }

  /**
   * 
   */
  const contextObj = useMemo(() => {

    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;

    return moduleDataContext

  }, [store]);

  const { api, spinner } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  /**
   * 
   */
  useEffect(() => {
    loadData();
    return () => { };
  }, [])

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const Header = props => {

    return (
      <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32">
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="text-24 md:text-32 font-extrabold tracking-tight">
          Reset Online Inventory
        </Typography>
      </div>

    )
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const Content = props => {

    return (
      <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32">
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
          <div>
            <Button variant="contained" color="secondary">
              Select file
              <input hidden accept="image/*" multiple type="file" />
            </Button>
          </div>
          <br />
          <div>
            <Button variant="contained" color="secondary">
              Upload and Update
              <input hidden accept="image/*" multiple type="file" />
            </Button>
          </div>
        </motion.div>
      </div>

    )
  }

  /**
   * 
   */
  return <ModuleContext.Provider value={contextObj}>
    <FusePageCarded
      header={<Header />}
      content={<Content />}
      scroll={isMobile ? 'normal' : 'content'
      }
    />
  </ModuleContext.Provider>

}

