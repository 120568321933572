import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormProvider, useForm } from "react-hook-form";
import { TextArea } from "src/app/@core/forms/controls/textArea";
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';

/**
 * 
 */
export class NotesAddDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.order = null;
    this.frmLocal = useForm({ mode: 'onChange', defaultValues: {} });

  }

}

/**
 * 
 */
export class NotesAddDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   * @param {*} frmVals 
   */
  submit = async frmVals => {

    try {

      this.spinner().show();

      const dto = frmVals;
      dto.orderID = this.model.order.orderID;
      dto.productionID = this.model.order.productionID;
      if (!dto.noteDescription) dto.noteDescription = '';
      console.log(dto, this.model);
      const result = await this.api().productionNoteApi.insertNote(dto);

      this.hide(result);

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

  /**
   * 
   * @param {*} order 
   * @returns 
   */
  show = order => {
    this.model.frmLocal.reset({});
    return super.show(x => x.order = order);
  }

};

/**
 *
 * @param {*} props
 * @returns
 */
export const NotesAddDialog = props => {

  const { controller } = props;
  const { frmLocal } = controller.model;
  const { control, getValues, handleSubmit } = frmLocal;

  /**
   *
   */
  const onSubmit = () => {
    controller.submit(getValues());
  };

  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 500, maxHeight: 450 }}
      open={controller.visible()}
      onClose={controller.hide}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title="Add New Note" onClose={controller.hide} />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className="dialog-form_inner">
        <DialogContent dividers={true}>
          <FormProvider {...frmLocal}>
            <div>
              <div className="flex -mx-4">
                <TextArea id="noteDescription" label="Notes" control={control} rows={13} rules={{ required: "Notes is required" }} />
              </div>
            </div>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={controller.hide}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
