import { useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Paper, Table, TableBody, TablePagination } from "@mui/material";
import { motion } from "framer-motion";
import { THead } from "src/app/@core/forms/dataTable/dataTable.control";
import { NoDataFound } from "src/app/@core/forms/controls/noDataFound";
import { ModuleContext } from "src/app/@core/@contexts/module.context";
import { isUoN, isUNE, stableSort } from "src/app/@core/common";
import { EmptyRowTable } from "../dataTable/emptyRow.table";

/**
 *
 * @param {*} props
 * @returns
 */
export const ReportHeader = props => {

  const { title, reportCriteria, onSearch } = props;

  return <div style={{ width: "100%" }}>
    <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between container">
      <Typography
        component={motion.span}
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="text-24 md:text-32 font-extrabold tracking-tight"
      >
        {title}
      </Typography>
    </div>
    {
      reportCriteria &&
      <div style={{ marginBottom: 10 }}>
        <hr />
        <div className="flex flex-row container" style={{ marginTop: 10 }}>
          {reportCriteria}
          <div style={{ width: 200 }}>
            <motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
              <Button variant="contained" color="secondary" onClick={onSearch} style={{ marginTop: 7 }}>
                View Report
              </Button>
            </motion.div>
          </div>
        </div>
      </div>
    }
  </div>

};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const ReportBody = props => {

  const { columns, gvItems, onExport, ReportRow } = props;
  const { page, rowsPerPage, } = useContext(ModuleContext);
  const [sortOrder, setSortOrder] = useState({ direction: 'asc', id: null });

  const handleChangePage = (event, newPage) => page(newPage);
  const handleChangeRowsPerPage = (event) => rowsPerPage(event.target.value);

  /**
   * 
   * @param {*} prop 
   */
  const onSort = prop => {
    const direction = (sortOrder.id === prop && sortOrder.direction === 'desc') ? 'asc' : 'desc';
    setSortOrder({ direction, id: prop });
  }

  if (isUoN(gvItems) || gvItems.length === 0) return <NoDataFound />;

  return (
    <div className="w-full flex flex-col min-h-full">
      <Paper sx={{ width: "100%", mb: 2, boxShadow: 'none !important' }}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" color="secondary" onClick={onExport} className="btn-export"   >
            Export to Excel
          </Button>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={isUNE(gvItems) ? 0 : gvItems.length}
            rowsPerPage={rowsPerPage()}
            page={page()}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <Table stickyHeader className="min-w-xl" size="small" aria-labelledby="tableTitle">
          <THead columns={columns} sortOrder={sortOrder} onSort={onSort} />
          <TableBody>
            {
              stableSort(gvItems, sortOrder.id, sortOrder.direction)
                .slice(page() * rowsPerPage(), page() * rowsPerPage() + rowsPerPage())
                .map(item => <ReportRow key={item.itemID} item={item} />)
            }
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const ReportDialogBody = props => {

  const { columns, gvItems, onExport, ReportRow, controller } = props;
  const { page, rowsPerPage, } = controller;
  const [sortOrder, setSortOrder] = useState({ direction: 'asc', id: null });

  /**
   * 
   * @param {*} event 
   * @param {*} newPage 
   * @returns 
   */
  const handleChangePage = (event, newPage) => page(newPage);

  /**
   * 
   * @param {*} event 
   * @returns 
   */
  const handleChangeRowsPerPage = (event) => rowsPerPage(event.target.value);

  /**
   * 
   * @param {*} prop 
   */
  const onSort = prop => {
    const direction = (sortOrder.id === prop && sortOrder.direction === 'desc') ? 'asc' : 'desc';
    setSortOrder({ direction, id: prop });
  }

  if (isUoN(gvItems) || gvItems.length === 0) return <NoDataFound />;

  return (
    <div className="w-full flex flex-col min-h-full">
      <Paper sx={{ width: "100%", mb: 2, boxShadow: 'none !important' }}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" color="secondary" onClick={onExport} className="btn-export"   >
            Export to Excel
          </Button>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={isUNE(gvItems) ? 0 : gvItems.length}
            rowsPerPage={rowsPerPage()}
            page={page()}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <Table stickyHeader className="min-w-xl" size="small" aria-labelledby="tableTitle">
          <THead columns={columns} sortOrder={sortOrder} onSort={onSort} />
          <TableBody>
            {
              stableSort(gvItems, sortOrder.id, sortOrder.direction)
                .slice(page() * rowsPerPage(), page() * rowsPerPage() + rowsPerPage())
                .map(item => <ReportRow key={item.itemID} item={item} />)
            }
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const ReportBodyOrderSummary = props => {

  const { columns, gvItems, onExport, ReportRow, ReportSummaryRow } = props;
  const { page, rowsPerPage, } = useContext(ModuleContext);
  const [sortOrder, setSortOrder] = useState({ direction: 'asc', id: null });

  /**
   * 
   * @param {*} event 
   * @param {*} newPage 
   * @returns 
   */
  const handleChangePage = (event, newPage) => page(newPage);

  /**
   * 
   * @param {*} e 
   */
  const handleChangeRowsPerPage = e => rowsPerPage(e.target.value);
  
  /**
   * 
   * @param {*} prop 
   */
  const onSort = prop => {
    const direction = (sortOrder.id === prop && sortOrder.direction === 'desc') ? 'asc' : 'desc';
    setSortOrder({ direction, id: prop });
  }

  if (isUoN(gvItems) || gvItems.length === 0) return <NoDataFound />;

  let totalPages = parseInt(gvItems.length / rowsPerPage());
  if (gvItems.length % rowsPerPage() > 0) totalPages++;

  return (
    <div className="w-full flex flex-col min-h-full">
      <Paper sx={{ width: "100%", mb: 2, boxShadow: 'none !important' }}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" color="secondary" onClick={onExport} className="btn-export"   >
            Export to Excel
          </Button>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={isUNE(gvItems) ? 0 : gvItems.length}
            rowsPerPage={rowsPerPage()}
            page={page()}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <Table stickyHeader className="min-w-xl" size="small" aria-labelledby="tableTitle">
          <THead columns={columns} sortOrder={sortOrder} onSort={onSort} />
          <TableBody>
            {
              stableSort(gvItems, sortOrder.id, sortOrder.direction)
                .slice(page() * rowsPerPage(), page() * rowsPerPage() + rowsPerPage())
                .map(item => <ReportRow key={item.itemID} item={item} />)
            }
            {totalPages - 1 === page() && <ReportSummaryRow />}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const ReportBodyDailyReceivedCutComplete = props => {

  const { columns, gvItems, onExport, ReportRow,
    DailyReportSummary, DailyReportSummaryPercent, dailySummaryItems
  } = props;
  const { page, rowsPerPage, } = useContext(ModuleContext);
  const [sortOrder, setSortOrder] = useState({ direction: 'asc', id: null });

  const handleChangePage = (event, newPage) => page(newPage);
  const handleChangeRowsPerPage = (event) => rowsPerPage(event.target.value);

  /**
   * 
   * @param {*} prop 
   */
  const onSort = prop => {
    const direction = (sortOrder.id === prop && sortOrder.direction === 'desc') ? 'asc' : 'desc';
    setSortOrder({ direction, id: prop });
  }

  if (isUoN(gvItems) || gvItems.length === 0) return <NoDataFound />;

  return (
    <div className="w-full flex flex-col min-h-full">
      <Paper sx={{ width: "100%", mb: 2, boxShadow: 'none !important' }}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" color="secondary" onClick={onExport} className="btn-export"   >
            Export to Excel
          </Button>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={isUNE(gvItems) ? 0 : gvItems.length}
            rowsPerPage={rowsPerPage()}
            page={page()}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <Table stickyHeader className="min-w-xl" size="small" aria-labelledby="tableTitle">
          <THead columns={columns} sortOrder={sortOrder} onSort={onSort} />
          <TableBody>
            {dailySummaryItems && <DailyReportSummary item={dailySummaryItems} />}
            {dailySummaryItems && <DailyReportSummaryPercent item={dailySummaryItems} />}
            {dailySummaryItems && <EmptyRowTable colSpan={16} />}
            {
              stableSort(gvItems, sortOrder.id, sortOrder.direction)
                .slice(page() * rowsPerPage(), page() * rowsPerPage() + rowsPerPage())
                .map(item => <ReportRow key={item.itemID} item={item} />)
            }
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};
