import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import { motion } from 'framer-motion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon';
import FusePageCarded from 'src/app/@core/@fuse/FusePageCarded.FullWidth';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { ModuleContext, initialModuleData, moduleDataContext } from 'src/app/@core/@contexts/module.context';
import { isUoN, isUNE, stableSort, toMMDDYYYY } from 'src/app/@core/common';
import { NoDataFound } from 'src/app/@core/forms/controls/noDataFound';
import { OrderEditDialog, OrderEditDialogController, OrderEditDialogModel, } from './edit/@order.edit.dialog';
import { NotesDialog, NotesDialogController, NotesDialogModel, } from 'src/app/@domain/modules/note/@notes.dialog';
import { TBodyCell, THead, THeadWithFilter } from "src/app/@core/forms/dataTable/dataTable.control";
import { prodStatusConst } from 'src/app/@domain/consts/prodStatus.const';
import { ProductionLogStatusControl } from './@orderStatus.controller';

/**
 * 
 * @returns 
 */
export const OrdersPage = props => {

  const { isCreateOrder, isEditOrder, orderID } = props;
  const navigate = useNavigate();
  const frmModel = useForm({ mode: 'onChange', defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);

  const { api, spinner, orderAddDialogController } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [searchCriteria, setSearchCriteria] = useState(0);
  const [searchText, setSearchText] = useState('');

  const [orderEditDialogModel, setOrderEditDialogModel] = useState(new OrderEditDialogModel());
  const orderEditDialogController = new OrderEditDialogController(orderEditDialogModel, setOrderEditDialogModel);

  const frmFilter = useForm({ mode: 'onChange', defaultValues: { orderID: { value: '', ref: null, focus: false } } });

  /**
   * 
   * @param {*} orderID 
   */
  const loadData = async orderID => {

    setGvItems([]);
    moduleDataContext.moduleGvData([]);

    if (isCreateOrder || isEditOrder) return;

    try {

      spinner.show();

      const apiFunc = orderID === 0 ? api.orderApi.getProductionLog(orderID) : api.orderApi.searchProductionsByOrderID(orderID);
      const result = await apiFunc;
      setGvItems(result);
      moduleDataContext.moduleGvData(result);

    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  }

  /**
   * 
   * @param {*} item 
   * @param {*} text 
   * @returns 
   */
  const executeItemFilter = (item, text) => {
    let id = false;
    if (!isUoN(item.orderID)) id = item.orderID.toString().includes(text);
    return id;
  }

  /**
   * 
   * @param {*} orderID 
   */
  const refreshOrder = async orderID => {

    try {

      spinner.show();

      const items = [...gvItems];
      const order = await api.orderApi.getProdByOrderID(orderID);
      const idx = items.findIndex(x => x.orderID == orderID);
      Object.assign(items[idx], order);
      setGvItems(items);

    } catch (err) { console.log(err); }
    finally { spinner.hide(); }
  }

  /**
   * 
   */
  const contextObj = useMemo(() => {

    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;
    moduleDataContext.executeItemFilterFunc = executeItemFilter;
    moduleDataContext.searchCriteria = searchCriteria;
    moduleDataContext.setSearchCriteria = setSearchCriteria;
    moduleDataContext.setGvItems = setGvItems;
    moduleDataContext.spinner = spinner;
    moduleDataContext.refreshItem = refreshOrder;

    return moduleDataContext

  }, [store]);

  /**
   * 
   */
  useEffect(() => {

    loadData(0);

    if (isCreateOrder) executeCreateOrder();
    else if (isEditOrder) executeEditOrder(orderID);

    return () => { };

  }, [])

  /**
   * 
   */
  useEffect(() => {
    const subscription = frmFilter.watch(values => executeWatchFilters(values));
    return () => subscription.unsubscribe();
  }, [frmFilter.watch]);

  /**
   * 
   * @param {*} values 
   */
  const executeWatchFilters = values => {

    const items = moduleDataContext.moduleGvData();
    const orderID = values.orderID.value;
    if (orderID.toString().length !== 0) {

      const filtered = items.filter(x => {
        if (!x) return false;
        let hasOrderID = false;
        if (orderID) hasOrderID = x.orderID.toString().includes(orderID);
        return hasOrderID;
      });

      setGvItems(filtered);
      moduleDataContext.page(0);

    } else {
      setGvItems(items);
    }

  }

  /**
   *
   */
  const executeCreateOrder = async () => {
    const newOrder = await orderAddDialogController.show();
    if (!newOrder) navigate("/");
    else onEditOrder(newOrder);
  };

  /**
   *
   * @param {*} orderID
   */
  const executeEditOrder = async orderID => {
    const result = await orderEditDialogController.show(orderID);
    navigate("/");

  };

  /**
   *
   * @param {*} item
   */
  const onEditOrder = async item => {
    navigate(`/editOrder?orderID=${item.orderID}`)
  };


  /**
   *
   * @param {*} props
   * @returns
   */
  const ProductionLogHeader = props => {

    const { gvItems, searchText, setSearchText, onAdd } = props;
    const { api, spinner, orderAddDialogController } = useContext(DomainContext);
    const { page, rowsPerPage, loadModelDataFunc, setSearchCriteria } = useContext(ModuleContext);

    /**
     * 
     * @param {*} event 
     * @param {*} value 
     * @returns 
     */
    const onChangePage = (event, value) => page(value);

    /**
     * 
     * @param {*} event 
     * @returns 
     */
    const onChangeRowsPerPage = event => rowsPerPage(event.target.value);

    /**
     * 
     */
    const onSearch = async () => {

      try {

        frmFilter.reset();

        spinner.show();

        const id = searchText.trim();
        let orderID = 0
        if (id.length > 0) {
          if (isNaN(+id)) orderID = -1;
          else orderID = parseInt(id);
        }

        if (orderID < 0) return;
        setSearchCriteria(orderID);
        await loadModelDataFunc(orderID);

      } catch (err) { console.log(err); }
      finally { spinner.hide(); }

    }

    /**
     * 
     */
    const searchCss = {
      display: 'flex',
      backgroundColor: '#e3e3e3',
      paddingLeft: 10,
      paddingRight: 20,
      height: 40,
      marginRight: -20,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    }

    return (
      <div style={{ width: '100%' }}>
        <div className='flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between container'>
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className='text-24 md:text-32 font-extrabold tracking-tight'
          >
            Production Log
          </Typography>
        </div>
        <hr />
        <div className='flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 py-5 flex-1 items-center justify-center space-x-8'>
          <Paper
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className='flex items-center w-full sm:max-w-400 space-x-8 px-16 rounded-full border-1 shadow-0'
            style={{ overflow: 'hidden' }}>
            <Input
              placeholder='OrderID'
              className='flex flex-1'
              disableUnderline
              fullWidth
              value={searchText}
              inputProps={{ 'aria-label': 'Search', }}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div style={searchCss} onClick={onSearch}>
              <FuseSvgIcon color='disabled'>heroicons-solid:search</FuseSvgIcon>
              <span style={{ marginLeft: 5 }}>Search</span>
            </div>
          </Paper>
        </div>
      </div>
    );
  };

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ProductionLogBody = props => {

    const { gvItems, onEditOrder } = props;
    const { page, searchText, rowsPerPage, searchCriteria, executeDataFilterFunc, loadModelDataFunc } = useContext(ModuleContext);
    const [sortOrder, setSortOrder] = useState({ direction: 'desc', id: 'orderID' });

    const [notesDialogModel, setNotesDialogModel] = useState(new NotesDialogModel());
    const notesDialogController = new NotesDialogController(notesDialogModel, setNotesDialogModel);

    /**
     *
     * @param {*} item
     */
    const onViewNotes = async (item) => {
      await notesDialogController.show(item);
    };

    /**
     * 
     * @param {*} item 
     * @returns 
     */
    const getPendingColor = item => item.prodStatusID === prodStatusConst.Pending
      || item.prodStatusID === prodStatusConst.PendingApproval ? 'error' : 'success';

    // if (isUoN(gvItems) || gvItems.length === 0) return <NoDataFound />;
    if (!gvItems) return <NoDataFound />;

    const columns = [
      { id: 'eidt', align: 'center', disablePadding: false, sort: false, minWidth: 30 },
      { id: 'statusAction', align: 'center', disablePadding: false, sort: false, minWidth: 75 },
      { id: 'statusIcon', align: 'center', disablePadding: false, sort: false, minWidth: 30 },
      { id: 'orderID', align: 'left', disablePadding: false, label: 'Order ID', sort: true, minWidth: 100 },
      { id: 'lastName', align: 'left', disablePadding: false, label: 'Last Name', sort: true, minWidth: 0 },
      { id: 'firstName', align: 'left', disablePadding: false, label: 'First Name', sort: true, minWidth: 0 },
      { id: 'retailerName', align: 'left', disablePadding: false, label: 'Retailer Name', sort: true, minWidth: 0 },
      { id: 'newShirts', align: 'left', disablePadding: false, label: 'New Shirts', sort: true, minWidth: 100 },
      { id: 'repairShirts', align: 'left', disablePadding: false, label: 'Repair Shirts', sort: true, minWidth: 100 },
      { id: 'receiveDate', align: 'left', disablePadding: false, label: 'Production Receive Date', sort: true, minWidth: 100 },
      { id: 'prodDescText', align: 'left', disablePadding: false, label: 'Production Type', sort: true, minWidth: 100 },
      { id: 'prodStatusText', align: 'left', disablePadding: false, label: 'Production Status', sort: true, minWidth: 100 },
      { id: 'lastStatusUpdateDate', align: 'left', disablePadding: false, label: 'Updated Date', sort: true, minWidth: 100 },
      { id: 'cleanersDate', align: 'left', disablePadding: false, label: 'Clean Date', sort: true, minWidth: 100 },
      { id: 'shipDate', align: 'left', disablePadding: false, label: 'Ship Date', sort: true, minWidth: 100 },
      { id: 'arriveDate', align: 'left', disablePadding: false, label: 'Arrive Date', sort: true, minWidth: 100 },
      { id: 'note', align: 'center', disablePadding: false, sort: false, minWidth: 30 },
    ];

    /**
     * 
     * @param {*} propName 
     */
    const onSort = propName => {
      const direction = (sortOrder.id === propName && sortOrder.direction === 'desc') ? 'asc' : 'desc';
      setSortOrder({ direction, id: propName });
    }

    /**
     * 
     * @param {*} props 
     * @returns 
     */
    const DataTableRow = props => {

      const { item } = props;

      return <TableRow hover tabIndex={-1} >
        <TBodyCell value={<FuseSvgIcon className='text-48 cursor-pointer' size={20} color='action' onClick={() => onEditOrder(item)}>material-solid:edit</FuseSvgIcon>} />
        <TBodyCell value={<ProductionLogStatusControl item={item} />} />
        <TBodyCell value={<FuseSvgIcon className='text-48' size={16} color={getPendingColor(item)}>material-solid:circle</FuseSvgIcon>} />
        <TBodyCell align="center" value={item.orderID} />
        <TBodyCell align="left" value={item.lastName} />
        <TBodyCell align="left" value={item.firstName} />
        <TBodyCell align="left" value={item.retailerName} />
        <TBodyCell align="center" value={item.newShirts} />
        <TBodyCell align="center" value={item.repairShirts} />
        <TBodyCell align="center" value={toMMDDYYYY(item.receiveDate)} />
        <TBodyCell align="left" value={item.prodDescText} />
        <TBodyCell align="left" value={item.prodStatusText} />
        <TBodyCell align="center" value={toMMDDYYYY(item.lastStatusUpdateDate)} />
        <TBodyCell align="center" value={toMMDDYYYY(item.cleanersDate)} />
        <TBodyCell align="center" value={toMMDDYYYY(item.shipDate)} />
        <TBodyCell align="center" value={toMMDDYYYY(item.arriveDate)} />
        <TBodyCell value=
          {
            <FuseSvgIcon className='text-48 cursor-pointer' size={20} color='action' onClick={() => onViewNotes(item)}>
              material-solid:sticky_note_2
            </FuseSvgIcon>
          }
        />
      </TableRow>
    }

    return (
      <div className='w-full flex flex-col min-h-full'>

        <OrderEditDialog controller={orderEditDialogController} />
        <NotesDialog controller={notesDialogController} />

        <Table stickyHeader className='min-w-xl' size='small'>
          <THead columns={columns} sortOrder={sortOrder} onSort={onSort} frmFilter={frmFilter} />
          <TableBody>
            {
                gvItems.slice(0, 500)
                .map((item) => <DataTableRow key={item.itemID} item={item} />)
            }
          </TableBody>
        </Table>
      </div>
    );
  };

  /**
   * 
   */
  return <ModuleContext.Provider value={contextObj}>
    <FusePageCarded
      header={<ProductionLogHeader gvItems={gvItems} searchText={searchText} setSearchText={setSearchText} onAdd={executeCreateOrder} />}
      content={<ProductionLogBody gvItems={gvItems} setGvItems={setGvItems} onEditOrder={onEditOrder} />}
      scroll={isMobile ? 'normal' : 'normal'
      }
    />
  </ModuleContext.Provider>

}

