import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import FusePageCarded from '@fuse/core/FusePageCarded/FusePageCarded';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { ModuleContext, initialModuleData, moduleDataContext } from 'src/app/@core/@contexts/module.context';
import { TextInput } from 'src/app/@core/forms/controls/textInput';
import { NoWrapControl } from 'src/app/@core/forms/controls/noWrapControl';
import { isUoN } from 'src/app/@core/common';

export const AdminUpdateInventoryPage = () => {

  const dispatch = useDispatch();
  const frmModel = useForm({ mode: 'onChange', defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);

  const { api, spinner, messageBox} = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const hiddenFileInput = useRef(null);

  /**
   * 
   */
  const loadData = () => {
  }

  /**
   * 
   */
  const contextObj = useMemo(() => {

    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;

    return moduleDataContext

  }, [store]);

  /**
   * 
   */
  useEffect(() => {
    loadData();
    return () => { };
  }, [])

  /**
   * 
   * @param {*} event 
   */
  const onFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    setFile(file);
  };

  /**
   * 
   * @returns 
   */
  const onSubmit = async () => {

    if (isUoN(file)) {
      messageBox.showError('Please select an XLSX import file.')
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    console.log(formData);

    try {
      spinner.show();
      await api.inventoryApi.updateOnlineInventory(formData);
      dispatch(showMessage({ message: `The online inventory has been updated.` }));
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  }

  const onSelectFile = () => {
    hiddenFileInput.current.click();
  };

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const Header = () => {

    return (
      <div style={{ width: "100%" }}>
        <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between container">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-24 md:text-32 font-extrabold tracking-tight"
          >
            Update Online Inventory
          </Typography>
        </div>
        {
          <div style={{ marginBottom: 10 }}>
            <hr />
            <div className="flex flex-row container" style={{ marginTop: 10 }}>
              <div style={{ flex: 1, display: "flex" }}>
                <TextInput value={fileName} disabled={true} />
              </div>
              <div style={{ width: 250, paddingTop: 7, textAlign: 'right' }}>
                <motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
                  <NoWrapControl>
                    <input type="file" onChange={onFileChange} accept=".xlsx" ref={hiddenFileInput} style={{ display: "none" }} />
                    <Button variant="contained" color="secondary" onClick={onSelectFile}>
                      Select File
                    </Button>
                    &nbsp;
                    <Button variant="contained" color="secondary" onClick={onSubmit}>
                      Upload and Update
                    </Button>
                  </NoWrapControl>
                </motion.div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const Content = () => {

    return (
      <div className="space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32">
        <div>
          This feature is used to update Online Inventory values. You must upload an Excel 2007 file with a Sheet named Fabric Received with the following columns: Fabric ID, Meters On Hand.
        </div>
      </div>
    )
  }

  /**
   * 
   */
  return <ModuleContext.Provider value={contextObj}>
    <FusePageCarded
      header={<Header />}
      content={<Content />}
      scroll={isMobile ? 'normal' : 'content'
      }
    />
  </ModuleContext.Provider>

}

