import { useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FuseUtils from '@fuse/utils/FuseUtils';
import { DialogBaseV1 } from 'src/app/@core/forms/dialog/@dialogBaseV1';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { TextInput } from 'src/app/@core/forms/controls/textInput';
import { TextArea } from 'src/app/@core/forms/controls/textArea';
import { isUoN } from 'src/app/@core/common';

export class ShirtUserAddDialogController extends DialogBaseV1 {
  /**
   *
   * @param {*} context
   */
  constructor(context) {
    super(context, 'shirtUserAddDialogDataObj');
  }

  items = () => this.model().items;

  /**
   *
   */
  hide = (data) => {
    const store = { ...this.store() };
    store.shirtUserAddDialogDataObj.visible = false;
    this.context.setStore(store);
    this.resolve(data);
  };

  /**
   *
   * @param {*} title
   * @param {*} message
   * @returns
   */
  show = async () => {
    try {
      this.spinner().show();

      const store = { ...this.store() };
      this.context.setStore(store);

      return super.show();
    } catch (err) {
      console.log(err);
    } finally {
      this.spinner().hide();
    }
  };
  getRetailers = () => {
    const store = { ...this.store() };
    return store.orderAddDialogDataObj.retailers;
  };
}

/**
 *
 * @param {*} props
 * @returns
 */
export const ShirtUserAddDialog = (props) => {
  const { shirtUserAddDialogController, api, spinner } =
    useContext(DomainContext);
  const frmModel = useForm({ mode: 'onChange', defaultValues: {} });
  const { control, getValues, handleSubmit, reset } = frmModel;
  /**
   *
   */
  const onClose = (val) => {
    if (val) {
      shirtUserAddDialogController.hide(val);
    }
    reset();
    shirtUserAddDialogController.hide(null);
  };

  /**
   *
   */
  const onSubmit = async () => {
    let result = null;

    try {
      spinner.show();

      const obj = getValues();
      console.log(obj);
      if (!isUoN(obj.retailer)) obj.retailerID = obj.retailer.id;
      obj.isActive = true;
      obj.dateCreated = new Date();
      result = await api.contactApi.insert(obj);
    } catch (err) {
      console.log(err);
    } finally {
      onClose(result);
      spinner.hide();
    }
  };

  return (
    <Dialog
      fullScreen
      style={{ margin: 'auto', maxWidth: 600, maxHeight: 400, zIndex: 9000 }}
      open={shirtUserAddDialogController.visible()}
      onClose={() => onClose()}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>
        <DialogHeaderContent title='Create Shirt User' onClose={onClose} />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className='dialog-form_inner'>
        <DialogContent dividers={true} style={{ padding: 10 }}>
          <FormProvider {...frmModel}>
            <div>
              <div className='flex'>
                <TextInput
                  id='firstName'
                  label='First Name'
                  control={control}
                  rules={{ required: 'First Name is required' }}
                />
                <TextInput
                  id='lastName'
                  label='Last Name'
                  control={control}
                  rules={{ required: 'Last Name is required' }}
                />
              </div>
              <div className='flex'>
                <div style={{ width: '100%', marginLeft: 5, marginRight: 10 }}>
                  <Controller
                    name='retailer'
                    control={control}
                    rules={{
                      required: 'Please select retailer',
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <Autocomplete
                        {...field}
                        value={
                          field.value
                            ? shirtUserAddDialogController
                              .getRetailers()
                              .find((option) => {
                                return field.value.itemID === option.itemID;
                              }) ?? null
                            : null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.itemID === (value ? value.itemID : null)
                        }
                        ListboxProps={{ style: { maxHeight: 150 } }}
                        disablePortal
                        id='retailer'
                        className='mt-8'
                        fullWidth
                        options={shirtUserAddDialogController.getRetailers()}
                        onChange={(e, data) => {
                          field.onChange(data);
                        }}
                        getOptionLabel={(x) => x.name}
                        renderOption={(props, option) => (
                          <li {...props} key={option.itemID}>
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={error ? error.message : null}
                            error={!!error}
                            className=''
                            label='Retailer'
                            size='small'
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={() => onClose()}>
            Cancel
          </Button>
          <Button variant='contained' color='secondary' type='submit'>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
