import { createContext } from 'react';
import { isUoN } from 'src/app/@core/common';
import { FormDataApi } from 'src/app/@domain/services/api/formData.api';
import { DropdownItem } from 'src/app/@domain/models/dropdown-item';
import { OrderApi } from 'src/app/@domain/services/api/order.api';
import { ContactApi } from 'src/app/@domain/services/api/contact.api';
import { RetailerApi } from 'src/app/@domain/services/api/retailer.api';
import { InventoryApi } from 'src/app/@domain/services/api/inventory.api';
import { ProductionNoteApi } from 'src/app/@domain/services/api/productionNode.api';
import { MessageBoxService } from 'src/app/@core/forms/controls/messageBox.control';
import { SpinnerService } from 'src/app/@core/forms/controls/spinner.control';
import { ShirtUserPickerDialogController } from 'src/app/@domain/@shared/dialogs/shirtUserPicker.dialog';
import { ShirtUserAddDialogController } from 'src/app/@domain/@shared/dialogs/shirtUserAdd.dialog';
import { SalespersonAddDialogController } from 'src/app/@domain/@shared/dialogs/salespersonAdd.dialog';
import { ErrorService } from '../forms/controls/error.control';
import { FabricApi } from 'src/app/@domain/services/api/fabric.api';
import { OrderAddDialogController } from 'src/app/@domain/modules/order/add/@order.add.dialog';
import { ReportApi } from 'src/app/@domain/services/api/report.api';

/**
 * 
 */
const dialogDataObjBase = { visible: false, resolve: null }

/**
 *
 */
class InitialDomainData {

  /**
   *
   */
  constructor() {

    this.spinnerControlObj = { ...dialogDataObjBase };
    this.messageBoxControlObj = { ...dialogDataObjBase, ...{ title: '', message: '', icon: null } };
    this.errorControlObj = { hasError: false, message: '' };

    this.shirtUserPickerDialogDataObj = { ...dialogDataObjBase, ...{ items: [], gvItems: [] } };
    this.shirtUserAddDialogDataObj = { ...dialogDataObjBase, ...{ items: [] } };
    this.salespersonAddDialogDataObj = { ...dialogDataObjBase, ...{ items: [] } };

    this.orderAddDialogDataObj = { ...dialogDataObjBase, ...{ contacts: [], retailers: [] } };

    this.dropdown = {};

  }
}

/**
 *
 */
class DomainDataContext {
  /**
   *
   */
  constructor() {
    this.api = {
      formDataApi: new FormDataApi(this),
      orderApi: new OrderApi(this),
      contactApi: new ContactApi(this),
      retailerApi: new RetailerApi(this),
      inventoryApi: new InventoryApi(this),
      productionNoteApi: new ProductionNoteApi(),
      fabricApi: new FabricApi(),
      reportApi: new ReportApi
    };

    this.loadDropdrownItems();
  }

  /**
   *
   * @param {*} store
   * @param {*} setStore
   */
  bootstrap(store, setStore) {
    this.store = store;
    this.setStore = setStore;
  }

  /**
   *
   */
  loadDropdrownItems = () => {
    this.api.formDataApi.getInitialData().then((result) => {
      if (isUoN(result)) return;
      const dropdownItemsBuilder = new DropdownItem(result);

      const store = { ...this.store };
      store.dropdown.currentAvailabilityStatuses = dropdownItemsBuilder.currentAvailabilityStatuses();
      store.dropdown.collections = dropdownItemsBuilder.collections();
      store.dropdown.productionStatuses = dropdownItemsBuilder.productionStatuses();
      store.dropdown.shirtTypes = dropdownItemsBuilder.shirtTypes();
      store.dropdown.prodTypes = dropdownItemsBuilder.prodTypes();

      this.setStore(store);
    });
  };

  /**
   *
   * @returns
   */
  dropdown = () => this.store.dropdown;

  error = new ErrorService(this);
  spinner = new SpinnerService(this);
  messageBox = new MessageBoxService(this);

  // Common components
  shirtUserPickerDialogController = new ShirtUserPickerDialogController(this);
  shirtUserAddDialogController = new ShirtUserAddDialogController(this);
  salesPersonAddDialogController = new SalespersonAddDialogController(this);

  // Order components
  orderAddDialogController = new OrderAddDialogController(this);

}

export const initiaDomainlData = new InitialDomainData();
export const doaminDataContext = new DomainDataContext();

export const DomainContext = createContext({});
