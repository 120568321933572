import { ContactsPage } from "../contact/@contacts.page";
import { InventoryPage } from "../inventory/@inventory.page";
import { RetailersPage } from "../retailer/@retailers.page";

const LookupConfig = {
  routes: [
    {
      path: '/lookup',
      element: <ContactsPage />,
    },
    {
      path: '/lookup/contacts',
      element: <ContactsPage />,
    },
    {
      path: '/lookup/inventory',
      element: <InventoryPage />,
    },
    {
      path: '/lookup/retailers',
      element: <RetailersPage />,
    },
  ],
};

export default LookupConfig;

