import { AdminImportFabricPage } from "./fabricImport.admin.page";
import { AdminResetInventoryPage } from "./inventory-reset.admin.page";
import { AdminUpdateInventoryPage } from "./inventoryUpdate.admin.page";

const AdminConfig = {
  routes: [
    {
      path: '/admin',
      element: <AdminImportFabricPage />,
    },
    {
      path: '/admin/importFabric',
      element: <AdminImportFabricPage />,
    },
    {
      path: '/admin/updateInventory',
      element: <AdminUpdateInventoryPage />,
    },
    {
      path: '/admin/resetInventory',
      element: <AdminResetInventoryPage />,
    },
  ],
};

export default AdminConfig;

