const locale = {
  APPLICATIONS: 'تطبيقات',
  DASHBOARDS: 'لوحات',
  CALENDAR: 'التقويم',
  ECOMMERCE: 'التجارة الإلكترونية',
  ACADEMY: 'الأكاديمية',
  MAIL: 'بريد',
  TASKS: 'لكى يفعل',
  FILE_MANAGER: 'مدير الملفات',
  CONTACTS: 'جهات الاتصال',
  CHAT: 'دردشة',
  SCRUMBOARD: 'مجلس',
  NOTES: 'ملاحظات',
};

export default locale;
